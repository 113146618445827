<template>
    <div class =" col-span-12 mx-4  mt-4 rounded-lg bg-TFL-cardDashboard  p-4 m-2    col-span-2 lg:col-span-1   ">
        <p class="text-TFL-dashText1"> پشتیبانی  </p>
        <p class="text-xs mt-4 text-TFL-dashText2 flex mx-2"><svg class="flex-shrink-0 w-4 h-4 text-TFL-base1 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                    </svg>کاربر گرامی، در این بخش می‌توانید نسبت به ارسال تیکت به پشتیبانی اقدام نمایید.</p>
        <p class="text-xs mt-1 text-TFL-dashText2 flex  mx-2"><svg class="flex-shrink-0 w-4 h-4 text-TFL-base1 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                    </svg>پس از ارسال تیکت تا پاسخگویی کارشناس مربوطه صبور باشید و از ارسال پیام مجدد خودداری نمایید.</p>
        <p class="text-xs mt-1 text-TFL-dashText2 flex mx-2"><svg class="flex-shrink-0 w-4 h-4 text-TFL-base1 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                    </svg>درخواست‌های ریست، اکستند، فاز بعدی و برداشت به صورت خودکار پس از ثبت درخواست در قسمت مربوطه انجام خواهد شد و  از ارسال تیکت مرتبط با موارد ذکر شده خودداری نمایید. </p>

    </div>
    <div class="grid grid-cols-12 m-2">
        
        
        <div class=" col-span-12 grid grid-cols-12  rounded-lg bg-TFL-cardDashboard  p-4 m-2    ">


        <div class=" lg:col-span-4 col-span-12 flex flex-col  items-center justify-center ml-2   text-gray-800 ">
            <div class="flex flex-col flex-grow w-full  h-96  bg-gray-700 shadow-xl rounded-lg overflow-hidden">
                <div v-if="!msgLoaded" class=" h-96 overflow-auto">
                    <div role="status" class=" p-4 space-y-4 divide-y divide-gray-200 rounded shadow animate-pulse  md:p-6">
                    <div class="flex items-center justify-between">
                        <div>
                            <div class="h-2.5 bg-gray-300 rounded-full  w-24 mb-2.5"></div>
                            <div class="w-32 h-2 bg-gray-200 rounded-full "></div>
                        </div>
                        <div class="h-2.5 bg-gray-300 rounded-full  w-12"></div>
                    </div>
                    <div class="flex items-center justify-between pt-4">
                        <div>
                            <div class="h-2.5 bg-gray-300 rounded-full  w-24 mb-2.5"></div>
                            <div class="w-32 h-2 bg-gray-200 rounded-full "></div>
                        </div>
                        <div class="h-2.5 bg-gray-300 rounded-full  w-12"></div>
                    </div>
                    <div class="flex items-center justify-between pt-4">
                        <div>
                            <div class="h-2.5 bg-gray-300 rounded-full  w-24 mb-2.5"></div>
                            <div class="w-32 h-2 bg-gray-200 rounded-full "></div>
                        </div>
                        <div class="h-2.5 bg-gray-300 rounded-full  w-12"></div>
                    </div>
                    <div class="flex items-center justify-between pt-4">
                        <div>
                            <div class="h-2.5 bg-gray-300 rounded-full  w-24 mb-2.5"></div>
                            <div class="w-32 h-2 bg-gray-200 rounded-full "></div>
                        </div>
                        <div class="h-2.5 bg-gray-300 rounded-full  w-12"></div>
                    </div>
                    <div class="flex items-center justify-between pt-4">
                        <div>
                            <div class="h-2.5 bg-gray-300 rounded-full  w-24 mb-2.5"></div>
                            <div class="w-32 h-2 bg-gray-200 rounded-full "></div>
                        </div>
                        <div class="h-2.5 bg-gray-300 rounded-full  w-12"></div>
                    </div>
                    <span class="sr-only">Loading...</span>
                </div>
                </div>
                <div v-if="msgLoaded" class=" h-96 overflow-auto">
                    <div @click="fetchTicketChat(index,ticketList[index].state)" v-for="(ticket, index) in ticketList" :key="index" class=" relative h-16 m-2 p-2   hover:bg-gray-600 bg-TFL-card1  cursor-pointer rounded-lg">
                        <!-- <span v-if="ticket.seenByUser == false" class="absolute  -top-3 -left-3 relative justify-self-center mx-2 flex h-3 w-3 mr-auto z-50">
                        <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-blue-500 opacity-75 mr-auto"></span>
                        <span class="relative inline-flex rounded-full h-3 w-3 bg-blue-600 mr-auto"></span>
                        </span> -->
                        <div class="flex">

                            <div v-if="ticket.seenByUser == false" class="mx-1 mb-1 text-base z-30 text-TFL-dashText1 animate-pulse ">{{ formatNumber(ticket.title)}}</div>
                            <div v-if="ticket.seenByUser == true" class="mx-1 mb-1 text-xs z-30 text-TFL-dashText1  ">{{ formatNumber(ticket.title)}}</div>
                            <div v-if="ticket.state == 'Open' " class=" z-30 self-start bg-TFL-base1 text-white text-xs   px-2.5 py-0.5 rounded  mx-1 mb-2 mr-auto">فعال</div>
                            <div v-if="ticket.state == 'Closed'" class=" z-30 self-start bg-purple-800 text-white text-xs   px-2.5 py-0.5 rounded  mx-1 mb-2 mr-auto">بسته شده </div>
                        </div>
                        <div class="flex">
                            <div class="mb-1 mx-1 text-xs inline-flex items-center text-gray-300">{{ formatNumber( ticket.createDateTime.replace("T", " در  ").replace("+00:00", "").substring(0, 20).toLocaleString('fa-IR').replace(/٬/g,''))}}</div>
                            

                            <div v-if="ticket.category == 'General'" class=" self-start bg-green-500 text-white text-xs   px-2.5 py-0.5 rounded bg-opacity-50 mx-1 mb-2 mr-auto">عمومی</div>
                            <div v-if="ticket.category == 'Technical'" class=" self-start bg-green-500 text-white text-xs   px-2.5 py-0.5 rounded bg-opacity-50 mx-1 mb-2 mr-auto">فنی</div>
                            <div v-if="ticket.category == 'Financial'" class=" self-start bg-green-500 text-white text-xs   px-2.5 py-0.5 rounded bg-opacity-50 mx-1 mb-2 mr-auto">مالی</div>
                            

                        </div>
                        
                    </div>
                    
                   
                    

                </div>
                <button @click="changeNewTicket" class="m-2 p-2 text-xs rounded-lg bg-TFL-base1 hover:bg-TFL-base1 text-white">
                    ایجاد تیکت جدید
                </button>
            </div>
        </div>
        <div class="lg:col-span-8 col-span-12 flex flex-col  items-center justify-center  text-gray-800 ">


            <div v-if="!newTicket" class="flex flex-col flex-grow w-full h-96  bg-gray-700 shadow-xl rounded-lg  overflow-hidden" id="myDiv">
                <div  class="flex flex-col flex-grow h-0 p-4 pl-2  overflow-auto">
                    <div v-if="!ticketChat[0]" class="flex w-full mt-2  space-x-3 mr-auto justify-end  max-w-lg">
                        <div>
                            <div class="bg-gray-400 text-white p-3  max-w-lg  rounded-xl ml-2">
                                <p class="text-xs ">کاربر گرامی،</p>
                                <p class="text-xs ">جهت ارسال تیکت از دکمه ایجاد تیکت جدید استفاده نمایید. </p>
                                <p class="text-xs ">حداکثر زمان پاسخگویی {{formatNumber(24)}} ساعت می‌باشد</p>
                                <p class="text-xs ">از صبوری شما سپاسگزاریم.</p>
                            </div>
                            
                        </div>
                        <div class="hidden lg:block flex-shrink-0 h-10 w-10  rounded-full bg-gray-400"></div>
                    </div>
                   <div v-for="(msg, index) in ticketChat" :key="index" >
                       <div v-if="thisIsMyUId != msg.userId" class="flex w-full mt-2  space-x-3 mr-auto justify-end  max-w-lg">
                           <div>
                               <div class="bg-gray-400 p-3  max-w-lg  rounded-xl ml-2">
                                   <p  class=" text-ellipsis overflow-hidden text-xs text-white  " v-html="formatMessage(msg.message)"></p>
                                   
                               </div>
                               <span class="text-xs text-gray-500 leading-none">{{formatNumber( msg.createDateTime.replace("T", " در ساعت ").replace("+00:00", "").substring(0, 27).toLocaleString('fa-IR').replace(/٬/g,''))}}</span>
                           </div>
                           <div class="hidden lg:block flex-shrink-0 h-10 w-10  rounded-full bg-gray-400"></div>
                       </div>
   
                       <div v-if="thisIsMyUId == msg.userId" class="flex w-full mt-2 space-x-3 max-w-xs ml-auto justify-start">
                           <div class=" hidden lg:block flex-shrink-0 h-10 w-10 rounded-full bg-gray-600 bg-opacity-500"></div>
                           <div>
                               <div class="bg-TFL-base1 bg-opacity-70 text-white p-3 rounded-2xl mr-2 ">
                                   <p class=" text-ellipsis overflow-hidden text-xs" v-html="formatMessage(msg.message)"></p>
                               </div>
                               <span class="text-xs text-gray-500 leading-none">{{formatNumber( msg.createDateTime.replace("T", " در ساعت ").replace("+00:00", "").substring(0, 27).toLocaleString('fa-IR').replace(/٬/g,''))}}</span>
                           </div>
                       </div>

                   </div>                      

                </div> 
                

                    
                    
                    
                

                <div class=" grid grid-cols-12 p-2">
                    <form class="col-span-12">
                        <div class="flex items-center  ">
                            <textarea v-if="ticketChat[0] && showTextBox" v-model="messageText" id="chat" rows="1" class="block mx-1 p-2.5 w-full text-xs text-white bg-gray-600 bg-opacity-50 rounded-lg  focus:ring-blue-500 focus:border-blue-500 " placeholder=" پیام خود را بنویسید"></textarea>
                                <button :disabled="sending" @click.prevent="SendMessage" class="inline-flex justify-center p-2 text-TFL-base1 rounded-full cursor-pointer hover:bg-TFL-base1 hover:text-white  ">
                                    <svg  v-if="ticketChat[0] && showTextBox" class="w-5 h-5 -rotate-90" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                                        <path d="m17.914 18.594-8-18a1 1 0 0 0-1.828 0l-8 18a1 1 0 0 0 1.157 1.376L8 18.281V9a1 1 0 0 1 2 0v9.281l6.758 1.689a1 1 0 0 0 1.156-1.376Z"/>
                                    </svg>

                                </button>
                            <textarea v-if="ticketChat[0] && !showTextBox" :disabled="!showTextBox "  id="chat" rows="1" class=" disabled block mx-1 p-2.5 w-full text-xs text-white bg-gray-600 bg-opacity-50 rounded-lg  focus:ring-blue-500 focus:border-blue-500 " placeholder=" پیام خود را بنویسید"></textarea>
                                <button  @click.prevent="SendMessage" :disabled="!showTextBox " class=" disabled inline-flex justify-center p-2 text-TFL-base1 rounded-full    ">
                                    <svg  v-if="ticketChat[0] && !showTextBox" class="w-5 h-5 -rotate-90" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                                        <path d="m17.914 18.594-8-18a1 1 0 0 0-1.828 0l-8 18a1 1 0 0 0 1.157 1.376L8 18.281V9a1 1 0 0 1 2 0v9.281l6.758 1.689a1 1 0 0 0 1.156-1.376Z"/>
                                    </svg>

                                </button>
                        </div>
                    </form>
                </div>
            </div>
            <div v-if="newTicket" class="flex flex-col flex-grow w-full h-96  bg-TFL-card1 shadow-xl rounded-lg  overflow-hidden" id="myDiv">
                <div  class="flex flex-col flex-grow h-0 p-4 pl-2  overflow-auto">
                    
                        <div class="text-TFL-dashText1">ارسال تیکت جدید</div>
                        <div v-if="showSuccessCreated" class=" border border-green-500 bg-green-400  text-white text-xs rounded-lg p-2 my-2 "><p> تیکت شما با موفقیت ایجاد شد</p> </div>
                    
                    <div class=" ml-2 mt-6">
                      <label for="small-input" class="block mb-1  text-xs font-medium text-gray-300 ">عنوان تیکت:</label>
                      <input v-model="title" type="text" id="small-input" class="block w-full p-2   text-gray-300   rounded-lg bg-gray-600 bg-opacity-50 sm:text-xs  ">
                    </div>
                    <div class ="grid grid-cols-2 mt-2">

                        <div class=" col-span-1 ml-2  mt-1">
                          <label for="small" class="block mb-1 text-xs font-medium text-gray-300 ">موضوع تیکت:</label>
                          <select v-model="category" id="small" class="block w-full p-1   text-gray-300   rounded-lg bg-gray-600 bg-opacity-50 sm:text-xs  ">
                            
                            <option class="bg-gray-700 text-gray-300" value="General">عمومی</option>
                            <option class="bg-gray-700 text-gray-300" value="Technical">فنی</option>
                            <option class="bg-gray-700 text-gray-300" value="Financial">مالی</option>
                            
                          </select>
                        </div>
                        <div class=" col-span-1 ml-2   mt-1">
                          <label for="small" class="block mb-1 text-xs font-medium text-gray-300 ">اهمیت تیکت:</label>
                          <select v-model="priority" id="small" class="block w-full p-1   text-gray-300   rounded-lg bg-gray-600 bg-opacity-50 sm:text-xs  ">
                            <option class="bg-gray-700 text-gray-300" value="Low">کم</option>
                            <option class="bg-gray-700 text-gray-300"  value="Medium">متوسط</option>
                            <option class="bg-gray-700 text-gray-300" value="High">زیاد</option>
                            <option class="bg-gray-700 text-gray-300" value="Urgent">ضروری</option>
                          </select>
                        </div>
                    </div>
                    
                </div> 
                

                    
                    
                    
                

                <div class=" grid grid-cols-12 p-2">
                    <form class="col-span-12">
                        <div class="flex items-center  ">
                            <textarea  v-model="message" id="chat" rows="6" class="block mx-1 bg-gray-600 bg-opacity-50 p-2.5 w-full text-xs text-gray-300  rounded-lg   focus:ring-blue-500 focus:border-blue-500 " placeholder=" پیام خود را بنویسید"></textarea>
                                <button :disabled="sending" @click.prevent="CreateNewTicket" class="inline-flex justify-center p-2 text-TFL-base1 rounded-full cursor-pointer hover:bg-TFL-base1 hover:text-white  ">
                                    <svg   class="w-5 h-5 -rotate-90" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                                        <path d="m17.914 18.594-8-18a1 1 0 0 0-1.828 0l-8 18a1 1 0 0 0 1.157 1.376L8 18.281V9a1 1 0 0 1 2 0v9.281l6.758 1.689a1 1 0 0 0 1.156-1.376Z"/>
                                    </svg>

                                </button>
                        </div>
                    </form>
                </div>
            </div>
            

            </div>
    </div>
    </div>
    
    
</template>


<script>
import axios from 'axios';
import URL from "../URL";
export default {
    data(){
        return{
            ticketList:[],
            ticketChat:[],
            thisIsMyUId:'',
            messageText: '',
            selectedTicket: '',
            newTicket: false,
            title: '',
            message: '',
            category: 'General',
            priority: 'Low',
            showSuccessCreated: false,
            msgLoaded: false,
            showTextBox:true,
            sending: false,
             

        }
    },
    mounted(){
        this.fetchTicketList();
        this.checkToken();
        // this.scrollToBottom();
    },
    
    methods:{
        formatMessage(message) {
    return message.replace(/\n/g, '<br>');
  },
        changeNewTicket(){
            this.newTicket = !this.newTicket;
            this.showSuccessCreated = false;
        },
        formatNumber(number) {
          const formattedNumber = number.toLocaleString('en-US');
          return digitsEnToFa(formattedNumber);
        },
        fetchTicketList(){
            const url = URL.baseUrl + `Meta/ticket`;
            axios.get(url,
            
            {
                headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })
            .then(response => {
          // loop through response data and push each object into trades array
          this.ticketList = response.data.map(ticket => {
      const localTime = new Date(ticket.createDateTime).toLocaleString('en-US', {
        hour12: false,
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
      });
      return {
        ...ticket,
        createDateTime: localTime
      };
    });
                this.msgLoaded = true;
            })
            .catch(error => {
            this.errorMessage = error.message;
            this.msgLoaded = true;
            
            });
        },
        fetchTicketChat(index,ticketState){
            if(ticketState == 'Closed'){
               this.showTextBox = false;
            }
            if(ticketState == 'Open'){
               this.showTextBox = true;
            }
            const url = URL.baseUrl + `Meta/ticket/${this.ticketList[index].uId}`;
            axios.get(url,
            
            {
                headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })
            .then(response => {
          // loop through response data and push each object into trades array
                  this.ticketChat = response.data.map(chat => {
              const localTime = new Date(chat.createDateTime).toLocaleString('en-US', {
                  hour12: false,
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                  second: 'numeric'
                });
              return {
                ...chat,
                createDateTime: localTime
              };
            });
              this.selectedTicket = response.data[0].ticketThreadId;
              this.fetchTicketList();
              this.newTicket = false;
            })
            .catch(error => {
            this.errorMessage = error.message;
            
            this.newTicket = false;
            });
        },
        checkToken(){
         const url = URL.baseUrl + `users/whoami`;
         axios.get(url,{
        headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    })
        .then(response => {
            this.thisIsMyUId = response.data.uId;
          
          
          // age az accountList omade bud
        })
        .catch(error => {
          
          


        });
      },
      SendMessage() {
            
        this.sending = true;
        const url = URL.baseUrl + `Meta/ticket/message`;
        axios.post(url,
        { 
           
            
            ticketThreadId: this.selectedTicket,
            message: this.messageText,
            
            },
            {
         headers: {
           'Content-Type': 'application/json',
           'Authorization': 'Bearer ' + localStorage.getItem('token')
           }
         }
            
        
        )
        .then(response => {
            // Handle successful response here
            console.log(response.data);
            this.messageText = '';
            this.fetchTicketList();
            this.fetchTicketChat(this.ticketList.findIndex(item => item.uId === this.selectedTicket ));
            this.sending = false;
        })
            

            .catch(error => {
            this.errorMessage = error.message;
            this.sending = false;
            
            });
},
      CreateNewTicket() {
            
        this.sending = true;
        const url = URL.baseUrl + `Meta/ticket`;
        axios.post(url,
        { 
            title: this.title,
            message: this.message,
            category: this.category,
            priority: this.priority,
            
                        
            
            },
            {
         headers: {
           'Content-Type': 'application/json',
           'Authorization': 'Bearer ' + localStorage.getItem('token')
           }
         }
            
        
        )
        .then(response => {
            // Handle successful response here
            console.log(response.data);
            this.messageText = '';
            this.fetchTicketList();
            this.message = '';
            this.title = '';
            this.showSuccessCreated = true;
            this.sending = false;
            
        })
            

            .catch(error => {
            this.errorMessage = error.message;
            this.sending = false;
            
            
            });
},
       
    }
}
</script>