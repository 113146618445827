
<template>
  <div class="col-span-12 grid grid-cols-12">
    <div class =" text-justify col-span-12 mx-4 mt-4 rounded-lg  bg-TFL-cardDashboard p-4       ">
        <p class="font-bold text-TFL-dashText1"> تورنومنت</p>
        <p class="text-xs mt-4 text-TFL-dashText2 flex mx-2"><svg class="flex-shrink-0 w-4 h-4 text-TFL-base1 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                    </svg> پسورد حساب معاملاتی پس از آغاز تورنومنت در بخش اطلاعات حساب قابل مشاهده است. </p>
        <p class="text-xs mt-4 text-TFL-dashText2 flex mx-2"><svg class="flex-shrink-0 w-4 h-4 text-TFL-base1 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                    </svg> به منظور افزایش شفافیت، پس از اتمام تورنومنت، یوزر و پسورد حساب معاملاتی نفرات برتر قابل مشاهده خواهد بود.</p>
        <p class="text-xs mt-4 text-TFL-dashText2 flex mx-2"><svg class="flex-shrink-0 w-4 h-4 text-TFL-base1 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                    </svg> برای شرکت در تورنومنت‌های خصوصی، نام تورنومنت مورد نظر را جستجو کنید.</p>
        <p class="text-xs mt-4 text-TFL-dashText2 flex mx-2"><svg class="flex-shrink-0 w-4 h-4 text-TFL-base1 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                    </svg> زمان ذکر شده جهت شروع و اتمام تورنومنت به وقت UTC می‌باشد.</p>
        <p class="text-xs mt-4 text-TFL-dashText2 flex mx-2"><svg class="flex-shrink-0 w-4 h-4 text-TFL-base1 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                    </svg>مسئولیت انتخاب بروکر به عهده معامله‌گر بوده و به هیچ عنوان امکان تغییر بروکر وجود نخواهد داشت. </p>
    </div>
    
       

    <div class="col-span-12 "> 
        <div class="flex">
            <input v-model="tournamentName" type="text" class=" col-span-12 rounded-lg bg-TFL-cardDashboard p-2 h-8 mt-4 mx-4  text-white placeholder:text-xs " placeholder="جستجوی تورنومنت">
        <button v-if="!searching" @click="getTournaments()" class="bg-TFL-base1 h-8 text-neutral-100 text-xs font-IranYekan font-bold rounded-lg p-2 mt-4 ">جستجو </button>
        <button v-if="searching"  class="bg-TFL-base1 text-neutral-100 text-xs h-8 font-IranYekan font-bold rounded-lg p-2 mt-4">
            <div role="status justify-self-center">
                        <svg aria-hidden="true" class="inline w-5 h-5  text-gray-300 animate-spin  fill-white " viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                        </svg>
                        <span class="sr-only">Loading...</span>
                    </div>
        </button>
        
        </div>
        <p v-if="showMessage" class="text-xs text-red-400 text-right mx-4 my-2">تورنومنت یافت نشد!</p>
        
        
        <div v-for="tournament in tournaments" :key="tournament.id" class="bg-TFL-cardDashboard grid grid-cols-2 rounded-lg p-4 m-4">
            <div class="lg:col-span-1 col-span-2 bg-TFL-innerCard px-4 py-2 rounded-xl shadow-[inset_0px_3px_8px_rgba(0,0,0,0.50)]">

            
                
                <div class="text-right text-neutral-100 text-xs font-IranYekan flex justify-between mt-1"> 
                    <p class="text-neutral-300">نام تورنومنت :</p>
                    <p class="font-bold">{{tournament.name}}</p>

                </div>
                <div class="text-right text-neutral-100 text-xs font-IranYekan flex justify-between mt-1"> 
                    <p class="text-neutral-300"> وضعیت: </p>
                    <p v-if="tournament.state == 'Pending'" class="rounded  px-1 bg-blue-300   text-[10px] text-blue-600"> در حال ثبت‌نام</p>
                    <p v-if="tournament.state == 'Active'" class="rounded  px-1 bg-green-300   text-[10px] text-green-600"> در حال برگزاری</p>
                    <p v-if="tournament.state == 'Finished'" class="rounded  px-1 bg-red-300   text-[10px] text-red-600"> اتمام  </p>
                    <p v-if="tournament.state == 'FulledNotStarted'" class="rounded  px-1 bg-red-300   text-[10px] text-red-600"> ظرفیت تکمیل  </p>

                </div>
                <div class="text-right text-neutral-100 text-xs font-IranYekan flex justify-between mt-1"> 
                    <p class="text-neutral-300">تاریخ شروع:</p>
                    <p dir="ltr">{{ tournament.startDateTime.slice(0, 10) }} - {{ tournament.startDateTime.slice(11, 16) }}</p>

                </div>
                <div class="text-right text-neutral-100 text-xs font-IranYekan flex justify-between mt-1"> 
                    <p class="text-neutral-300">تاریخ پایان:</p>
                    <p dir="ltr">{{ tournament.endDateTime.slice(0, 10) }} - {{ tournament.endDateTime.slice(11, 16) }}</p>
                    

                </div>
                
               
                <div class="text-right text-neutral-100 text-xs font-IranYekan flex justify-between mt-1"> 
                    <p class="text-neutral-300">هزینه ورود: </p>
                    <p v-if=" tournament.entryFee == 0"> رایگان</p>
                    <p v-else> ${{tournament.entryFee}} </p>

                </div>
                
                
            </div>
            <div class="lg:col-span-1 col-span-2  mt-2 mx-2 px-2 py-2 lg:mt-0  gap-y-2">
                <button v-if="tournament.state == 'Pending' && !tournament.tradingAccountId" class="bg-green-400 w-full text-neutral-100 text-xs font-IranYekan font-bold rounded-lg p-2 " @click="entryFee = tournament.entryFee ;tournamentId = tournament.uId; price=tournament.entryFee; toggleSignup()">شرکت در تورنومنت</button>
                
                <button v-if="tournament.tradingAccountId && AccountDataLoaded" class="bg-TFL-base1  text-neutral-100 h-8 text-xs font-IranYekan font-bold rounded-lg p-2 w-full " @click="tournamentId = tournament.uId; getAccountData()">مشخصات حساب</button>
                <button v-if="tournament.tradingAccountId && !AccountDataLoaded" class="bg-TFL-base1  text-neutral-100 h-8 text-xs font-IranYekan font-bold rounded-lg p-2 w-full " >
                    <div role="status justify-self-center">
                        <svg aria-hidden="true" class="inline w-5 h-5  text-gray-300 animate-spin  fill-white " viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                        </svg>
                        <span class="sr-only">Loading...</span>
                    </div>
                </button>
                <router-link v-if="tournament.tradingAccountId" :to="{params: {id:tournament.uId }, name:'DTournamentsAnalysis' }"  class="relative w-full h-8 mt-2 inline-flex items-center justify-center p-2   overflow-hidden font-medium text-TFL-base1 transition duration-300 ease-out border-2 border-TFL-base1 hover:border-2 hover:border-TFL-base1 rounded-lg shadow-md group">
                                <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 translate-x-full bg-TFL-base1 group-hover:translate-x-0 ease">
                                    <svg class="w-6 h-6  flip-right-to-left" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path>
                                    </svg>
                                </span>
                                <span class="absolute flex items-center  text-xs justify-center w-full h-full text-TFL-base1 transition-all duration-300 transform group-hover:translate-x-full ease"> آنالیز حساب</span>
                                
                            </router-link>
                <button v-if="(tournament.state == 'Active' || tournament.state == 'Finished') && leaderBoardLoaded  " class="bg-TFL-base1 h-8 w-full text-neutral-100 text-xs font-IranYekan font-bold rounded-lg p-2 " @click="tournamentId = tournament.uId; leaderBoard()"> جدول رتبه‌بندی </button> 
                <button v-if="(tournament.state == 'Active' || tournament.state == 'Finished') && !leaderBoardLoaded  " class="bg-TFL-base1 h-8 w-full text-neutral-100 text-xs font-IranYekan font-bold rounded-lg p-2 " > 
                    <div role="status justify-self-center">
                        <svg aria-hidden="true" class="inline w-5 h-5  text-gray-300 animate-spin  fill-white " viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                        </svg>
                        <span class="sr-only">Loading...</span>
                    </div>
                </button> 
                
            </div>
            
        </div>
        
        
    </div>

  </div>

<div v-if="showAccountDetail" class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="fixed inset-0 bg-gray-800 bg-opacity-70 transition-opacity"></div>

    <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-2 text-center sm:items-center sm:p-0">
            <div class="relative transform overflow-hidden rounded-lg bg-TFL-card2 text-left shadow-xl transition-all w-full max-w-lg">
                <div class="col-span-3 lg:col-span-2 m-2 p-2 bg-TFL-card2 rounded-lg shadow sm:p-4">
                    <div class="flex items-center space-x-4 my-2 text-gray-300">
                        <div class="flex-1 min-w-0">
                            <p class="text-xs text-right text-gray-300 truncate">شماره حساب</p>
                        </div>
                        <div class="inline-flex items-center text-xs text-gray-300" @click="copyAccountId(accountData.accountId)" style="cursor: pointer;" title="برای کپی کلیک کنید">
                            {{accountData.accountId}}
                        </div>
                    </div>
                    <hr class="h-px my-2 bg-gray-600 border-0">
                    <div class="flex items-center space-x-4 my-2 text-gray-300">
                        <div class="flex-1 min-w-0">
                            <p class="text-xs text-right text-gray-300 truncate">پسورد</p>
                        </div>
                        <div v-if="accountData.password" class="inline-flex text-xs items-center text-gray-300" @click="copyAccountId(accountData.password)" style="cursor: pointer;" title="برای کپی کلیک کنید">
                            {{accountData.password}}
                        </div>
                        <div v-else class="inline-flex items-center text-gray-300">
                            <span class="text-[10px]">پسورد پس از شروع تورنومنت در دسترس است.  </span>
                        </div>
                    </div>
                    <hr class="h-px my-2 bg-gray-600 border-0">
                    <div class="flex items-center space-x-4 my-2 text-gray-300">
                        <div class="flex-1 min-w-0">
                            <p class="text-xs text-right text-gray-300 truncate">اینوستور پسورد</p>
                        </div>
                        <div class="inline-flex items-center text-xs text-gray-300" @click="copyAccountId(accountData.investorPassword)" style="cursor: pointer;" title="برای کپی کلیک کنید">
                            {{accountData.investorPassword}}
                        </div>
                    </div>
                    <hr class="h-px my-2 bg-gray-600 border-0">
                    <div class="flex items-center space-x-4 my-2 text-gray-300">
                        <div class="flex-1 min-w-0">
                            <p class="text-xs text-right text-gray-300 truncate">بروکر</p>
                        </div>
                        <div class="inline-flex items-center text-xs text-gray-300">
                            {{accountData.broker}}
                        </div>
                    </div>
                    <hr class="h-px my-2 bg-gray-600 border-0">
                    <div class="flex items-center space-x-4 my-2 text-gray-300">
                        <div class="flex-1 min-w-0">
                            <p class="text-xs text-right text-gray-300 truncate">سرور</p>
                        </div>
                        <div class="inline-flex items-center text-xs text-gray-300" @click="copyAccountId(accountData.server)" style="cursor: pointer;" title="برای کپی کلیک کنید">
                            {{accountData.server}}
                        </div>
                    </div>
                    <hr class="h-px my-2 bg-gray-600 border-0">
                    <div class="flex items-center space-x-4 my-2 text-gray-300">
                        <div class="flex-1 min-w-0">
                            <p class="text-xs text-right text-gray-300 truncate">پلتفرم</p>
                        </div>
                        <div class="flex">
                            <a v-if="accountData.broker == 'Roboforex'" href="https://s30.picofile.com/file/8468464068/roboforex4setup.exe.html" target="_blank" class="text-xs bg-blue-500 text-white rounded rounded-lg p-1 text-center mx-2 ">
                                دانلود ROBOFOREX MT4
                            </a>
                            <a v-if="accountData.broker == 'Fxtm'" href="https://s30.picofile.com/file/8468464084/forextimefxtm4setup.exe.html" target="_blank" class="text-xs bg-blue-500 text-white rounded rounded-lg p-1 text-center mx-2">
                                دانلود FXTM MT4
                            </a>
                            <a v-if="accountData.broker == 'Tickmill'" href="https://s31.picofile.com/file/8468464018/tickmilleu4setup.exe.html" target="_blank" class="text-xs bg-blue-500 text-white rounded rounded-lg p-1 text-center mx-2">
                                دانلود Tickmill MT4
                            </a>
                            <a v-if="accountData.broker == 'ICMarkets'" href="https://s31.picofile.com/file/8468464034/icmarketseu4setup.exe.html" target="_blank" class="text-xs bg-blue-500 text-white rounded rounded-lg p-1 text-center mx-2">
                                دانلود ICMarkets MT4
                            </a>
                            <a v-if="accountData.broker == 'Alpari'" href="https://s30.picofile.com/file/8475440084/alpari4setup.exe.html" target="_blank" class="text-xs bg-blue-500 text-white rounded rounded-lg p-1 text-center mx-2">
                                دانلود Alpari MT4
                            </a>
                            <a v-if="accountData.broker == 'Exness'" href="https://s30.picofile.com/file/8475448418/exness4setup.exe.html" target="_blank" class="text-xs bg-blue-500 text-white rounded rounded-lg p-1 text-center mx-2">
                                دانلود Exness MT4
                            </a>
                            <div class="inline-flex items-center text-xs text-gray-300">
                                {{accountData.platform}}
                            </div>
                        </div>
                    </div>
                    <hr class="h-px my-2 bg-gray-600 border-0">
                    <div class="flex items-center space-x-4 my-2 text-gray-300">
                        <div class="flex-1 min-w-0">
                            <p class="text-xs text-right text-gray-300 truncate">لورج</p>
                        </div>
                        <div class="inline-flex items-center text-xs text-gray-300">
                            1:{{accountData.leverage}}
                        </div>
                    </div>
                    <hr class="h-px my-2 bg-gray-600 border-0">
                </div>
                <div class="bg-TFL-card2 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <button @click="toggleAccount()" type="button" class="inline-flex w-full justify-center rounded-md bg-TFL-base1 px-3 py-2 text-xs font-semibold text-white shadow-sm hover:bg-TFL-base1 ">بستن</button>
                </div>
            </div>
        </div>
    </div>
</div>
<div v-if="tournamentSignup" class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="fixed inset-0 bg-gray-800 bg-opacity-70 transition-opacity"></div>

    <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-2 text-center sm:items-center sm:p-0">
            <div class="relative transform overflow-hidden p-4 rounded-lg bg-TFL-card2 text-left shadow-xl transition-all w-full max-w-lg">
                <div class="col-span-12 mt-3">
                    <label class="block text-TFL-dashText1 text-right text-xs" >
                       انتخاب بروکر 
                    </label>
                    <div class="grid grid-cols-12" >
                        <button @click="brokerButtonClick('Roboforex')" :class="{ 'bg-gray-600 bg-opacity-30 text-TFL-dashText2': this.broker !== 'Roboforex','bg-TFL-base1 text-white': this.broker === 'Roboforex' }" class="py-2 px-4 m-1 rounded-lg col-span-6 text-xs ">Roboforex</button>
                        <button @click="brokerButtonClick('Fxtm')" :class="{ 'bg-gray-600 bg-opacity-30  text-TFL-dashText2': this.broker !== 'Fxtm','bg-TFL-base1 text-white': this.broker === 'Fxtm' }" class=" py-2 px-4 col-span-6 text-xs rounded-lg m-1 ">Fxtm</button>
                        <button @click="brokerButtonClick('ICMarkets')" :class="{ 'bg-gray-600 bg-opacity-30  text-TFL-dashText2': this.broker !== 'ICMarkets','bg-TFL-base1 text-white': this.broker === 'ICMarkets' }" class=" py-2 px-4 col-span-6 text-xs rounded-lg m-1 ">ICMarkets</button>
                        <button @click="brokerButtonClick('Tickmill')" :class="{ 'bg-gray-600 bg-opacity-30  text-TFL-dashText2': this.broker !== 'Tickmill','bg-TFL-base1 text-white': this.broker === 'Tickmill' }" class=" py-2 px-4 col-span-6 text-xs rounded-lg m-1 ">Tickmill</button>
                        <button @click="brokerButtonClick('Alpari')" :class="{ 'bg-gray-600 bg-opacity-30  text-TFL-dashText2': this.broker !== 'Alpari','bg-TFL-base1 text-white': this.broker === 'Alpari' }" class=" py-2 px-4 col-span-6 text-xs rounded-lg m-1 ">Alpari</button>
                        <!-- <button @click="brokerButtonClick('Exness')" :class="{ 'bg-gray-600 bg-opacity-30  text-TFL-dashText2': this.broker !== 'Exness','bg-TFL-base1 text-white': this.broker === 'Exness' }" class=" py-2 px-4 col-span-6 text-xs rounded-lg m-1 ">Exness</button> -->
                                            
                    </div>

                    

                </div>
                <div class="col-span-12 mt-3">
                    <label class="block text-TFL-dashText1 text-right text-xs" >
                       انتخاب سرور 
                    </label>


                    <div class="relative mt-1 text-right">
                        <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center px-2 text-gray-300">
                            <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                        </div>
                        <select v-model="serverName"   class="block text-right appearance-none w-full bg-gray-600 bg-opacity-30 border-2 border-TFL-base1 text-gray-300 py-2 px-4 pr-8 rounded-lg leading-tight focus:outline-none  focus:border-TFL-base1" id="grid-state">
                            <option class="bg-gray-700 text-gray-300"  v-for="(serverNameL, index) in serverNameList" :key="index" >{{serverNameL.name}}</option>
                        </select>
                    </div>
                    <div class="text-blue-500 text-xs mt-2 text-right  ">

                        <a href="/Brokers" target="_blank" >حساب‌های تست و مقایسه بروکرها</a>
                    </div>
                </div>
                <div v-if="entryFee != 0" class="col-span-12 mt-4  ">

                    <label class=" block text-TFL-dashText1  text-right text-xs" >
                           انتخاب درگاه پرداخت
                        </label>
                        <div class=" grid grid-cols-12" >
                            <button @click="choosePayment('Crypto')" :class="{ 'bg-gray-600 bg-opacity-30 text-TFL-dashText2': this.paymentType !== 'Crypto','bg-TFL-base1  text-white': this.paymentType === 'Crypto' }" class="sm:col-span-6 col-span-12 text-xs inline-flex items-center justify-center py-2 px-4 m-1 rounded-lg">ارز دیجیتال </button>
                            <!-- <button v-if="loadToken" @click="choosePayment('Rial')" :disabled="notAllowRial" :class="{ 'bg-gray-600 bg-opacity-30 text-TFL-dashText2': this.paymentType !== 'Rial','bg-TFL-base1  text-white': this.paymentType === 'Rial' }" class="sm:col-span-6 col-span-12 text-xs py-2 px-4 m-1 rounded-lg">ریالی (زرین پال)</button> -->
                            <!-- <button v-if="loadToken && developmentUID && (developmentUID == developmentKey1 || developmentUID == developmentKey2)" @click="choosePayment('PayStar')" :disabled="notAllowRial" :class="{ 'bg-gray-600 bg-opacity-30 text-TFL-dashText2': this.paymentType !== 'PayStar','bg-TFL-base1  text-white': this.paymentType === 'PayStar' }" class="sm:col-span-6 col-span-12 text-xs py-2 px-4 m-1 rounded-lg">ریالی</button> -->
                            <button v-if="loadToken " @click="choosePayment('PayStar')" :disabled="notAllowRial" :class="{ 'bg-gray-600 bg-opacity-30 text-TFL-dashText2': this.paymentType !== 'PayStar','bg-TFL-base1  text-white': this.paymentType === 'PayStar' }" class="sm:col-span-6 col-span-12 text-xs py-2 px-4 m-1 rounded-lg">ریالی</button>
                            <!-- <button v-if="loadToken" @click="choosePayment('Rial2')" :disabled="notAllowRial" :class="{ 'bg-gray-600 bg-opacity-30 text-TFL-dashText2': this.paymentType !== 'Rial2','bg-TFL-base1  text-white': this.paymentType === 'Rial2' }" class="sm:col-span-4 col-span-12 text-xs py-2 px-4 m-1 rounded-lg">ریالی (رایان پی)</button> -->
                            <!-- <button v-if="loadToken" @click="choosePayment('Rial2')" :disabled="notAllowRial" :class="{ 'bg-gray-600 bg-opacity-30 text-TFL-dashText2': this.paymentType !== 'Rial2','bg-TFL-base1  text-white': this.paymentType === 'Rial2' }" class="sm:col-span-4 col-span-12 text-xs py-2 px-4 m-1 rounded-lg">ریالی (رایان پی)</button> -->
                            <button v-if="!loadToken"  :class="{ 'bg-gray-600 bg-opacity-30 text-TFL-dashText2': this.paymentType !== 'Rial','bg-TFL-base1  text-white': this.paymentType === 'Rial' }" class="col-span-6 text-xs py-2 px-4 m-1 rounded-lg">
                                <div role="status justify-self-center">
                                    <svg aria-hidden="true" class="inline w-5 h-5  text-gray-300 animate-spin  fill-white " viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                    </svg>
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </button>
                            <div   v-if="paymentType == 'Crypto'" class=  "bg-TFL-red1 order-3  text-white text-right text-xs col-span-12 px-2  p-1 m-1     rounded-lg">     
                                برای استفاده از درگاه  کریپتو از آیپی خارج از ایران استفاده نمایید.
                              </div>
                              <div   v-if="paymentType == 'Rial' || paymentType == 'PayStar'" class=  "bg-TFL-red1 text-right order-3  text-white text-xs col-span-12 px-2  p-1 m-1     rounded-lg">     
                                برای استفاده از درگاه ریالی با آیپی ایران وارد شوید.
                              </div>
                              <div   v-if="notAllowRial && (paymentType == 'Rial' || paymentType == 'PayStar') " class=  "bg-TFL-red1 order-3 text-right  text-white text-xs col-span-12 px-2  p-1 m-1     rounded-lg">     
                                برای استفاده از درگاه ریالی از قسمت "پروفایل" شماره همراه خود را تائید کنید.
                              </div>
                            <!-- <button v-if="!loadToken"  :class="{ 'bg-gray-600 bg-opacity-30 text-TFL-dashText2': this.paymentType !== 'Rial2','bg-TFL-base1  text-white': this.paymentType === 'Rial2' }" class="col-span-6 text-xs py-2 px-4 m-1 rounded-lg">
                                <div role="status justify-self-center">
                                    <svg aria-hidden="true" class="inline w-5 h-5  text-gray-300 animate-spin  fill-white " viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                    </svg>
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </button> -->
                            
                            
                        </div>
                        </div>
                        <button v-if="!createdLoading"  class="bg-TFL-base1 w-full text-neutral-100 text-xs font-IranYekan font-bold rounded-lg p-2 mt-4 " @click="created()"> ثبت نام </button>
                        <button v-if="createdLoading"  class="bg-TFL-base1 w-full text-neutral-100 text-xs font-IranYekan font-bold rounded-lg p-2 mt-4 "> 
                            <div role="status justify-self-center">
                        <svg aria-hidden="true" class="inline w-5 h-5  text-gray-300 animate-spin  fill-white " viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                        </svg>
                        <span class="sr-only">Loading...</span>
                    </div>
                        </button>


                <div class="bg-TFL-card2  py-3 sm:flex sm:flex-row-reverse ">
                    <button @click="toggleSignup()" type="button" class="inline-flex w-full justify-center rounded-md bg-neutral-700 px-3 py-2 text-xs font-semibold text-white shadow-sm hover:bg-TFL-base1 ">بستن</button>
                </div>
            </div>
        </div>
    </div>
</div>
<div v-if="showRanking" class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="fixed inset-0 bg-gray-800 bg-opacity-70 transition-opacity"></div>

    <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-2 text-center sm:items-center sm:p-0">
            <div class="relative transform overflow-hidden p-4 rounded-lg bg-TFL-card2 text-left shadow-xl transition-all w-full max-w-lg">
                
                
                <table class="w-full  text-[10px]  bg-gray-800 text-right text-gray-500 round-10 table-auto">
            <thead  class="text-xs text-gray-300 uppercase bg-[#202020] round-10 ">
              <tr>
                 <th scope="col" class="px-2 py-3 text-center ">رتبه</th>
                 <th scope="col" class="px-2 py-3 text-center ">نام شرکت‌کننده</th> 
                 <th scope="col" class="px-2 py-3 text-center ">بالانس</th> 
                 <th scope="col" class="px-2 py-3 text-center ">اکویتی</th> 
                 <th scope="col" class="px-2 py-3 text-center ">شماره حساب</th> 
                 <th scope="col" class="px-2 py-3 text-center ">پسورد</th> 
                 <th scope="col" class="px-2 py-3 text-center "> بروکر</th> 
                 <th scope="col" class="px-2 py-3 text-center ">سرور </th> 
                 
              </tr>
            </thead> 
            <tbody  class="bg-[#363636] bg-opacity-30  ">
              <tr v-for="rank in ranking"  class="text-gray-300  hover:bg-[#404040] border-b-[#404040]">
                <td class="px-2 py-2 text-center">{{(rank.rank )}}</td>
                <td class="px-2 py-2 text-center">{{(rank.name )}}</td>
                <td class="px-2 py-2 text-center">{{(rank.balance )}}</td>
                <td class="px-2 py-2 text-center">{{(rank.equity )}}</td>
                <td class="px-2 py-2 text-center">{{(rank.broker )}}</td>
              </tr>
            </tbody>
          </table>

                <div class="bg-TFL-card2  py-3 sm:flex sm:flex-row-reverse ">
                    <button @click="toggleRanking()" type="button" class="inline-flex w-full justify-center rounded-md bg-neutral-700 px-3 py-2 text-xs font-semibold text-white shadow-sm hover:bg-TFL-base1 ">بستن</button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from 'axios';
import { digitsEnToFa } from 'persian-tools';
import URL from "../URL";
export default {
    data() {
        return {
        tournaments: [],
        broker: 'Roboforex',
        platform: 'Mt4',
        serverName: 'RoboForex-Demo',
        leverage: '100',
        deposit: 'K100',
        confirmedDiscountCode: '',
        plan: 'Competition',
        paymentType: 'Crypto',
        WithdrawType: 'Common',
        tournamentId: '',
        response: false,
        accountData:[],
        showAccountDetail: false,
        tournamentSignup: false,
        serverNameList: [],
        payStarReq: [],
        loadToken: false,
        
        notAllowRial: false,
        price: 0,

        AccountDataLoaded: true,
        ranking: [],
        showRanking: false,
        leaderBoardLoaded: true,

        tournamentName: '',

        searching: false,
        showMessage: false,

        entryFee: 0,
        createdLoading: false,
        





        };
    },
    watch: {
        broker() {
      // Call the API whenever the variable changes
      this.getServer();
    },
},
    methods: {
        brokerButtonClick(value){
            this.broker = value;
        },
        choosePayment(value){
            this.paymentType = value;
            
        },
        serverNameChange(index){
            this.serverName = this.serverNameList[index].name;
        },
        payStarCreate(){
    axios.post(`https://api.paystar.shop/api/pardakht/create`, this.payStarReq, {
        headers :{
            'Content-Type': 'application/json',
            'Authorization': 'Bearer d5l9z3xgqkxx7z',
        }
    })
    .then ( response => {
        // this.postPaystar(response.data.data.token);
        window.location.href = `https://api.paystar.shop/api/pardakht/payment?token=${response.data.data.token}`;
    }
    )
},
        getServer() {
  // POST request using axios with error handling
  
        this.serverNameList = '';
        this.serverName = null;
        const url = URL.baseUrl + `Meta/Broker/${this.broker}`;
        axios.get(url,
       
            {
            headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        }
            
        
        )
        .then(response => {
            // Handle successful response here
            
            this.serverNameList = response.data;
            
        })
            

            .catch(error => {
            this.errorMessage = error.message;
            
            });
},
        checkToken(){
         const url = URL.baseUrl + `users/whoami`
         axios.get(url,{
        headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    })
        .then(response => {
         this.developmentUID = response.data.uId;
         if(response.data.phoneNumberConfirmed == false){
           this.notAllowRial = true

         }
         if(response.data.phoneNumberConfirmed == true){
             this.notAllowRial = false;
           
         }
         this.loadToken = true;
          
          
        })
        .catch(error => {
          
          window.location.href = '/login';


        });
      },
        toggleAccount(){
            this.showAccountDetail = false;
        },
        toggleRanking(){
            this.showRanking = false;
        },
        toggleSignup(){
            this.tournamentSignup = !this.tournamentSignup;
        },
        async getAccountData() {
            this.AccountDataLoaded = false;
            
            try {
                const url = URL.baseUrl + `Meta/account/tournaments/${this.tournamentId}`;
                const response = await axios.get(url, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('token')
                    }
                });
                this.accountData = response.data;
                this.showAccountDetail = true;
                this.AccountDataLoaded = true;
            } catch (error) {
                console.error(error);
                this.AccountDataLoaded = true;
            }
        },
        async leaderBoard() {
            this.leaderBoardLoaded = false;
            try {
                const url = URL.baseUrl + `Meta/account/tournaments/leaderboard/${this.tournamentId}`;
                const response = await axios.get(url, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('token')
                    }
                });
                this.ranking = response.data;
                this.leaderBoardLoaded = true;
                this.showRanking = true;
                
            } catch (error) {
                
                this.leaderBoardLoaded = true;
            }
        },
        created() {
    this.createdLoading = true;
    this.response = !this.response;
    const url = URL.baseUrl + `Meta/account`;

    axios.post(url, 
    { 
        broker: this.broker,
        platform: this.platform,
        serverName: this.serverName,
        leverage: this.leverage,
        deposit: this.deposit,
        discountCode: this.confirmedDiscountCode,
        plan: this.plan,
        paymentType: this.paymentType,
        WithdrawType: this.WithdrawType,
        tournamentId: this.tournamentId
    }, 
    {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    })
    .then(response => {
        this.response = !this.response;
        this.createdLoading = false;
        if(this.price == 0){
            this.tournamentSignup = false;
            this.getTournaments();
            
        }
        else{
            if (this.paymentType !== 'PayStar') {
            // ریدایرکت مستقیم برای پرداخت‌های غیر از PayStar
            window.location.href = response.data.invoiceUrl;
        } else {
            // پرداخت با PayStar
            // this.postPaystar(response.data.token, response.data.invoiceUrl);
            this.payStarReq = response.data;
            this.payStarCreate();
        }
        }
        
    })
    .catch(error => {
        this.createdLoading = false;
        this.response = !this.response;
       
    });
},
async getTournaments() {
    this.showMessage = false;
    this.searching = true;

try {
    const url = URL.baseUrl + `Meta/tournaments/${this.tournamentName}`;
    const response = await axios.get(url, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    });
    this.tournaments = response.data;
    this.searching = false;

} catch (error) {
    console.error(error);
    if (error.response.status === 401) {
        window.location.href = '/login';
    }
    if (error.response.status === 404) {
        this.showMessage = true;
    }
    this.searching = false;
}
},
        
    },
    mounted() {
        this.getTournaments();
        this.getServer();
        this.checkToken();
    },

}

</script>

<style>

</style>