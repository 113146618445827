<template>
    <div class="bg-TFL-background    grid grid-cols-2  ">
      <div class=" col-span-2 grid grid-cols-2 max-w-screen-xl my-2 mx-auto">
            <p class=" p-2 py-4 col-span-2 text-center font-bold text-4xl mx-4 bg-gradient-to-b from-TFL-base1  to-TFL-base2 from-30%  text-transparent bg-clip-text ">قوانین  </p>
        </div>
        <div class="col-span-2  w-full mx-auto  text-center my-10    rounded-3xl   max-w-screen-xl p-2">
          <div class="grid grid-cols-12 mx-auto" >
                        <button @click="rulesButtonClick('General')" :class="{ 'bg-gray-600 bg-opacity-30 text-TFL-dashText2': this.rules !== 'General','bg-TFL-base1  text-white': this.rules === 'General' }" class="lg:col-span-3 col-span-12 inline-flex items-center justify-center py-2 px-4 m-1 rounded-lg">قوانین عمومی </button>
                        <button @click="rulesButtonClick('twoSteps')" :class="{ 'bg-gray-600 bg-opacity-30 text-TFL-dashText2': this.rules !== 'twoSteps','bg-TFL-base1  text-white': this.rules === 'twoSteps' }" class="lg:col-span-3 col-span-12 py-2 px-4 m-1 rounded-lg">قوانین چالش دو مرحله‌ای</button>
                        <button @click="rulesButtonClick('Rapid')" :class="{ 'bg-gray-600 bg-opacity-30 text-TFL-dashText2': this.rules !== 'Rapid','bg-TFL-base1  text-white': this.rules === 'Rapid' }" class="lg:col-span-3 col-span-12 py-2 px-4 m-1 rounded-lg">قوانین پلن مستقیم</button>
                        <button @click="rulesButtonClick('Multiple')" :class="{ 'bg-gray-600 bg-opacity-30 text-TFL-dashText2': this.rules !== 'Multiple','bg-TFL-base1  text-white': this.rules === 'Multiple' }" class="lg:col-span-3 col-span-12 py-2 px-4 m-1 rounded-lg">قوانین پلن 5X</button>
                        
                        
                    </div>
            <div>
              <div v-if="rules=='General'" class="bg-TFL-background">

                <div  v-for="(item, index) in GeneralRules" :key="index" class="my-2">
                  <h2 class="accordion-header">
                    <button
                      class=" flex items-center justify-between w-full px-4 py-2 rounded-l-lg text-left  bg-gray-700 bg-opacity-50 hover:bg-gray-600 font-medium text-TFL-dashText2 border-r-4 border-TFL-base2    "
                      @click="toggleAccordion(index)"
                    >
                      <span>{{ item.title }}</span>
                      <svg
                        class="accordion-icon w-4 h-4 ml-2 text-gray-500 transition-transform duration-200 transform"
                        :class="{ 'rotate-180': activeIndex === index }"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path d="M19 9l-7 7-7-7"></path>
                      </svg>
                    </button>
                  </h2>
                  <transition >
            
                      <div  v-if="activeIndex === index" >
                        <div class="border-r-4 px-10 py-2 my-4 border-TFL-base1 bg-gray-600 bg-opacity-30 text-right text-gray-300 ">
                          <!-- Use v-html directive to render HTML content -->
                          <div class="text-sm font-medium line-height "  v-html="formatNumber(item.content)"></div>
                        </div>
                      </div>
                  </transition>
                </div>
              </div>
              <div v-if="rules=='twoSteps'" class="bg-TFL-background">
                <div class=" flex items-center justify-between w-full px-4 py-2 rounded-l-lg text-right text-red-500  bg-gray-700 bg-opacity-50 hover:bg-gray-600 font-medium text-TFL-dashText2 border-r-4 border-TFL-base2    "> پیش از مطالعه قوانین هر یک از پلن‌ها، قوانین عمومی را مطالعه فرمایید</div>

                <div  v-for="(item, index) in twoStepsRules" :key="index" class="my-2">
                  <h2 class="accordion-header">
                    <button
                      class=" flex items-center justify-between w-full px-4 py-2 rounded-l-lg text-left  bg-gray-700 bg-opacity-50 hover:bg-gray-600 font-medium text-TFL-dashText2 border-r-4 border-TFL-base2    "
                      @click="toggleAccordion(index)"
                    >
                      <span>{{ item.title }}</span>
                      <svg
                        class="accordion-icon w-4 h-4 ml-2 text-gray-500 transition-transform duration-200 transform"
                        :class="{ 'rotate-180': activeIndex === index }"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path d="M19 9l-7 7-7-7"></path>
                      </svg>
                    </button>
                  </h2>
                  <transition >
            
                      <div  v-if="activeIndex === index" >
                        <div class="border-r-4 px-10 py-2 my-4 border-TFL-base1 bg-gray-600 bg-opacity-30 text-right text-gray-300 ">
                          <!-- Use v-html directive to render HTML content -->
                          <div class="text-sm font-medium line-height "  v-html="formatNumber(item.content)"></div>
                        </div>
                      </div>
                  </transition>
                </div>
              </div>
              <div v-if="rules=='Rapid'" class="bg-TFL-background">
                <div class=" flex items-center justify-between w-full px-4 py-2 rounded-l-lg text-right text-red-500  bg-gray-700 bg-opacity-50 hover:bg-gray-600 font-medium text-TFL-dashText2 border-r-4 border-TFL-base2    "> پیش از مطالعه قوانین هر یک از پلن‌ها، قوانین عمومی را مطالعه فرمایید</div>

                <div  v-for="(item, index) in RapidRules" :key="index" class="my-2">
                  <h2 class="accordion-header">
                    <button
                      class=" flex items-center justify-between w-full px-4 py-2 rounded-l-lg text-left  bg-gray-700 bg-opacity-50 hover:bg-gray-600 font-medium text-TFL-dashText2 border-r-4 border-TFL-base2    "
                      @click="toggleAccordion(index)"
                    >
                      <span>{{ item.title }}</span>
                      <svg
                        class="accordion-icon w-4 h-4 ml-2 text-gray-500 transition-transform duration-200 transform"
                        :class="{ 'rotate-180': activeIndex === index }"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path d="M19 9l-7 7-7-7"></path>
                      </svg>
                    </button>
                  </h2>
                  <transition >
            
                      <div  v-if="activeIndex === index" >
                        <div class="border-r-4 px-10 py-2 my-4 border-TFL-base1 bg-gray-600 bg-opacity-30 text-right text-gray-300 ">
                          <!-- Use v-html directive to render HTML content -->
                          <div class="text-sm font-medium line-height "  v-html="formatNumber(item.content)"></div>
                        </div>
                      </div>
                  </transition>
                </div>
              </div>
              <div v-if="rules=='Multiple'" class="bg-TFL-background">
                <div class=" flex items-center justify-between w-full px-4 py-2 rounded-l-lg text-right text-red-500  bg-gray-700 bg-opacity-50 hover:bg-gray-600 font-medium text-TFL-dashText2 border-r-4 border-TFL-base2    "> پیش از مطالعه قوانین هر یک از پلن‌ها، قوانین عمومی را مطالعه فرمایید</div>
                <div  v-for="(item, index) in MultipleRules" :key="index" class="my-2">
                  <h2 class="accordion-header">
                    <button
                      class=" flex items-center justify-between w-full px-4 py-2 rounded-l-lg text-right  bg-gray-700 bg-opacity-50 hover:bg-gray-600 font-medium text-TFL-dashText2 border-r-4 border-TFL-base2    "
                      @click="toggleAccordion(index)"
                    >
                      <span>{{ item.title }}</span>
                      <svg
                        class="accordion-icon w-4 h-4 ml-2 text-gray-500 transition-transform duration-200 transform"
                        :class="{ 'rotate-180': activeIndex === index }"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path d="M19 9l-7 7-7-7"></path>
                      </svg>
                    </button>
                  </h2>
                  <transition >
            
                      <div  v-if="activeIndex === index" >
                        <div class="border-r-4 px-10 py-2 my-4 border-TFL-base1 bg-gray-600 bg-opacity-30 text-right text-gray-300 ">
                          <!-- Use v-html directive to render HTML content -->
                          <div class="text-sm font-medium line-height "  v-html="formatNumber(item.content)"></div>
                        </div>
                      </div>
                  </transition>
                </div>
              </div>
  </div>
    </div>
    </div>
</template>

<script>
import Rules from '../Rules';

export default {
  data() {
    return {
      activeIndex: null,
      rules: 'General',
      GeneralRules: Rules.GeneralRules,
      twoStepsRules: Rules.twoStepsRules,
      RapidRules: Rules.RapidRules,
      MultipleRules: Rules.MultipleRules,
    };
  },
  methods: {
    toggleAccordion(index) {
      if (this.activeIndex === index) {
        this.activeIndex = null;
      } else {
        this.activeIndex = index;
      }
    },
    formatNumber(number) {
      const formattedNumber = number.toLocaleString('en-US');
      return digitsEnToFa(formattedNumber);
    },
    rulesButtonClick(value){
            this.rules = value;
            
            
        },
  },
};
</script>

<style>

.line-height p {
  line-height: 2;
}
.accordion {
  width: 100%;
}

.accordion-item {
  border: 1px solid #e2e8f0;
}

.accordion-title {
  background-color: #edf2f7;
  padding: 1rem;
}

.accordion-title:hover {
  background-color: #e2e8f0;
}

.accordion-title:focus {
  outline: none;
}

.accordion-title .rotate-180 {
  transform: rotate(180deg);
}

.accordion-content {
  padding: 1rem;
}
</style>