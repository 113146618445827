<template>
    <div class="grid grid-cols-12 m-2">
        
        <div class=" col-span-12 grid grid-cols-12 border rounded-lg border-surface-border  bg-white      ">


        <div class="sm:col-span-4 col-span-12 flex flex-col  items-center justify-center ml-2   text-gray-800 ">
            <div class="flex flex-col flex-grow w-full  h-96  bg-white shadow-xl rounded-lg overflow-hidden">
                <div class=" h-96 overflow-auto">
                    <div @click="fetchTicketChat(index)" v-for="(ticket, index) in ticketList" :key="index" class=" h-16 m-2 p-2   hover:bg-gray-200 bg-astro-300 cursor-pointer rounded-lg">
                        <div class="flex">

                            <div class="mx-1 mb-1 text-sm">{{ formatNumber(ticket.title)}}</div>
                            <span v-if="ticket.seenByUser == false" class="relative justify-self-center mx-2 flex h-3 w-3 mr-auto">
                  <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-blue-500 opacity-75 mr-auto"></span>
                  <span class="relative inline-flex rounded-full h-3 w-3 bg-blue-600 mr-auto"></span>
                  </span>
                            <span v-if="ticket.seenByAdmin == false" class="relative justify-self-center mx-2 flex h-3 w-3 mr-auto">
                  <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-500 opacity-75 mr-auto"></span>
                  <span class="relative inline-flex rounded-full h-3 w-3 bg-green-600 mr-auto"></span>
                  </span>
                        </div>
                        <div class="flex">
                            <div class="mb-1 mx-1 text-xs inline-flex items-center text-gray-400">{{ formatNumber( ticket.createDateTime.replace("T", " در  ").replace("+00:00", "").substring(0, 20).toLocaleString('fa-IR').replace(/٬/g,''))}}</div>
                            <div v-if="ticket.category == 'General'" class=" self-start bg-green-500 text-green-800 text-xs   px-2.5 py-0.5 rounded bg-opacity-50 mx-1 mb-2 mr-auto">عمومی</div>
                            <div v-if="ticket.category == 'Technical'" class=" self-start bg-green-500 text-green-800 text-xs   px-2.5 py-0.5 rounded bg-opacity-50 mx-1 mb-2 mr-auto">فنی</div>
                            <div v-if="ticket.category == 'Financial'" class=" self-start bg-green-500 text-green-800 text-xs   px-2.5 py-0.5 rounded bg-opacity-50 mx-1 mb-2 mr-auto">مالی</div>
                            

                        </div>
                        
                    </div>
                    
                   
                    

                </div>
                <button @click="changeNewTicket" class="m-2 p-2  rounded-lg bg-green-100 hover:bg-green-200 text-white">
                    ایجاد تیکت جدید
                </button>
            </div>
        </div>
        <div class="sm:col-span-8 col-span-12 flex flex-col  items-center justify-center  text-gray-800 ">

<!-- Component Start -->
            <div v-if="!newTicket" class="flex flex-col flex-grow w-full h-96  bg-white shadow-xl rounded-lg  overflow-hidden" id="myDiv">
                <div  class="flex flex-col flex-grow h-0 p-4 pl-2  overflow-auto">
                    <div v-if="!ticketChat[0]" class="flex w-full mt-2  space-x-3 mr-auto justify-end  max-w-lg">
                        <div>
                            <div class="bg-gray-300 p-3  max-w-lg  rounded-xl ml-2">
                                <p class="text-sm ">کاربر گرامی،</p>
                                <p class="text-sm ">جهت ارسال تیکت از دکمه ایجاد تیکت جدید استفاده نمایید. </p>
                                <p class="text-sm ">حداکثر زمان پاسخگویی 24 ساعت می‌باشد</p>
                                <p class="text-sm ">از صبوری شما سپاسگزاریم.</p>
                            </div>
                            
                        </div>
                        <div class="hidden lg:block flex-shrink-0 h-10 w-10  rounded-full bg-gray-300"></div>
                    </div>
                   <div v-for="(msg, index) in ticketChat" :key="index" >
                       <div v-if="thisIsMyUId != msg.userId" class="flex w-full mt-2  space-x-3 mr-auto justify-end  max-w-lg">
                           <div>
                               <div class="bg-gray-300 p-3  max-w-lg  rounded-xl ml-2">
                                   <p class=" text-ellipsis overflow-hidden text-sm " v-html="formatMessage(msg.message)"></p>
                               </div>
                               <span class="text-xs text-gray-500 leading-none">{{formatNumber( msg.createDateTime.replace("T", " در ساعت ").replace("+00:00", "").substring(0, 27).toLocaleString('fa-IR').replace(/٬/g,''))}}</span>
                           </div>
                           <div class="hidden lg:block flex-shrink-0 h-10 w-10  rounded-full bg-gray-300"></div>
                       </div>
   
                       <div v-if="thisIsMyUId == msg.userId" class="flex w-full mt-2 space-x-3 max-w-xs ml-auto justify-start">
                           <div class=" hidden lg:block flex-shrink-0 h-10 w-10 rounded-full bg-gray-300"></div>
                           <div>
                               <div class="bg-astro-400 text-white p-3 rounded-2xl mr-2 ">
                                   <p class=" text-ellipsis overflow-hidden text-sm" v-html="formatMessage(msg.message)"></p>
                               </div>
                               <span class="text-xs text-gray-500 leading-none">{{formatNumber( msg.createDateTime.replace("T", " در ساعت ").replace("+00:00", "").substring(0, 27).toLocaleString('fa-IR').replace(/٬/g,''))}}</span>
                           </div>
                       </div>

                   </div>                      

                </div> 
                

                    
                    
                    
                

                <div class=" grid grid-cols-12 p-2">
                    <form class="col-span-12">
                        <div class="flex items-center  ">
                            <textarea v-if="ticketChat[0]" v-model="messageText" id="chat" rows="1" class="block mx-1 p-2.5 w-full text-sm text-gray-900 bg-white rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 " placeholder=" پیام خود را بنویسید"></textarea>
                                <button @click.prevent="SendMessage" class="inline-flex justify-center p-2 text-green-100 rounded-full cursor-pointer hover:bg-green-100 hover:text-white  ">
                                    <svg  v-if="ticketChat[0]" class="w-5 h-5 -rotate-90" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                                        <path d="m17.914 18.594-8-18a1 1 0 0 0-1.828 0l-8 18a1 1 0 0 0 1.157 1.376L8 18.281V9a1 1 0 0 1 2 0v9.281l6.758 1.689a1 1 0 0 0 1.156-1.376Z"/>
                                    </svg>

                                </button>
                        </div>
                    </form>
                </div>
            </div>
            <div v-if="newTicket" class="flex flex-col flex-grow w-full h-96  bg-white shadow-xl rounded-lg  overflow-hidden" id="myDiv">
                <div  class="flex flex-col flex-grow h-0 p-4 pl-2  overflow-auto">
                    
                        <div>ارسال تیکت جدید</div>
                        <div v-if="showSuccessCreated" class=" border border-green-500 bg-green-400 bg-opacity-30 text-green-700 text-sm rounded-lg p-2 my-2 "><p> تیکت شما با موفقیت ایجاد شد</p> </div>
                    
                    <div class=" ml-2 mt-6">
                      <label for="small-input" class="block mb-1  text-sm font-medium text-gray-900 ">عنوان تیکت:</label>
                      <input v-model="title" type="text" id="small-input" class="block w-full p-2   text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs  ">
                    </div>
                    <div class ="grid grid-cols-2 mt-2">

                        <div class=" col-span-1 ml-2  mt-1">
                          <label for="small" class="block mb-1 text-sm font-medium text-gray-900 ">موضوع تیکت:</label>
                          <select v-model="category" id="small" class="block w-full p-1   text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs  ">
                            
                            <option value="General">عمومی</option>
                            <option value="Technical">فنی</option>
                            <option value="Financial">مالی</option>
                            
                          </select>
                        </div>
                        <div class=" col-span-1 ml-2   mt-1">
                          <label for="small" class="block mb-1 text-sm font-medium text-gray-900 ">اهمیت تیکت:</label>
                          <select v-model="priority" id="small" class="block w-full p-1   text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-xs  ">
                            <option value="Low">کم</option>
                            <option value="Medium">متوسط</option>
                            <option value="High">زیاد</option>
                            <option value="Urgent">ضروری</option>
                          </select>
                        </div>
                    </div>
                    
                </div> 
                

                    
                    
                    
                

                <div class=" grid grid-cols-12 p-2">
                    <form class="col-span-12">
                        <div class="flex items-center  ">
                            <textarea  v-model="message" id="chat" rows="6" class="block mx-1 bg-gray-50 p-2.5 w-full text-sm text-gray-900  rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 " placeholder=" پیام خود را بنویسید"></textarea>
                                <button @click.prevent="CreateNewTicket" class="inline-flex justify-center p-2 text-green-100 rounded-full cursor-pointer hover:bg-green-100 hover:text-white  ">
                                    <svg   class="w-5 h-5 -rotate-90" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                                        <path d="m17.914 18.594-8-18a1 1 0 0 0-1.828 0l-8 18a1 1 0 0 0 1.157 1.376L8 18.281V9a1 1 0 0 1 2 0v9.281l6.758 1.689a1 1 0 0 0 1.156-1.376Z"/>
                                    </svg>

                                </button>
                        </div>
                    </form>
                </div>
            </div>
            <!-- Component End  -->

            </div>
    </div>
    </div>
    
    
</template>


<script>
import axios from 'axios';
import URL from "../URL";
export default {
    data(){
        return{
            ticketList:[],
            ticketChat:[],
            thisIsMyUId:'',
            messageText: '',
            selectedTicket: '',
            newTicket: false,
            State: 'Open',
            title: '',
            message: '',
            category: 'General',
            priority: 'Low',
            showSuccessCreated: false,
            getUID: this.$route.params.id,

        }
    },
    mounted(){
        this.fetchTicketList();
        this.checkToken();
        // this.scrollToBottom();
    },
    methods:{
        formatMessage(message) {
          return message.replace(/\n/g, '<br>');
        },
        changeNewTicket(){
            this.newTicket = !this.newTicket;
            this.showSuccessCreated = false;
        },
        formatNumber(number) {
          const formattedNumber = number.toLocaleString('en-US');
          return digitsEnToFa(formattedNumber);
        },
        fetchTicketList(){
            const url = URL.baseUrl + `Admin/ticket?UserId=${this.getUID}`;
            axios.get(url,
            
            {
                headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })
            .then(response => {
          // loop through response data and push each object into trades array
              this.ticketList = response.data;
            })
            .catch(error => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
            });
        },
        fetchTicketChat(index){
            const url = URL.baseUrl + `Admin/ticket/${this.ticketList[index].uId}`;
            axios.get(url,
            
            {
                headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })
            .then(response => {
          // loop through response data and push each object into trades array
              this.ticketChat = response.data;
              this.selectedTicket = response.data[0].ticketThreadId;
              this.fetchTicketList();
              this.newTicket = false;
            })
            .catch(error => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
            this.newTicket = false;
            });
        },
        checkToken(){
         const url = URL.baseUrl + `users/whoami`;
         axios.get(url,{
        headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    })
        .then(response => {
            this.thisIsMyUId = response.data.uId;
          
          
          // age az accountList omade bud
        })
        .catch(error => {
          console.error(error);
          


        });
      },
      SendMessage() {
            
  
        const url = URL.baseUrl + `Admin/ticket/message`;
        axios.post(url,
        { 
           
            
            ticketThreadId: this.selectedTicket,
            message: this.messageText,
            state: this.State,
            
            },
            {
         headers: {
           'Content-Type': 'application/json',
           'Authorization': 'Bearer ' + localStorage.getItem('token')
           }
         }
            
        
        )
        .then(response => {
            // Handle successful response here
            console.log(response.data);
            this.messageText = '';
            this.fetchTicketList();
            this.fetchTicketChat(0);
        })
            

            .catch(error => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
            
            });
},
      CreateNewTicket() {
            
  
        const url = URL.baseUrl + `Admin/ticket/${this.getUID}`;
        axios.post(url,
        { 
            title: this.title,
            message: this.message,
            category: this.category,
            priority: this.priority,
            
            
            
            
            },
            {
         headers: {
           'Content-Type': 'application/json',
           'Authorization': 'Bearer ' + localStorage.getItem('token')
           }
         }
            
        
        )
        .then(response => {
            // Handle successful response here
            console.log(response.data);
            this.messageText = '';
            this.fetchTicketList();
            this.message = '';
            this.title = '';
            this.showSuccessCreated = true;
            
        })
            

            .catch(error => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
            
            });
},
       
    }
}
</script>