<template>
  <div class="w-full col-span-12 grid grid-cols-12 bg-TFL-cardDashboard">  
    <div class=" rounded-10 col-span-12 justify-between  ">
      <div class="col-span-12 items-center justify-between">
      </div>
      
      <div class="col-span-12 overflow-x-auto ">
        <!-- Parent div with overflow property -->
        <div class="w-full overflow-auto rounded-lg">
          <table class="w-full  text-[10px]  bg-gray-800 text-right text-gray-500 round-10 table-auto">
            <thead  class="text-xs text-gray-300 uppercase bg-[#202020] round-10 ">
              <tr>
                 <th scope="col" class="px-2 py-3 text-center ">#</th>
                 <th scope="col" class="px-2 py-3 text-center ">تیکت</th> 
                 <th scope="col" class="px-2 py-3 text-center ">نماد</th> 
                 <th scope="col" class="px-2 py-3 text-center ">نوع</th> 
                 <th scope="col" class="px-2 py-3 text-center ">حجم</th> 
                 <th scope="col" class="px-2 py-3 text-center ">سود/ضرر</th> 
                 <th scope="col" class="px-2 py-3 text-center ">سود/ضرر %</th> 
                 <th scope="col" class="px-2 py-3 text-center ">زمان شروع</th> 
                 <th scope="col" class="px-2 py-3 text-center ">زمان پایان</th> 
                 <th scope="col" class="px-2 py-3 text-center ">قیمت ابتدا</th> 
                 <th scope="col" class="px-2 py-3 text-center ">قیمت انتها</th> 
                 <th scope="col" class="px-2 py-3 text-center ">حداکثر افت %</th>
                 <th scope="col" class="px-2 py-3 text-center ">  سود مجاز</th>
              </tr>
            </thead> 
            <tbody  class="bg-[#363636] bg-opacity-30  ">
              <tr v-for="(trade, index) in tradeHistory" :key="index" class="text-gray-300  hover:bg-[#404040] border-b-[#404040]">
                <td class="px-2 py-2 text-center">{{ formatNumber(index )}}</td>
                <td class="px-2 py-2 text-center">{{ formatNumber(trade.ticket).replace(/,/g,'') }}</td>
                <td class="px-2 py-2 text-center">{{ trade.symbol }}</td>
                <td class="px-2 py-2 text-center">{{ trade.type }}</td>
                <td class="px-2 py-2 text-center">{{ formatNumber(trade.lots) }}</td>
                <td v-if="trade.totalProfit >= 0" class="text-TFL-green1 px-2 py-2 text-center">{{ formatNumber(trade.totalProfit.toFixed(2)).replace(/٬/g,'') }}</td>
                <td v-if="trade.totalProfit < 0" class="text-TFL-red1 px-2 py-2 text-center">{{ formatNumber(trade.totalProfit.toFixed(2)).replace(/٬/g,'') }}</td>
                <td v-if="trade.totalProfit >= 0" class="text-TFL-green1 px-2 py-2 text-center">{{ formatNumber(trade.totalProfitInPercent.toFixed(2)) }} %</td>
                <td v-if="trade.totalProfit < 0" class="text-TFL-red1 px-2 py-2 text-center">{{ formatNumber(trade.totalProfitInPercent.toFixed(2)) }} %</td>
                <td class="px-2 py-2 text-center">{{ formatNumber(trade.openTime.replace("T", " - ").replace("+00:00", "")) }}</td>
                <td class="px-2 py-2 text-center">{{ formatNumber(trade.closeTime.replace("T", " - ").replace("+00:00", "")) }}</td>
                <td class="px-2 py-2 text-center">{{ formatNumber(trade.openPrice) }}</td>
                <td class="px-2 py-2 text-center">{{ formatNumber(trade.closePrice) }}</td>
                <td class="px-2 py-2 text-center">{{ formatNumber(trade.maxDrawDownInPercent.toFixed(2)) }} %</td>
                <td v-if="trade.profitNotAllowed" class="px-2 py-2 text-center">خیر</td>
                <td v-if="!trade.profitNotAllowed" class="px-2 py-2 text-center">بله</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>





<script>
import { digitsEnToFa } from 'persian-tools';
export default {
  props: {
    tradeHistory: {
      type: Array,
      required: true,
    },
  },
  
  methods:{
    formatNumber(number) {
      const formattedNumber = number.toLocaleString('en-US');
      return digitsEnToFa(formattedNumber);
    },
  }
};
</script>