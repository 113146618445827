<template>
  <div class="grid grid-cols-12">
    <div class="lg:col-span-6 col-span-12 lg:h-[550px] bg-TFL-cardDashboard  rounded-lg m-2 mt-4 p-2">
      <p class="font-bold text-lg text-white mx-2 my-2">گزارش حساب </p>
      <div class="bg-TFL-innerCard px-4 py-2 rounded-xl font-bold shadow-[rgba(0,_0,_0,_0.50)_0px_3px_8px]">
        <div class="flex justify-between my-1 items-center">
        <p class="text-xs text-neutral-400">شماره حساب:</p>
        <p class="text-xs text-neutral-200">{{ accountData.accountId }}</p>
      </div>
      <div class="flex justify-between my-1 items-center">
        <p class="text-xs text-neutral-400"> پایان تورنومنت:</p>
        
        <p dir="ltr" class="text-xs text-neutral-200">{{ accountData.expireTime.slice(0, 10) }} - {{ accountData.expireTime.slice(11, 16) }}</p>
      </div>
      <div class="flex justify-between my-1 items-center">
        <p class="text-xs text-neutral-400">وضعیت حساب:</p>
        <p v-if="accountData.state == 'Active'" class="text-xs text-TFL-green1">فعال</p>
        <p v-if="accountData.state == 'Failed' && accountData.failedBy == 'FailedByExpired'" class="text-xs text-TFL-red1">اتمام تورنومنت</p>
        <p v-if="accountData.state == 'Failed' && accountData.failedBy == 'FailedByMaxDrawDown'" class="text-xs text-TFL-red1">نقض قانون افت کلی </p>
        <p v-if="accountData.state == 'Failed' && accountData.failedBy == 'FailedByMinEquityDaily'" class="text-xs text-TFL-red1">نقض قانون افت روزانه </p>
        <p v-if="accountData.state == 'Failed' && accountData.failedBy == 'FailedByPositionDrawDown'" class="text-xs text-TFL-red1">نقض قانون افت شناور </p>
        <p v-if="accountData.state == 'Failed' && accountData.failedBy == 'FailedByTradeOnWeekends'" class="text-xs text-TFL-red1">نقض قانون ترید در تعطیلات  </p>
        <p v-if="accountData.state == 'Failed' && accountData.failedBy == 'FailedByTradeOnNews'" class="text-xs text-TFL-red1">نقض قانون ترید در زمان اخبار  </p>
        <p v-if="accountData.state == 'Failed' && accountData.failedBy == 'FailedByMaxTradePerDayInCompetition'" class="text-xs text-TFL-red1">نقض قانون تعداد مجاز ترید در روز </p>
        <p v-if="accountData.state == 'Failed' && accountData.failedBy == 'FailedByInvalidAccount'" class="text-xs text-TFL-red1">غیر فعال به علت قطع ارتباط</p>
      </div>  
      </div>
      <div class="bg-TFL-innerCard px-4 py-2 mt-2 rounded-xl shadow-[inset_0px_3px_8px_rgba(0,0,0,0.50)]">
        <div class="flex justify-between my-1 items-center">
          <p class="text-xs text-neutral-400">موجودی اولیه:</p>
          <p class="text-xs text-neutral-200 ">${{ accountData.actualDeposit }}</p>
        </div>
        <div class="flex justify-between my-1 items-center">
          <p class="text-xs text-neutral-400">بالانس:</p>
          <p class="text-xs text-neutral-200">${{ (accountData.balance).toFixed(1) }}</p>
        </div>
        <div class="flex justify-between my-1 items-center">
          <p class="text-xs text-neutral-400">اکویتی:</p>
          <p class="text-xs text-neutral-200">${{ accountData.equity.toFixed(1) }}</p>
        </div>
        <div class="flex justify-between my-1 items-center">
          <p class="text-xs text-neutral-400">بالانس ابتدای روز:</p>
          <p class="text-xs text-neutral-200">${{ accountData.lastDayBalanceOrEquity.toFixed(1) }}</p>
        </div>
      </div>
      <div class="bg-gradient-to-bl from-TFL-green1 via-[#16a34a] to-[#0f766e] rounded-xl mt-2 p-2">
        <div class="flex justify-between my-1 items-center">
          <p class="text-xs font-bold text-white [text-shadow:_0_2px_2px_rgb(0_0_0_/_0.8)]">سود حساب :</p>
          <p v-if="(accountData.balance - accountData.actualDeposit) > 0" class="text-xs font-bold text-white [text-shadow:_0_2px_2px_rgb(0_0_0_/_0.8)]">${{ (accountData.balance - accountData.actualDeposit).toFixed(1) }}</p>
          <p v-if="(accountData.balance - accountData.actualDeposit) <= 0" class="text-xs font-bold text-white [text-shadow:_0_2px_2px_rgb(0_0_0_/_0.8)]">$0</p>
        </div>

        

      </div>
      <div class="bg-TFL-innerCard px-4 py-2 mt-2 rounded-xl shadow-[inset_0px_3px_8px_rgba(0,0,0,0.50)]">
        <div class="flex justify-between my-1 items-center">
          <p class="text-xs text-neutral-400">افت روزانه :</p>
          <p class="text-xs text-neutral-200 ">بدون محدودیت</p>
        </div>
        <div  class="flex justify-between my-1 items-center">
          <p class="text-xs text-neutral-400">افت کلی:</p>
          <p v-if="(accountData.actualDeposit - accountData.balance) > 0" dir="ltr" class="text-xs text-neutral-200">{{ (((accountData.actualDeposit - accountData.balance)/accountData.actualDeposit)*100).toFixed(1) }}% / 12%</p>
          <p v-if="(accountData.actualDeposit - accountData.balance) <= 0" dir="ltr" class="text-xs text-neutral-200">0% / 12%</p>
        </div>
        <div  class="flex justify-between my-1 items-center">
          <p class="text-xs text-neutral-400">مانده افت کلی:</p>
          <p dir="ltr" class="text-xs text-neutral-200">${{ ((accountData.balance) - (accountData.actualDeposit*0.88)).toFixed(1) }}</p>
        </div>
        
      </div>
      <div v-if="leaderBoardLoaded" class=" px-2 py-4 mt-4 relative rounded-xl border-2 border-TFL-innerCard">
        <p class="bg-TFL-cardDashboard absolute -top-3 text-sm text-neutral-300 font-bold px-2">نفرات برتر تورنومنت</p>
        <div class="flex items-center justify-between bg-gradient-to-l my-1 from-[#EFBF04] to-TFL-cardDashboard h-8 rounded-lg">
          <div class="h-7 w-7 mx-0.5 rounded-lg bg-TFL-innerCard flex items-center justify-center">
            <p class="text-xs text-[#EFBF04] font-bold">1</p>
          </div>
          <p v-if="ranking[0]" class="text-xs text-white font-bold">{{ ranking[0].name }}</p>
          <p v-if="ranking[0]" class="text-xs text-white font-bold mx-4">${{ ranking[0].balance.toFixed(0) }}</p>
        </div>
        <div class="flex items-center justify-between bg-gradient-to-l my-1 from-[#C0C0C0] to-TFL-cardDashboard h-8 rounded-lg">
          <div class="h-7 w-7 mx-0.5 rounded-lg bg-TFL-innerCard flex items-center justify-center">
            <p class="text-xs text-[#C0C0C0] font-bold">2</p>
          </div>
          <p v-if="ranking[1]" class="text-xs text-white font-bold">{{ ranking[1].name }}</p>
          <p v-if="ranking[1]" class="text-xs text-white font-bold mx-4">${{ ranking[1].balance.toFixed(0) }}</p>
        </div>
        <div class="flex items-center justify-between bg-gradient-to-l my-1 from-[#CD7F32] to-TFL-cardDashboard h-8 rounded-lg">
          <div class="h-7 w-7 mx-0.5 rounded-lg bg-TFL-innerCard flex items-center justify-center">
            <p class="text-xs text-[#CD7F32] font-bold">3</p>
          </div>
          <p v-if="ranking[2]" class="text-xs text-white font-bold">{{ ranking[2].name }}</p>
          <p v-if="ranking[2]" class="text-xs text-white font-bold mx-4">${{ ranking[2].balance.toFixed(0) }}</p>
        </div>
      </div>

      
      
    </div>
    <div class="lg:col-span-6 col-span-12 lg:h-[550px]  bg-TFL-cardDashboard  rounded-lg m-2 mt-4 p-2">
      <p class="font-bold text-lg text-white mx-2 my-2"> نمودار وضعیت </p>
      <div class="w-full grid grid-cols-3 bg-gray-800 my-4   m-1 rounded-xl">
          <button @click="ChartSelected('Balance')" :class="{ 'bg-TFL-innerCard  text-neutral-500': this.selectedChart !== 'Balance','bg-TFL-base1  text-white': this.selectedChart === 'Balance' }" class="col-span-1 py-1 px-2 rounded-r-lg   text-xs"> بالانس</button>
          <button @click="ChartSelected('Equity')" :class="{ 'bg-TFL-innerCard  text-neutral-500': this.selectedChart !== 'Equity','bg-TFL-base1  text-white': this.selectedChart === 'Equity' }" class="col-span-1 py-1 px-2  border-r border-l border-neutral-700  text-xs">اکویتی  </button>
          <button @click="ChartSelected('Position')" :class="{ 'bg-TFL-innerCard  text-neutral-500': this.selectedChart !== 'Position','bg-TFL-base1  text-white': this.selectedChart === 'Position' }" class="col-span-1 py-1 px-2 rounded-l-lg    text-xs">معاملات  </button>
        </div>
      <BalanceApex v-if="tradeHistory.length > 0 && selectedChart == 'Balance'"  :tradeHistory="tradeHistory" style="height: 100%" />
        <PositionChart  v-if=" selectedChart == 'Position' " :tradeHistory="tradeHistory" class="w-full h-full" />
        <EquityApex v-if=" selectedChart == 'Equity'" :accountuId="accountuId" style="height: 100%" />
    </div>
    <div class="col-span-12  bg-TFL-cardDashboard  rounded-lg mx-2  p-2">
      <TradeHistory  :tradeHistory="tradeHistory"/>
    </div>
  </div>
</template>

<script>
import axios, { all } from 'axios';
import URL from "../URL";
import TradeHistory from './TradeHistory';
import BalanceApex from '../components/BalanceApex.vue';
import PositionChart from './PositionChart.vue';
import EquityApex from '../components/EquityApex.vue';
export default {
  name: 'DTournamentsAnalysis',
  components: { TradeHistory, BalanceApex,PositionChart,EquityApex },
  data () {
    return {
      
      tournamentId: this.$route.params.id,
      accountData: [],
      accountuId: '',
      tradeHistory: [],
      tradeStat: [],
      showPosition: false,
      allLoaded2: false,
      allLoaded1: false,
      ranking: [],
      leaderBoardLoaded: false,

      selectedChart: 'Balance',

    }
  },
  created(){
    this.getAccountData();
    this.leaderBoard();
  },
  methods: {
    ChartSelected(value){
      
      this.selectedChart = value;
    },
    fetchStat() {
      
      const url = URL.baseUrl + `Meta/account/advance/statistics?AccountId=${this.accountuId}`;
      axios.get(url,{
        headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    })
        .then(response => {
          if(response.data != ''){
            
            
            this.tradeStat = response.data;
            this.allLoaded1 = true;
          }
          
        })
        .catch(error => {
          this.allLoaded1 = true;
        });
    },
    fetchHistoryData() {
      
      const url = URL.baseUrl + `Meta/account/orders?AccountId=${this.accountuId}`;
      axios.get(url,{
        headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    })
        .then(response => {
          // loop through response data and push each object into trades array
          this.tradeHistory = response.data;
          this.$emit('tradesloaded', this.tradeHistory);
          this.showPosition = true;
          this.allLoaded2 = true; 
        })
        .catch(error => {
          this.allLoaded2 = true;
        });
    },
    async leaderBoard() {
            
            try {
                const url = URL.baseUrl + `Meta/account/tournaments/leaderboard/${this.tournamentId}`;
                const response = await axios.get(url, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('token')
                    }
                });
                this.ranking = response.data;
                this.leaderBoardLoaded = true;
                
                
            } catch (error) {
                
                this.leaderBoardLoaded = true;
            }
        },
    async getAccountData() {
            this.showAccountDetail = true;
            try {
                const url = URL.baseUrl + `Meta/account/tournaments/${this.tournamentId}`;
                const response = await axios.get(url, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('token')
                    }
                });
                this.accountData = response.data;
                this.accountuId = this.accountData.uId;
                this.fetchHistoryData();
                this.fetchStat(); 

                
            } catch (error) {
                console.error(error);
            }
        },
  }
}
</script>

<style>

</style>