<template>
    <div class="bg-TFL-back1 min-h-screen">
        <div class="grid grid-cols-12">
            <div class="col-span-12 bg-neutral-800 rounded-lg p-4 m-4">
                <h1 class="text-2xl text-right font-IranYekan font-bold text-neutral-100">ایجاد تورنومنت جدید</h1>
                <div class="grid grid-cols-12">
                    <div class="col-span-12  ">
                       
                            <form @submit.prevent="createTournoment">
                                <div class="grid grid-cols-12 my-4">
                                    <div class="col-span-12">
                                        <label for="name" class="text-neutral-100 font-IranYekan text-sm font-normal mt-2">نام تورنومنت</label>
                                        <input type="text" id="name" v-model="name" class="w-full bg-neutral-700 text-neutral-100 rounded-lg p-2">
                                    </div>
                                    <div class="col-span-12">
                                        <label for="description" class="text-neutral-100 font-IranYekan text-sm font-normal mt-2 ">توضیحات</label>
                                        <textarea id="description" v-model="description" class="w-full bg-neutral-700 text-neutral-100 rounded-lg p-2"></textarea>
                                    </div>
                                    <div class="col-span-12">
                                        <label for="startDateTime" class="text-neutral-100 font-IranYekan text-sm font-normal mt-2">تاریخ شروع</label>
                                        <input type="datetime-local" id="startDateTime" v-model="startDateTime" class="w-full bg-neutral-700 text-neutral-100 rounded-lg p-2">
                                    </div>
                                    <div class="col-span-12">
                                        <label for="endDateTime" class="text-neutral-100 font-IranYekan text-sm font-normal mt-2">تاریخ پایان </label>
                                        <input type="datetime-local" id="endDateTime" v-model="endDateTime" class="w-full bg-neutral-700 text-neutral-100 rounded-lg p-2">
                                    </div>
                                    <div class="col-span-12">
                                        <label for="maxParticipantCount" class="text-neutral-100 font-IranYekan text-sm font-normal mt-2">تعداد شرکت کننده </label>
                                        <input type="text" id="maxParticipantCount" v-model="maxParticipantCount" class="w-full bg-neutral-700 text-neutral-100 rounded-lg p-2">
                                    </div>
                                    <div class="col-span-12">
                                        <label for="prize" class="text-neutral-100 font-IranYekan text-sm font-normal mt-2">جایزه  </label>
                                        <input type="number" id="prize" v-model="prize" class="w-full bg-neutral-700 text-neutral-100 rounded-lg p-2">
                                    </div>
                                    <div class="col-span-12">
                                        <label for="entryFee" class="text-neutral-100 font-IranYekan text-sm font-normal mt-2">هزینه ورود</label>
                                        <input type="number" id="entryFee" v-model="entryFee" class="w-full bg-neutral-700 text-neutral-100 rounded-lg p-2">
                                    </div>
                                    <div class="col-span-12">
                                        <label for="privacyType" class="text-neutral-100 font-IranYekan text-sm font-normal mt-2">نوع دسترسی</label>
                                        <select id="privacyType" v-model="privacyType" class="w-full bg-neutral-700 text-neutral-100 rounded-lg p-2">
                                            <option value="Public">عمومی</option>
                                            <option value="Private">خصوصی</option>
                                        </select>
                                    </div>
                                </div>
                            </form>
                            <button v-if="!loadingCreate" @click="createTournoment" class="bg-TFL-base1 text-neutral-100 rounded-lg p-2">ایجاد تورنومنت</button>
                            <button v-if="loadingCreate"  class="bg-gray-600 text-neutral-100 animate-pulse rounded-lg p-2">ایجاد تورنومنت</button>


                    </div>
                </div>
                        

            </div>
            <div class="col-span-12 bg-neutral-800 rounded-lg p-4 m-4">
                <h1 class="text-2xl text-right font-IranYekan font-bold text-neutral-100">لیست تورنومنت ها</h1>
                <div class="grid grid-cols-12">
                    <div class="col-span-12  ">
                        <div v-for="tournament in tournaments" :key="tournament.id" class="bg-neutral-700 rounded-lg p-4 m-4">
                            <h1 class="text-2xl text-right font-IranYekan font-bold text-neutral-100">{{tournament.name}}</h1>
                            <p class="text-right text-neutral-100 font-IranYekan">{{tournament.description}}</p>
                            <div class="text-right text-neutral-100 text-xs font-IranYekan flex justify-between"> 
                                <p>تاریخ شروع:</p>
                                <p>{{tournament.startDateTime}}</p>

                            </div>
                            <div class="text-right text-neutral-100 text-xs font-IranYekan flex justify-between"> 
                                <p>تاریخ پایان:</p>
                                <p>{{tournament.endDateTime}}</p>

                            </div>
                            <div class="text-right text-neutral-100 text-xs font-IranYekan flex justify-between"> 
                                <p>تعداد شرکت کننده:</p>
                                <p>{{tournament.maxParticipantCount}}</p>

                            </div>
                            <div class="text-right text-neutral-100 text-xs font-IranYekan flex justify-between"> 
                                <p>جایزه: </p>
                                <p>{{tournament.prize}}</p>

                            </div>
                            <div class="text-right text-neutral-100 text-xs font-IranYekan flex justify-between"> 
                                <p>هزینه ورود: </p>
                                <p> {{tournament.entryFee}}</p>

                            </div>
                            <div class="text-right text-neutral-100 text-xs font-IranYekan flex justify-between"> 
                                <p>تعداد ثبت نام شده : </p>
                                <p> {{tournament.currentParticipantCount}}</p>

                            </div>
                            <div class="text-right text-neutral-100 text-xs font-IranYekan flex justify-between"> 
                                <p> وضعیت: </p>
                                <p> {{tournament.state}}</p>

                            </div>


                            <div class="lg:flex lg:justify-between grid grid-cols-1 my-2">
                                <button v-if="!loadingStart" @click="startTournament(tournament.uId)" class="bg-TFL-base1 my-2 text-neutral-100 rounded-lg p-2">شروع تورنومنت</button>
                                <button v-if="loadingStart"  class="bg-gray-600 my-2 text-neutral-100 animate-pulse rounded-lg p-2">شروع تورنومنت</button>
                                <button v-if="!loadingEnd" @click="endTournament(tournament.uId)" class="bg-TFL-base1 my-2 text-neutral-100 rounded-lg p-2">پایان تورنومنت</button>
                                <button v-if="loadingEnd"  class="bg-gray-600 my-2 text-neutral-100 animate-pulse rounded-lg p-2">پایان تورنومنت</button>
                                <button v-if="!loadingRanking" @click="getAccounts(tournament.uId)" class="bg-TFL-base1 my-2 text-neutral-100 rounded-lg p-2">لیست شرکت‌کنندگان </button>
                                <button v-if="loadingRanking"  class="bg-gray-600 my-2 text-neutral-100 animate-pulse rounded-lg p-2">لیست شرکت‌کنندگان </button>
                            </div>
                            
                           
                            
                            
                            
                           
                        </div>
                    </div>
                </div>

            </div>
                
        </div>
        
    </div>
    <div v-if="showRanking" class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <div class="fixed inset-0 bg-gray-800 bg-opacity-70 transition-opacity"></div>

    <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-2 text-center sm:items-center sm:p-0">
            <div class="relative transform overflow-hidden p-4 rounded-lg bg-TFL-card2 text-left shadow-xl transition-all w-full max-w-lg">
                
                
                <table class="w-full  text-[10px]  bg-gray-800 text-right text-gray-500 round-10 table-auto">
            <thead  class="text-xs text-gray-300 uppercase bg-[#202020] round-10 ">
              <tr>
                 <th scope="col" class="px-2 py-3 text-center ">رتبه</th>
                 <th scope="col" class="px-2 py-3 text-center ">شمار حساب </th> 
                 <th scope="col" class="px-2 py-3 text-center ">بالانس</th> 
                 <th scope="col" class="px-2 py-3 text-center ">اکویتی</th> 
                 
                 <th scope="col" class="px-2 py-3 text-center ">یوزر</th> 
                 
                 
              </tr>
            </thead> 
            <tbody  class="bg-[#363636] bg-opacity-30  ">
              <tr v-for="rank in accounts"  class="text-gray-300  hover:bg-[#404040] border-b-[#404040]">
                <td class="px-2 py-2 text-center">{{(rank.rank )}}</td>
                <td class="px-2 py-2 text-center">{{(rank.accountId )}}</td>
                <td class="px-2 py-2 text-center">{{(rank.balance )}}</td>
                <td class="px-2 py-2 text-center">{{(rank.equity )}}</td>
                <td class="px-2 py-2 text-center">
                    <router-link :to="{params: {id:rank.userId }, name:'DAdminUserDetails' }"   class="col-span-1 col-start-4 font-iranSans  hover:scale-105 duration-300 text-center w-full block  bg-gray-800  text-indigo-900 text-white font-semibold rounded-lg px-4 py-1 mt-2">
                              اطلاعات یوزر
                             
        </router-link>
                </td>
              </tr>
            </tbody>
          </table>

                <div class="bg-TFL-card2  py-3 sm:flex sm:flex-row-reverse ">
                    <button @click="toggleRanking()" type="button" class="inline-flex w-full justify-center rounded-md bg-neutral-700 px-3 py-2 text-xs font-semibold text-white shadow-sm hover:bg-TFL-base1 ">بستن</button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from 'axios';
import URL from "../URL";

export default {
    data() {
        return {
            name: '',
            description: '',
            startDateTime: '',
            endDateTime: '',
            maxParticipantCount: '',
            prize: '',
            entryFee: '',
            privacyType: 'Public',
            AccountDeposit: 'K100',


            tournaments: [],
            accounts: [],
            showRanking: false,
            loadingStart: false,
            loadingEnd: false,
            loadingRanking: false,
            loadingCreate: false,
        }
    },
    mounted(){

        this.getTournomanets();
    },
    
    methods: {
        toggleRanking() {
            this.showRanking = !this.showRanking;
        },
        getAccounts(id) {
            this.loadingRanking = true;

            const url = URL.baseUrl + `Admin/tournament/${id}/Accounts`;
            axios.get(url, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    })
            .then(response => {
                this.accounts = response.data;
                this.toggleRanking();
                this.loadingRanking = false;
            })
            .catch(error => {
                console.log(error);
                this.loadingRanking = false;
            })
        },
        getTournomanets() {
            const url = URL.baseUrl + `Admin/Tournament`;
            axios.get(url, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    })
            .then(response => {
                this.tournaments = response.data;
            })
            .catch(error => {
                console.log(error)
            })
        },

        createTournoment() {
            this.loadingCreate = true;
            const url = URL.baseUrl + `Admin/Tournament`;
            axios.post(url, {
                name: this.name,
                description: this.description,
                startDateTime: this.startDateTime,
                endDateTime: this.endDateTime,
                maxParticipantCount: this.maxParticipantCount,
                prize: this.prize,
                entryFee: this.entryFee,
                privacyType: this.privacyType,
                AccountDeposit: 'K100',
            }, {
     headers: {
       'Content-Type': 'application/json',
       'Authorization': 'Bearer ' + localStorage.getItem('token')
     }
   })
            .then(response => {
                console.log(response);
                this.getTournomanets();
                this.loadingCreate = false;
            })
            .catch(error => {
                console.log(error);
                this.loadingCreate = false;
            })
        },
        startTournament(id) {
            this.loadingStart = true;
            const url = URL.baseUrl + `Admin/Tournament/${id}/Start`;
            axios.put(url,'', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }, 
        })
            .then(response => {
                console.log(response);
                this.loadingStart = false;
                this.getTournomanets();
                

            })
            .catch(error => {
                console.log(error);
                this.loadingStart = false;
            })
        },
        endTournament(id) {
            this.loadingEnd = true;
            const url = URL.baseUrl + `Admin/Tournament/${id}/End`;
            axios.put(url,'', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }, 
        })
            .then(response => {
                console.log(response);
                this.loadingEnd = false;
                this.getTournomanets();
            })
            .catch(error => {
                console.log(error);
                this.loadingEnd = false;
            })
        },

    }
}
</script>