<template>
    <div class="bg-TFL-background    grid grid-cols-2  ">
      <div class=" col-span-2 grid grid-cols-2 max-w-screen-xl my-2 mx-auto">
            <p class=" p-2 py-4 col-span-2 text-center font-IRANSansBold text-4xl mx-4 bg-gradient-to-b from-TFL-base1  to-TFL-base2 from-30%  text-transparent bg-clip-text ">  </p>
        </div>
        <div class="col-span-2 grid grid-cols-2   my-4 mx-auto ">
            <div class="relative flex col-span-2  justify-center    ">
                <div class="absolute -inset-1  bg-gradient-to-b from-TFL-base1 via-TFL-background to-TFL-background from-0% via-50% rounded-xl  blur-xl opacity-20 "></div>
                <div class="relative">
                    <img alt="banner image" class="justify-self-center object-scale-down opacity-5 relative rounded-xl " src="../assets/images/TFL-Spread.jpg" loading="lazy" decoding="async">
                    <!-- <div class="absolute  inset-x-0 top-2/3 text-center lg:text-8xl text-4xl drop-shadow-[rgba(0,_0,_0,_0.8)_0px_6px_6px] font-Montserrat   text-transparent bg-clip-text bg-gradient-to-b from-TFL-base1  to-TFL-base2  ">
                      UP TO $100000
                    </div> -->
                    <div class="absolute  inset-x-0 top-0 text-center lg:text-8xl text-4xl drop-shadow-[rgba(0,_0,_0,_1)_0px_8px_8px] font-Montserrat   text-gray-300  ">
                      <div class="grid grid-cols-3 justify-between">
                        <p class=" my-auto p-2 py-4 col-span-2 text-center font-IRANSansBold lg:text-4xl text-3xl mx-4 bg-gradient-to-b from-TFL-base1  to-TFL-base2 from-30%  text-transparent bg-clip-text ">تنها بر روی زیرساختی مطمئن ترید کنید</p>
                        <img alt="  banner image" class="justify-self-center col-span-1 object-scale-down  relative rounded-xl h-[400px]   " src="../assets/images/TFL-MT4.png" loading="lazy" decoding="async">

                      </div>
                      <div class="col-span-2 grid grid-cols-3  justify-between   my-4 mx-auto ">
          
            
          <div v-if="RoboforexBroker" class=" col-span-3 sm:col-span-1  mt-4 mx-2 p-6 bg-gray-700 border border-gray-700 rounded-xl group hover:bg-gradient-to-br hover:from-TFL-base1 hover:to-purple-800  bg-opacity-50  sm:p-6">
            <p class="font-Montserrat text-3xl group-hover:text-white mx-2 text-center  bg-gradient-to-r from-TFL-base2  to-TFL-base1  text-transparent bg-clip-text">RoboForex</p>
            <p class=" mt-4 px-2 text-gray-300 text-xs font-IRANSansBold">شماره حساب</p> 
            <div class="bg-gray-800 p-2 rounded-xl my-2">
              <p class="font-Montserrat text-base group-hover:text-white mx-2 text-center  bg-gradient-to-r from-gray-500  to-gray-300  text-transparent bg-clip-text">{{ RoboforexBroker.accountId }}</p>
            </div>
            <p class=" mt-4 px-2 text-gray-300 text-xs font-IRANSansBold">رمزعبور </p> 
            <div class="bg-gray-800 p-2 rounded-xl my-2">
              <p class="font-Montserrat text-base group-hover:text-white mx-2 text-center  bg-gradient-to-r from-gray-500  to-gray-300  text-transparent bg-clip-text">{{ RoboforexBroker.password }}</p>
            </div>
            <p class=" mt-4 px-2 text-gray-300 text-xs font-IRANSansBold">سرور </p> 
            <div class="bg-gray-800 py-2 px-1 rounded-xl my-2">
              <p class="font-Montserrat text-sm group-hover:text-white mx-2 text-center  bg-gradient-to-r from-gray-500  to-gray-300  text-transparent bg-clip-text">{{ RoboforexBroker.name }}</p>
            </div>
            <div class="bg-gradient-to-br from-TFL-base1 group-hover:border-white border-2 border-gray-700 to-purple-800 py-2 px-1 text-sm rounded-xl my-2 mt-6">
              <a href="https://s30.picofile.com/file/8468464068/roboforex4setup.exe.html" target="_blank" class="font-Montserrat my-1 text-sm group-hover:text-white mx-2 text-center  ">Download MT4</a>
              
            </div>
          </div>
          <div v-if="ICMarketsBroker" class=" col-span-3 sm:col-span-1  mt-4 mx-2 p-6 bg-gray-700 border border-gray-700 rounded-xl group hover:bg-gradient-to-br hover:from-TFL-base1 hover:to-purple-800  bg-opacity-50  sm:p-6">
            <p class="font-Montserrat text-3xl group-hover:text-white mx-2 text-center  bg-gradient-to-r from-TFL-base2  to-TFL-base1  text-transparent bg-clip-text">ICMarkets</p>
            <p class=" mt-4 px-2 text-gray-300 text-xs font-IRANSansBold">شماره حساب</p> 
            <div class="bg-gray-800 p-2 rounded-xl my-2">
              <p class="font-Montserrat text-base group-hover:text-white mx-2 text-center  bg-gradient-to-r from-gray-500  to-gray-300  text-transparent bg-clip-text">{{ ICMarketsBroker.accountId }}</p>
            </div>
            <p class=" mt-4 px-2 text-gray-300 text-xs font-IRANSansBold">رمزعبور </p> 
            <div class="bg-gray-800 p-2 rounded-xl my-2">
              <p class="font-Montserrat text-base group-hover:text-white mx-2 text-center  bg-gradient-to-r from-gray-500  to-gray-300  text-transparent bg-clip-text">{{ ICMarketsBroker.password }}</p>
            </div>
            <p class=" mt-4 px-2 text-gray-300 text-xs font-IRANSansBold">سرور </p> 
            <div class="bg-gray-800 py-2 px-1 rounded-xl my-2">
              <p class="font-Montserrat text-sm group-hover:text-white mx-2 text-center  bg-gradient-to-r from-gray-500  to-gray-300  text-transparent bg-clip-text">{{ ICMarketsBroker.name }}</p>
            </div>
            <div class="bg-gradient-to-br from-TFL-base1 group-hover:border-white border-2 border-gray-700 to-purple-800 py-2 px-1 text-sm rounded-xl my-2 mt-6">
              <a href="https://s31.picofile.com/file/8468464034/icmarketseu4setup.exe.html" target="_blank" class="font-Montserrat my-1 text-sm group-hover:text-white mx-2 text-center  ">Download MT4</a>
              
            </div>
          </div>
          <div v-if="FXTMBroker" class=" col-span-3 sm:col-span-1  mt-4 mx-2 p-6 bg-gray-700 border border-gray-700 rounded-xl group hover:bg-gradient-to-br hover:from-TFL-base1 hover:to-purple-800  bg-opacity-50  sm:p-6">
            <p class="font-Montserrat text-3xl group-hover:text-white mx-2 text-center  bg-gradient-to-r from-TFL-base2  to-TFL-base1  text-transparent bg-clip-text">FXTM</p>
            <p class=" mt-4 px-2 text-gray-300 text-xs font-IRANSansBold">شماره حساب</p> 
            <div class="bg-gray-800 p-2 rounded-xl my-2">
              <p class="font-Montserrat text-base group-hover:text-white mx-2 text-center  bg-gradient-to-r from-gray-500  to-gray-300  text-transparent bg-clip-text">{{ FXTMBroker.accountId }}</p>
            </div>
            <p class=" mt-4 px-2 text-gray-300 text-xs font-IRANSansBold">رمزعبور </p> 
            <div class="bg-gray-800 p-2 rounded-xl my-2">
              <p class="font-Montserrat text-base group-hover:text-white mx-2 text-center  bg-gradient-to-r from-gray-500  to-gray-300  text-transparent bg-clip-text">{{ FXTMBroker.password }}</p>
            </div>
            <p class=" mt-4 px-2 text-gray-300 text-xs font-IRANSansBold">سرور </p> 
            <div class="bg-gray-800 py-2 px-1 rounded-xl my-2">
              <p class="font-Montserrat text-sm group-hover:text-white mx-2 text-center  bg-gradient-to-r from-gray-500  to-gray-300  text-transparent bg-clip-text">{{ FXTMBroker.name }}</p>
            </div>
            <div class="bg-gradient-to-br from-TFL-base1 group-hover:border-white border-2 border-gray-700 to-purple-800 py-2 px-1 text-sm rounded-xl my-2 mt-6">
              <a href="https://s30.picofile.com/file/8468464084/forextimefxtm4setup.exe.html" target="_blank" class="font-Montserrat my-1 text-sm group-hover:text-white mx-2 text-center  ">Download MT4</a>
              
            </div>
          </div>
          <!-- <div v-if="TickmillBroker" class=" col-span-3 sm:col-span-1  mt-4 mx-2 p-6 bg-gray-700 border border-gray-700 rounded-xl group hover:bg-gradient-to-br hover:from-TFL-base1 hover:to-purple-800  bg-opacity-50  sm:p-6">
            <p class="font-Montserrat text-3xl group-hover:text-white mx-2 text-center  bg-gradient-to-r from-TFL-base2  to-TFL-base1  text-transparent bg-clip-text">Tickmill</p>
            <p class=" mt-4 px-2 text-gray-300 text-xs font-IRANSansBold">شماره حساب</p> 
            <div class="bg-gray-800 p-2 rounded-xl my-2">
              <p class="font-Montserrat text-base group-hover:text-white mx-2 text-center  bg-gradient-to-r from-gray-500  to-gray-300  text-transparent bg-clip-text">{{ TickmillBroker.accountId }}</p>
            </div>
            <p class=" mt-4 px-2 text-gray-300 text-xs font-IRANSansBold">رمزعبور </p> 
            <div class="bg-gray-800 p-2 rounded-xl my-2">
              <p class="font-Montserrat text-base group-hover:text-white mx-2 text-center  bg-gradient-to-r from-gray-500  to-gray-300  text-transparent bg-clip-text">{{ TickmillBroker.password }}</p>
            </div>
            <p class=" mt-4 px-2 text-gray-300 text-xs font-IRANSansBold">سرور </p> 
            <div class="bg-gray-800 py-2 px-1 rounded-xl my-2">
              <p class="font-Montserrat text-sm group-hover:text-white mx-2 text-center  bg-gradient-to-r from-gray-500  to-gray-300  text-transparent bg-clip-text">{{ TickmillBroker.name }}</p>
            </div>
            <div class="bg-gradient-to-br from-TFL-base1 group-hover:border-white border-2 border-gray-700 to-purple-800 py-2 px-1 text-sm rounded-xl my-2 mt-6">
              <a href="https://s31.picofile.com/file/8468464018/tickmilleu4setup.exe.html" target="_blank" class="font-Montserrat my-1 text-sm group-hover:text-white mx-2 text-center  ">Download MT4</a>
              
            </div>
          </div> -->
          <div v-if="AlpariBroker" class=" col-span-3 sm:col-span-1  mt-4 mx-2 p-6 bg-gray-700 border border-gray-700 rounded-xl group hover:bg-gradient-to-br hover:from-TFL-base1 hover:to-purple-800  bg-opacity-50  sm:p-6">
            <p class="font-Montserrat text-3xl group-hover:text-white mx-2 text-center  bg-gradient-to-r from-TFL-base2  to-TFL-base1  text-transparent bg-clip-text">Alpari</p>
            <p class=" mt-4 px-2 text-gray-300 text-xs font-IRANSansBold">شماره حساب</p> 
            <div class="bg-gray-800 p-2 rounded-xl my-2">
              <p class="font-Montserrat text-base group-hover:text-white mx-2 text-center  bg-gradient-to-r from-gray-500  to-gray-300  text-transparent bg-clip-text">{{ AlpariBroker.accountId }}</p>
            </div>
            <p class=" mt-4 px-2 text-gray-300 text-xs font-IRANSansBold">رمزعبور </p> 
            <div class="bg-gray-800 p-2 rounded-xl my-2">
              <p class="font-Montserrat text-base group-hover:text-white mx-2 text-center  bg-gradient-to-r from-gray-500  to-gray-300  text-transparent bg-clip-text">{{ AlpariBroker.password }}</p>
            </div>
            <p class=" mt-4 px-2 text-gray-300 text-xs font-IRANSansBold">سرور </p> 
            <div class="bg-gray-800 py-2 px-1 rounded-xl my-2">
              <p class="font-Montserrat text-sm group-hover:text-white mx-2 text-center  bg-gradient-to-r from-gray-500  to-gray-300  text-transparent bg-clip-text">{{ AlpariBroker.name }}</p>
            </div>
            <div class="bg-gradient-to-br from-TFL-base1 group-hover:border-white border-2 border-gray-700 to-purple-800 py-2 px-1 text-sm rounded-xl my-2 mt-6">
              <a href="https://s30.picofile.com/file/8475440084/alpari4setup.exe.html" target="_blank" class="font-Montserrat my-1 text-sm group-hover:text-white mx-2 text-center  ">Download MT4</a>
              
            </div>
          </div>
          <!-- <div v-if="ExnessBroker" class=" col-span-3 sm:col-span-1 mt-4 mx-2 p-6 bg-gray-700 border border-gray-700 rounded-xl group hover:bg-gradient-to-br hover:from-TFL-base1 hover:to-purple-800  bg-opacity-50  sm:p-6">
            <p class="font-Montserrat text-3xl group-hover:text-white mx-2 text-center  bg-gradient-to-r from-TFL-base2  to-TFL-base1  text-transparent bg-clip-text">Exness</p>
            <p class=" mt-4 px-2 text-gray-300 text-xs font-IRANSansBold">شماره حساب</p> 
            <div class="bg-gray-800 p-2 rounded-xl my-2">
              <p class="font-Montserrat text-base group-hover:text-white mx-2 text-center  bg-gradient-to-r from-gray-500  to-gray-300  text-transparent bg-clip-text">{{ ExnessBroker.accountId }}</p>
            </div>
            <p class=" mt-4 px-2 text-gray-300 text-xs font-IRANSansBold">رمزعبور </p> 
            <div class="bg-gray-800 p-2 rounded-xl my-2">
              <p class="font-Montserrat text-base group-hover:text-white mx-2 text-center  bg-gradient-to-r from-gray-500  to-gray-300  text-transparent bg-clip-text">{{ ExnessBroker.password }}</p>
            </div>
            <p class=" mt-4 px-2 text-gray-300 text-xs font-IRANSansBold">سرور </p> 
            <div class="bg-gray-800 py-2 px-1 rounded-xl my-2">
              <p class="font-Montserrat text-sm group-hover:text-white mx-2 text-center  bg-gradient-to-r from-gray-500  to-gray-300  text-transparent bg-clip-text">{{ ExnessBroker.name }}</p>
            </div>
            <div class="bg-gradient-to-br from-TFL-base1 group-hover:border-white border-2 border-gray-700 to-purple-800 py-2 px-1 text-sm rounded-xl my-2 mt-6">
              <a href="https://s30.picofile.com/file/8475448418/exness4setup.exe.html" target="_blank" class="font-Montserrat my-1 text-sm group-hover:text-white mx-2 text-center  ">Download MT4</a>
              
            </div>
          </div> -->
        </div>
        <div class=" max-w-screen-xl col-span-2  mx-auto my-20 ">
          <p class=" my-auto p-2 py-4 col-span-2 text-center font-IRANSansBold lg:text-4xl text-3xl mx-4 bg-gradient-to-b from-TFL-base1  to-TFL-base2 from-30%  text-transparent bg-clip-text ">مقایسه اسپرد و کمیسیون در بروکرهای ارائه شده</p>

          <ul class="mb-6 space-y-2 text-gray-300 px-10 pt-4 sm:text-base text-sm my-10">
                <li class="flex items-center gap-1.5 hover:scale-105 transition duration-500 ">
                    <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 w-5 h-5 text-TFL-base1" viewBox="0 0 20 20"
                        fill="currentColor">
                        <path fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"></path>
                    </svg>
                    <span class="text-gray-300 text-sm font-IRANSansBold text-right"> در جدول زیر  کمیسیون به ازای هر لات / اسپرد ذکر شده‌است.</span>
                </li>
                <li class="flex items-center gap-1.5 hover:scale-105 transition duration-500 ">
                    <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 w-5 h-5 text-TFL-base1" viewBox="0 0 20 20"
                        fill="currentColor">
                        <path fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"></path>
                    </svg>
                    <span class="text-gray-300 text-sm font-IRANSansBold text-right">اسپردهای درج شده مرتبط با ساعات با نقدینگی بالا در بازار بوده و به صورت بدیهی ممکن است در ساعات دیگر متفاوت باشد.</span>
                </li>
                <li class="flex items-center gap-1.5 hover:scale-105 transition duration-500 ">
                    <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 w-5 h-5 text-TFL-base1" viewBox="0 0 20 20"
                        fill="currentColor">
                        <path fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"></path>
                    </svg>
                    <span class="text-gray-300 text-sm font-IRANSansBold text-right">توصیه می‌شود به منظور بررسی دقیق‌تر از حساب‌های تست استفاده نمایید.</span>
                </li>
                </ul>
            <div class="relative overflow-x-auto shadow-md rounded-2xl sm:mx-10 mx-4">
    <table class="w-full text-sm text-left rtl:text-right text-gray-500   ">
        <thead class="text-xs text-gray-700 uppercase  ">
            
            <tr class=" text-center bg-gray-700 bg-opacity-50 ">
                <th scope="row" class="border-l border-TFL-base1 bg-gradient-to-r  from-TFL-base1 to-purple-500 text-gray-300" >
                    <h3 class="text-xs  font-IRANSansBold    sm:text-lg">نماد</h3>
                </th>
                <td class="px-2 py-4 ">
                  <p class="font-Montserrat text-xl group-hover:text-white mx-4 text-center  bg-gradient-to-r from-TFL-base2  to-TFL-base1  text-transparent bg-clip-text">RoboForex</p>
                </td>
                <td class="px-2 py-4  bg-gray-700">
                  <p class="font-Montserrat text-xl group-hover:text-white mx-4 text-center  bg-gradient-to-r from-TFL-base2  to-TFL-base1  text-transparent bg-clip-text">ICMarkets</p>
                </td>
                <td class="px-2 py-4  ">
                  <p class="font-Montserrat text-xl group-hover:text-white mx-4 text-center  bg-gradient-to-r from-TFL-base2  to-TFL-base1  text-transparent bg-clip-text">FXTM</p>
                </td>
                <!-- <td class="px-2 py-4  bg-gray-700">
                  <p class="font-Montserrat text-xl group-hover:text-white mx-4 text-center  bg-gradient-to-r from-TFL-base2  to-TFL-base1  text-transparent bg-clip-text">TickMill</p>
                </td> -->
                <td class="px-2 py-4  ">
                  <p class="font-Montserrat text-xl group-hover:text-white mx-4 text-center  bg-gradient-to-r from-TFL-base2  to-TFL-base1  text-transparent bg-clip-text">Alpari</p>
                </td>
                <!-- <td class="px-2 py-4  bg-gray-700">
                  <p class="font-Montserrat text-xl group-hover:text-white mx-4 text-center  bg-gradient-to-r from-TFL-base2  to-TFL-base1  text-transparent bg-clip-text">Exness</p>
                </td> -->
            </tr>
        </thead>
        <tbody>
            
            <tr class=" text-center bg-gray-700 bg-opacity-50  ">
                <th scope="row" class="px-2 py-4 border-l border-TFL-base1 bg-gradient-to-r  from-TFL-base1 to-purple-500 text-gray-300 ">
                    <h3 class="text-xs  font-Montserrat    sm:text-sm">EURUSD</h3>
                </th>
                <td class="px-2 py-4">
                    <h2 class="text-xs font-Montserrat text-white   sm:text-sm">2 / 0</h2>
                </td>
                <td class="px-2 py-4  bg-gray-700 ">
                    <h2 class="text-xs font-Montserrat text-white   sm:text-sm"> 7 / 0</h2>
                </td>
                <td class="px-2 py-4  ">
                    <h2 class="text-xs font-Montserrat text-white   sm:text-sm ">4 / 0</h2>
                </td>
                <td class="px-2 py-4  bg-gray-700">
                    <h2 class="text-xs font-Montserrat text-white   sm:text-sm ">4 / 0</h2>
                </td>
                <td class="px-2 py-4  ">
                    <h2 class="text-xs font-Montserrat text-white   sm:text-sm ">5 / 9</h2>
                </td>
                <!-- <td class="px-2 py-4  bg-gray-700">
                    <h2 class="text-xs font-Montserrat text-white   sm:text-sm ">0 / 0</h2>
                </td> -->
            </tr>
            <tr class=" text-center bg-gray-700 bg-opacity-50  ">
                <th scope="row" class="px-2 py-4 border-l border-TFL-base1 bg-gradient-to-r  from-TFL-base1 to-purple-500 text-gray-300 ">
                    <h3 class="text-xs  font-Montserrat    sm:text-sm">GBPUSD</h3>
                </th>
                <td class="px-2 py-4">
                    <h2 class="text-xs font-Montserrat text-white   sm:text-sm">2 / 0</h2>
                </td>
                <td class="px-2 py-4  bg-gray-700 ">
                    <h2 class="text-xs font-Montserrat text-white   sm:text-sm"> 7 / 0</h2>
                </td>
                <td class="px-2 py-4  ">
                    <h2 class="text-xs font-Montserrat text-white   sm:text-sm ">4 / 0</h2>
                </td>
                <td class="px-2 py-4  bg-gray-700">
                    <h2 class="text-xs font-Montserrat text-white   sm:text-sm ">4 / 1</h2>
                </td>
                <td class="px-2 py-4  ">
                    <h2 class="text-xs font-Montserrat text-white   sm:text-sm ">6 / 9</h2>
                </td>
                <!-- <td class="px-2 py-4  bg-gray-700">
                    <h2 class="text-xs font-Montserrat text-white   sm:text-sm ">0 / 1</h2>
                </td> -->
            </tr>
            <tr class=" text-center bg-gray-700 bg-opacity-50  ">
                <th scope="row" class="px-2 py-4 border-l border-TFL-base1 bg-gradient-to-r  from-TFL-base1 to-purple-500 text-gray-300 ">
                    <h3 class="text-xs  font-Montserrat    sm:text-sm">USDJPY</h3>
                </th>
                <td class="px-2 py-4">
                    <h2 class="text-xs font-Montserrat text-white   sm:text-sm">2 / 0</h2>
                </td>
                <td class="px-2 py-4  bg-gray-700 ">
                    <h2 class="text-xs font-Montserrat text-white   sm:text-sm"> 7 / 0</h2>
                </td>
                <td class="px-2 py-4  ">
                    <h2 class="text-xs font-Montserrat text-white   sm:text-sm ">4 / 1</h2>
                </td>
                <td class="px-2 py-4  bg-gray-700">
                    <h2 class="text-xs font-Montserrat text-white   sm:text-sm ">4 / 3</h2>
                </td>
                <td class="px-2 py-4  ">
                    <h2 class="text-xs font-Montserrat text-white   sm:text-sm ">5 / 9</h2>
                </td>
                <!-- <td class="px-2 py-4  bg-gray-700">
                    <h2 class="text-xs font-Montserrat text-white   sm:text-sm ">0 / 0</h2>
                </td> -->
            </tr>
            <tr class=" text-center bg-gray-700 bg-opacity-50  ">
                <th scope="row" class="px-2 py-4 border-l border-TFL-base1 bg-gradient-to-r  from-TFL-base1 to-purple-500 text-gray-300 ">
                    <h3 class="text-xs  font-Montserrat    sm:text-sm">USDCHF</h3>
                </th>
                <td class="px-2 py-4">
                    <h2 class="text-xs font-Montserrat text-white   sm:text-sm">2 / 5</h2>
                </td>
                <td class="px-2 py-4  bg-gray-700 ">
                    <h2 class="text-xs font-Montserrat text-white   sm:text-sm"> 7 / 2</h2>
                </td>
                <td class="px-2 py-4  ">
                    <h2 class="text-xs font-Montserrat text-white   sm:text-sm ">4 / 1</h2>
                </td>
                <td class="px-2 py-4  bg-gray-700">
                    <h2 class="text-xs font-Montserrat text-white   sm:text-sm ">4 / 1</h2>
                </td>
                <td class="px-2 py-4  ">
                    <h2 class="text-xs font-Montserrat text-white   sm:text-sm ">5 / 9</h2>
                </td>
                <!-- <td class="px-2 py-4  bg-gray-700">
                    <h2 class="text-xs font-Montserrat text-white   sm:text-sm ">0 / 4</h2>
                </td> -->
            </tr>
            <tr class=" text-center bg-gray-700 bg-opacity-50  ">
                <th scope="row" class="px-2 py-4 border-l border-TFL-base1 bg-gradient-to-r  from-TFL-base1 to-purple-500 text-gray-300 ">
                    <h3 class="text-xs  font-Montserrat    sm:text-sm">USDCAD</h3>
                </th>
                <td class="px-2 py-4">
                    <h2 class="text-xs font-Montserrat text-white   sm:text-sm">2 / 5</h2>
                </td>
                <td class="px-2 py-4  bg-gray-700 ">
                    <h2 class="text-xs font-Montserrat text-white   sm:text-sm"> 7 / 0</h2>
                </td>
                <td class="px-2 py-4  ">
                    <h2 class="text-xs font-Montserrat text-white   sm:text-sm ">4 / 4</h2>
                </td>
                <td class="px-2 py-4  bg-gray-700">
                    <h2 class="text-xs font-Montserrat text-white   sm:text-sm ">4 / 4</h2>
                </td>
                <td class="px-2 py-4  ">
                    <h2 class="text-xs font-Montserrat text-white   sm:text-sm ">5 / 9</h2>
                </td>
                <!-- <td class="px-2 py-4  bg-gray-700">
                    <h2 class="text-xs font-Montserrat text-white   sm:text-sm ">0 / 4</h2>
                </td> -->
            </tr>
            <tr class=" text-center bg-gray-700 bg-opacity-50  ">
                <th scope="row" class="px-2 py-4 border-l border-TFL-base1 bg-gradient-to-r  from-TFL-base1 to-purple-500 text-gray-300 ">
                    <h3 class="text-xs  font-Montserrat    sm:text-sm">NZDUSD</h3>
                </th>
                <td class="px-2 py-4">
                    <h2 class="text-xs font-Montserrat text-white   sm:text-sm">2 / 0</h2>
                </td>
                <td class="px-2 py-4  bg-gray-700 ">
                    <h2 class="text-xs font-Montserrat text-white   sm:text-sm"> 7 / 4</h2>
                </td>
                <td class="px-2 py-4  ">
                    <h2 class="text-xs font-Montserrat text-white   sm:text-sm ">4 / 5</h2>
                </td>
                <td class="px-2 py-4  bg-gray-700">
                    <h2 class="text-xs font-Montserrat text-white   sm:text-sm ">4 / 1</h2>
                </td>
                <td class="px-2 py-4  ">
                    <h2 class="text-xs font-Montserrat text-white   sm:text-sm ">3 / 8</h2>
                </td>
                <!-- <td class="px-2 py-4  bg-gray-700">
                    <h2 class="text-xs font-Montserrat text-white   sm:text-sm ">0 / 5</h2>
                </td> -->
            </tr>
            <tr class=" text-center bg-gray-700 bg-opacity-50  ">
                <th scope="row" class="px-2 py-4 border-l border-TFL-base1 bg-gradient-to-r  from-TFL-base1 to-purple-500 text-gray-300 ">
                    <h3 class="text-xs  font-Montserrat    sm:text-sm">AUDUSD</h3>
                </th>
                <td class="px-2 py-4">
                    <h2 class="text-xs font-Montserrat text-white   sm:text-sm">2 / 3</h2>
                </td>
                <td class="px-2 py-4  bg-gray-700 ">
                    <h2 class="text-xs font-Montserrat text-white   sm:text-sm"> 7 / 0</h2>
                </td>
                <td class="px-2 py-4  ">
                    <h2 class="text-xs font-Montserrat text-white   sm:text-sm ">4 / 4</h2>
                </td>
                <td class="px-2 py-4  bg-gray-700">
                    <h2 class="text-xs font-Montserrat text-white   sm:text-sm ">4 / 1</h2>
                </td>
                <td class="px-2 py-4  ">
                    <h2 class="text-xs font-Montserrat text-white   sm:text-sm ">3 / 7</h2>
                </td>
                <!-- <td class="px-2 py-4  bg-gray-700">
                    <h2 class="text-xs font-Montserrat text-white   sm:text-sm ">0 / 2</h2>
                </td> -->
            </tr>
            <tr class=" text-center bg-gray-700 bg-opacity-50  ">
                <th scope="row" class="px-2 py-4 border-l border-TFL-base1 bg-gradient-to-r  from-TFL-base1 to-purple-500 text-gray-300 ">
                    <h3 class="text-xs  font-Montserrat    sm:text-sm">XAUUSD</h3>
                </th>
                <td class="px-2 py-4">
                    <h2 class="text-xs font-Montserrat text-white   sm:text-sm">4 / 10 </h2>
                </td>
                <td class="px-2 py-4  bg-gray-700 ">
                    <h2 class="text-xs font-Montserrat text-white   sm:text-sm"> 7 / 10</h2>
                </td>
                <td class="px-2 py-4  ">
                    <h2 class="text-xs font-Montserrat text-white   sm:text-sm ">7 / 9</h2>
                </td>
                <td class="px-2 py-4  bg-gray-700">
                    <h2 class="text-xs font-Montserrat text-white   sm:text-sm ">7 / 14</h2>
                </td>
                <td class="px-2 py-4  ">
                    <h2 class="text-xs font-Montserrat text-white   sm:text-sm ">11 / 23</h2>
                </td>
                <!-- <td class="px-2 py-4  bg-gray-700">
                    <h2 class="text-xs font-Montserrat text-white   sm:text-sm ">0 / 63</h2>
                </td> -->
            </tr>
            <tr class=" text-center bg-gray-700 bg-opacity-50  ">
                <th scope="row" class="px-2 py-4 border-l border-TFL-base1 bg-gradient-to-r  from-TFL-base1 to-purple-500 text-gray-300 ">
                    <h3 class="text-xs  font-Montserrat    sm:text-sm">US500</h3>
                </th>
                <td class="px-2 py-4">
                    <h2 class="text-xs font-Montserrat text-white   sm:text-sm">0.04 / 20 </h2>
                </td>
                <td class="px-2 py-4  bg-gray-700 ">
                    <h2 class="text-xs font-Montserrat text-white   sm:text-sm"> 0 / 20</h2>
                </td>
                <td class="px-2 py-4  ">
                    <h2 class="text-xs font-Montserrat text-white   sm:text-sm ">14 / 50</h2>
                </td>
                <td class="px-2 py-4  bg-gray-700">
                    <h2 class="text-xs font-Montserrat text-white   sm:text-sm ">0 / 35</h2>
                </td>
                <td class="px-2 py-4  ">
                    <h2 class="text-xs font-Montserrat text-white   sm:text-sm ">0.3 / 10 </h2>
                </td>
                <!-- <td class="px-2 py-4  bg-gray-700">
                    <h2 class="text-xs font-Montserrat text-white   sm:text-sm ">0 / 45</h2>
                </td> -->
            </tr>
            <tr class=" text-center bg-gray-700 bg-opacity-50  ">
                <th scope="row" class="px-2 py-4 border-l border-TFL-base1 bg-gradient-to-r  from-TFL-base1 to-purple-500 text-gray-300 ">
                    <h3 class="text-xs  font-Montserrat    sm:text-sm">US100</h3>
                </th>
                <td class="px-2 py-4">
                    <h2 class="text-xs font-Montserrat text-white   sm:text-sm">0.12 / 80 </h2>
                </td>
                <td class="px-2 py-4  bg-gray-700 ">
                    <h2 class="text-xs font-Montserrat text-white   sm:text-sm"> 0 / 100</h2>
                </td>
                <td class="px-2 py-4  ">
                    <h2 class="text-xs font-Montserrat text-white   sm:text-sm ">5 / 90</h2>
                </td>
                <td class="px-2 py-4  bg-gray-700">
                    <h2 class="text-xs font-Montserrat text-white   sm:text-sm ">0 / 90</h2>
                </td>
                <td class="px-2 py-4  ">
                    <h2 class="text-xs font-Montserrat text-white   sm:text-sm ">0.9 / 110</h2>
                </td>
                <!-- <td class="px-2 py-4  bg-gray-700">
                    <h2 class="text-xs font-Montserrat text-white   sm:text-sm ">0 / 170</h2>
                </td> -->
            </tr>
            <tr class=" text-center bg-gray-700 bg-opacity-50  ">
                <th scope="row" class="px-2 py-4 border-l border-TFL-base1 bg-gradient-to-r  from-TFL-base1 to-purple-500 text-gray-300 ">
                    <h3 class="text-xs  font-Montserrat    sm:text-sm">US30</h3>
                </th>
                <td class="px-2 py-4">
                    <h2 class="text-xs font-Montserrat text-white   sm:text-sm">0.27 / 160 </h2>
                </td>
                <td class="px-2 py-4  bg-gray-700 ">
                    <h2 class="text-xs font-Montserrat text-white   sm:text-sm"> 0 / 100</h2>
                </td>
                <td class="px-2 py-4  ">
                    <h2 class="text-xs font-Montserrat text-white   sm:text-sm ">1 / 30</h2>
                </td>
                <td class="px-2 py-4  bg-gray-700">
                    <h2 class="text-xs font-Montserrat text-white   sm:text-sm ">0 / 140</h2>
                </td>
                <td class="px-2 py-4  ">
                    <h2 class="text-xs font-Montserrat text-white   sm:text-sm ">2 / 140</h2>
                </td>
                <!-- <td class="px-2 py-4  bg-gray-700">
                    <h2 class="text-xs font-Montserrat text-white   sm:text-sm ">0 / 26</h2>
                </td> -->
            </tr>
            
                
            
            
           
           
            
        </tbody>
    </table>
        </div>
        </div>
                    </div>
                </div>
            </div>
            
        </div>
        
        
        
        <!-- <div class="col-span-2 grid grid-cols-4  justify-between   my-4 mx-auto ">
          <div class=" col-span-1 mx-2 p-6 bg-gray-700 border border-gray-700 rounded-xl group hover:bg-gradient-to-br hover:from-TFL-base1 hover:to-purple-800  bg-opacity-50  sm:p-6">
            <p class="font-Montserrat text-3xl group-hover:text-white mx-4 text-center  bg-gradient-to-r from-TFL-base2  to-TFL-base1  text-transparent bg-clip-text">RoboForex</p>
            <p class=" mt-4 px-2 text-gray-300 text-xs font-IRANSansBold">شماره حساب</p> 
            <div class="bg-gray-800 p-2 rounded-xl my-2">
              <p class="font-Montserrat text-lg group-hover:text-white mx-4 text-center  bg-gradient-to-r from-gray-500  to-gray-300  text-transparent bg-clip-text">50144342</p>
            </div>
            <p class=" mt-4 px-2 text-gray-300 text-xs font-IRANSansBold">رمزعبور </p> 
            <div class="bg-gray-800 p-2 rounded-xl my-2">
              <p class="font-Montserrat text-lg group-hover:text-white mx-4 text-center  bg-gradient-to-r from-gray-500  to-gray-300  text-transparent bg-clip-text">5hy68dd</p>
            </div>
            <p class=" mt-4 px-2 text-gray-300 text-xs font-IRANSansBold">سرور </p> 
            <div class="bg-gray-800 p-2 rounded-xl my-2">
              <p class="font-Montserrat text-lg group-hover:text-white mx-4 text-center  bg-gradient-to-r from-gray-500  to-gray-300  text-transparent bg-clip-text">RoboForex-Demo</p>
            </div>
          </div>
          <div class=" col-span-1 mx-2 p-6 bg-gray-700 border border-gray-700 rounded-xl group hover:bg-gradient-to-br hover:from-TFL-base1 hover:to-purple-800  bg-opacity-50  sm:p-6">
            <p class="font-Montserrat text-3xl group-hover:text-white mx-4 text-center  bg-gradient-to-r from-TFL-base2  to-TFL-base1  text-transparent bg-clip-text">ICMarkets</p>
            <p class=" mt-4 px-2 text-gray-300 text-xs font-IRANSansBold">شماره حساب</p> 
            <div class="bg-gray-800 p-2 rounded-xl my-2">
              <p class="font-Montserrat text-lg group-hover:text-white mx-4 text-center  bg-gradient-to-r from-gray-500  to-gray-300  text-transparent bg-clip-text">50144342</p>
            </div>
            <p class=" mt-4 px-2 text-gray-300 text-xs font-IRANSansBold">رمزعبور </p> 
            <div class="bg-gray-800 p-2 rounded-xl my-2">
              <p class="font-Montserrat text-lg group-hover:text-white mx-4 text-center  bg-gradient-to-r from-gray-500  to-gray-300  text-transparent bg-clip-text">5hy68dd</p>
            </div>
            <p class=" mt-4 px-2 text-gray-300 text-xs font-IRANSansBold">سرور </p> 
            <div class="bg-gray-800 p-2 rounded-xl my-2">
              <p class="font-Montserrat text-lg group-hover:text-white mx-4 text-center  bg-gradient-to-r from-gray-500  to-gray-300  text-transparent bg-clip-text">RoboForex-Demo</p>
            </div>
          </div>
          <div class=" col-span-1 mx-2 p-6 bg-gray-700 border border-gray-700 rounded-xl group hover:bg-gradient-to-br hover:from-TFL-base1 hover:to-purple-800  bg-opacity-50  sm:p-6">
            <p class="font-Montserrat text-3xl group-hover:text-white mx-4 text-center  bg-gradient-to-r from-TFL-base2  to-TFL-base1  text-transparent bg-clip-text">FXTM</p>
            <p class=" mt-4 px-2 text-gray-300 text-xs font-IRANSansBold">شماره حساب</p> 
            <div class="bg-gray-800 p-2 rounded-xl my-2">
              <p class="font-Montserrat text-lg group-hover:text-white mx-4 text-center  bg-gradient-to-r from-gray-500  to-gray-300  text-transparent bg-clip-text">50144342</p>
            </div>
            <p class=" mt-4 px-2 text-gray-300 text-xs font-IRANSansBold">رمزعبور </p> 
            <div class="bg-gray-800 p-2 rounded-xl my-2">
              <p class="font-Montserrat text-lg group-hover:text-white mx-4 text-center  bg-gradient-to-r from-gray-500  to-gray-300  text-transparent bg-clip-text">5hy68dd</p>
            </div>
            <p class=" mt-4 px-2 text-gray-300 text-xs font-IRANSansBold">سرور </p> 
            <div class="bg-gray-800 p-2 rounded-xl my-2">
              <p class="font-Montserrat text-lg group-hover:text-white mx-4 text-center  bg-gradient-to-r from-gray-500  to-gray-300  text-transparent bg-clip-text">RoboForex-Demo</p>
            </div>
          </div>
          <div class=" col-span-1 mx-2 p-6 bg-gray-700 border border-gray-700 rounded-xl group hover:bg-gradient-to-br hover:from-TFL-base1 hover:to-purple-800  bg-opacity-50  sm:p-6">
            <p class="font-Montserrat text-3xl group-hover:text-white mx-4 text-center  bg-gradient-to-r from-TFL-base2  to-TFL-base1  text-transparent bg-clip-text">TickMill</p>
            <p class=" mt-4 px-2 text-gray-300 text-xs font-IRANSansBold">شماره حساب</p> 
            <div class="bg-gray-800 p-2 rounded-xl my-2">
              <p class="font-Montserrat text-lg group-hover:text-white mx-4 text-center  bg-gradient-to-r from-gray-500  to-gray-300  text-transparent bg-clip-text">50144342</p>
            </div>
            <p class=" mt-4 px-2 text-gray-300 text-xs font-IRANSansBold">رمزعبور </p> 
            <div class="bg-gray-800 p-2 rounded-xl my-2">
              <p class="font-Montserrat text-lg group-hover:text-white mx-4 text-center  bg-gradient-to-r from-gray-500  to-gray-300  text-transparent bg-clip-text">5hy68dd</p>
            </div>
            <p class=" mt-4 px-2 text-gray-300 text-xs font-IRANSansBold">سرور </p> 
            <div class="bg-gray-800 p-2 rounded-xl my-2">
              <p class="font-Montserrat text-lg group-hover:text-white mx-4 text-center  bg-gradient-to-r from-gray-500  to-gray-300  text-transparent bg-clip-text">RoboForex-Demo</p>
            </div>
          </div>
        </div> -->
    </div>
</template>

<script>
import Rules from '../Rules';
import URL from "../URL";
import axios from 'axios';

export default {
  data() {
    return {
      activeIndex: null,
      rules: 'General',
      GeneralRules: Rules.GeneralRules,
      twoStepsRules: Rules.twoStepsRules,
      RapidRules: Rules.RapidRules,

      brokers:[]
    };
  },
  mounted(){
        this.getBrokers();
        


    },
  methods: {
    toggleAccordion(index) {
      if (this.activeIndex === index) {
        this.activeIndex = null;
      } else {
        this.activeIndex = index;
      }
    },
    formatNumber(number) {
      const formattedNumber = number.toLocaleString('en-US');
      return digitsEnToFa(formattedNumber);
    },
    rulesButtonClick(value){
            this.rules = value;
            
            
        },
        getBrokers(){
         const url = URL.baseUrl + `Public/account/test`
         axios.get(url,{
        headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    })
        .then(response => {
         
         this.brokers = response.data;
          
          
        })
        .catch(error => {
          
          


        });
      },
  },
  computed: {
    ExnessBroker() {
      return this.brokers.find(broker => broker.broker === "Exness");
    },
    AlpariBroker() {
      return this.brokers.find(broker => broker.broker === "Alpari");
    },
    RoboforexBroker() {
      return this.brokers.find(broker => broker.broker === "Roboforex");
    },
    FXTMBroker() {
      return this.brokers.find(broker => broker.broker === "Fxtm");
    },
    ICMarketsBroker() {
      return this.brokers.find(broker => broker.broker === "ICMarkets");
    },
    TickmillBroker() {
      return this.brokers.find(broker => broker.broker === "Tickmill");
    }
  }
};
</script>

<style>

.line-height p {
  line-height: 2;
}
.accordion {
  width: 100%;
}

.accordion-item {
  border: 1px solid #e2e8f0;
}

.accordion-title {
  background-color: #edf2f7;
  padding: 1rem;
}

.accordion-title:hover {
  background-color: #e2e8f0;
}

.accordion-title:focus {
  outline: none;
}

.accordion-title .rotate-180 {
  transform: rotate(180deg);
}

.accordion-content {
  padding: 1rem;
}
</style>