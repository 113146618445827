<template>
  <div v-if="!allLoaded1 || !allLoaded2 " class="min-h-screen flex items-center justify-center">
    <div class="flex items-center" role="status">
    <svg aria-hidden="true" class="inline w-10 h-10 mr-2 text-gray-200 animate-spin  fill-TFL-base1" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
    </svg>
    <span class="sr-only">Loading...</span>
</div>
</div>
<div v-if="allLoaded1 && allLoaded2 " class="grid grid-cols-12 m-2">

  
  
  
  
  <div class="account-title grid grid-cols-3  col-span-12  xl:hidden   ">
                     
                     <div class="col-span-3  grid grid-rows-11   my-2 p-2 mx-2 bg-TFL-cardDashboard rounded-lg  p-4 m-2    sm:py-6 sm:px-8 ">

                         <div class="flex items-center space-x-4 my-2  ">
                             <div class="flex-shrink-0">
                                 <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 text-TFL-base1 h-6">
                                 <path stroke-linecap="round" stroke-linejoin="round" d="M5.25 8.25h15m-16.5 7.5h15m-1.8-13.5l-3.9 19.5m-2.1-19.5l-3.9 19.5" />
                                 </svg>

                             </div>
                             <div class="flex-1 min-w-0">
                                 <p class="text-sm text-TFL-dashText1 mx-2 truncate ">
                                      شماره حساب
                                 </p>
                             
                             </div>
                             <div v-if="accountNumber" class="inline-flex items-center  text-gray-300 ">
                                 {{formatNumber(accountNumber).replace(/,/g,'')}}
                             </div>
                         </div>
                         <hr class="h-px bg-gray-600 border-0 ">
                         <div class="flex items-center space-x-4 my-2">
                             <div class="flex-shrink-0">
                                 <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 text-TFL-base1 h-6">
                                 <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z" />
                                 </svg>


                             </div>
                             <div class="flex-1 min-w-0">
                                 <p class="text-sm text-TFL-dashText1 mx-2 truncate ">
                                       موجودی اولیه
                                 </p>
                             
                             </div>
                             <div v-if="accountDeposit" class="inline-flex items-center  text-gray-300 ">
                                 {{formatNumber(accountDeposit).replace(/,/g,'')}}
                             </div>
                         </div>
                         <hr class="h-px bg-gray-600 border-0 ">
                         <div class="flex items-center space-x-4 my-2">
                             <div class="flex-shrink-0">
                                 <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 text-TFL-base1 h-6">
                                 <path stroke-linecap="round" stroke-linejoin="round" d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0118 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3l1.5 1.5 3-3.75" />
                                 </svg>

                             </div>
                             <div class="flex-1 min-w-0">
                                 <p class="text-sm text-TFL-dashText1 mx-2 truncate ">
                                        وضعیت
                                 </p>
                             
                             </div>
                             <div v-if="accountState === 'Active'" class="inline-flex items-center bg-TFL-base1 text-white text-sm font-medium mr-2 px-2.5 py-0.5 rounded  ">
                                 
                                  فعال
                             </div>
                             <div v-if="accountState === 'Failed'" class="inline-flex items-center bg-red-100 text-white text-sm font-medium mr-2 px-2.5 py-0.5 rounded ">
                                 مردود
                             </div>
                             <div v-if="accountState === 'ReviewNeeded'" class="inline-flex items-center bg-orange-300 text-white text-sm font-medium mr-2 px-2.5 py-0.5 rounded ">
                                 بررسی کارشناس
                             </div>
                             <div v-if="accountState === 'Reseted'" class="inline-flex items-center bg-gray-300 text-white text-sm font-medium mr-2 px-2.5 py-0.5 rounded ">
                                 ریست شده
                             </div>
                             <div v-if="accountState === 'Expired'" class="inline-flex items-center bg-red-100 text-white text-sm font-medium mr-2 px-2.5 py-0.5 rounded ">
                                 منقضی شده
                             </div>
                             <div v-if="accountState === 'SucceedMinProfit'" class="inline-flex items-center bg-purple-500 text-white text-sm font-medium mr-2 px-2.5 py-0.5 rounded ">
                                 تارگت سود
                             </div>
                             <div v-if="accountState === 'PendingNextPhase'" class="inline-flex items-center bg-purple-500 text-white text-sm font-medium mr-2 px-2.5 py-0.5 rounded ">
                                 تارگت سود
                             </div>
                             <div v-if="accountState === 'Passed'" >
                                 <div class="inline-flex items-center bg-blue-500 text-blue-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded ">

                                     پاس
                                 </div>
                                 
                                 
                             </div>
                             
                         </div>
                         <hr class="h-px bg-gray-600 border-0 ">
                         <div class="flex items-center space-x-4 my-2">
                             <div class="flex-shrink-0">
                                 <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 text-TFL-base1 h-6">
                                 <path stroke-linecap="round" stroke-linejoin="round" d="M15.59 14.37a6 6 0 01-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 006.16-12.12A14.98 14.98 0 009.631 8.41m5.96 5.96a14.926 14.926 0 01-5.841 2.58m-.119-8.54a6 6 0 00-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 00-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 01-2.448-2.448 14.9 14.9 0 01.06-.312m-2.24 2.39a4.493 4.493 0 00-1.757 4.306 4.493 4.493 0 004.306-1.758M16.5 9a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z" />
                                 </svg>
                             </div>
                             <div class="flex-1 min-w-0">
                                 <p class="text-sm text-TFL-dashText1 mx-2 truncate ">
                                     فاز
                                 </p>
                             </div>
                             <div v-if="accountPhase == 'DemoPhase1' && accountplan!='Multiple' " class="inline-flex items-center text-gray-300 ">
                                 فاز اول
                             </div>
                             <div v-if=" accountplan =='Multiple' " class="inline-flex items-center text-gray-300 ">
                                  ریل
                             </div>
                             <div v-if="accountPhase == 'DemoPhase2'" class="inline-flex items-center text-gray-300 ">
                                 فاز دوم
                             </div>
                             <div v-if="accountPhase == 'Real'" class="inline-flex items-center text-gray-300 ">
                                 ریل 
                             </div>
                         </div>
                         <hr class="h-px bg-gray-600 border-0 ">
                         <div class="flex items-center space-x-4 my-2">
                             <div class="flex-shrink-0">
                                 <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 text-TFL-base1 h-6">
                                     <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5" />
                                 </svg>
                             </div>
                             <div class="flex-1 min-w-0">
                                 <p  class="text-sm text-TFL-dashText1 mx-2 truncate ">
                                     تاریخ شروع
                                 </p>
                             </div>
                             <div v-if="accountFirstTrade" class="inline-flex items-center  text-gray-300 ">
                                 {{ formatNumber(accountFirstTrade.replace("T", " at ").replace("+00:00", "").substring(0, 10) )}}
                             </div>
                             <div v-if="!accountFirstTrade" class="inline-flex items-center  text-gray-300 ">
                                در انتظار اولین ترید
                             </div>
                         </div>
                         <hr class="h-px bg-gray-600 border-0 ">
                         <div class="flex items-center space-x-4 my-2">
                             <div class="flex-shrink-0">
                                 <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 text-TFL-base1 h-6">
                                 <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                                 </svg>

                             </div>
                             <div class="flex-1 min-w-0">
                                 <p   class="text-sm text-TFL-dashText1 mx-2 truncate ">
                                     تاریخ پایان
                                 </p>
                             
                             </div>
                             <div v-if="accountplan=='Limited' && accountExpire && accountPhase != 'Real'" class="inline-flex items-center  text-gray-300 ">
                                 {{formatNumber(accountExpire.replace("T", " at ").replace("+00:00", "").substring(0, 10))}}
                             </div>
                             <div v-if=" accountplan=='Limited' && !accountExpire && accountPhase != 'Real'" class="inline-flex items-center  text-gray-300 ">
                                 در انتظار اولین ترید
                             </div>
                             <div v-if=" accountplan=='NoLimit' && accountPhase != 'Real' " class="inline-flex items-center  text-gray-300 ">
                                 بدون محدودیت زمانی
                             </div>
                             <div v-if=" accountPhase == 'Real' " class="inline-flex items-center  text-gray-300 ">
                                 بدون محدودیت زمانی
                             </div>
                         </div>
                         
                             
                 
                             
                         
                         
                     </div>
                     
                 </div>
  <div class="col-span-12 hidden xl:block ">
    <div class="bg-TFL-cardDashboard  p-4 m-2    rounded-lg">
      <div class="grid grid-cols-12  justify-items-center justify-between p-2 ">

        <div class="col-span-12  xl:col-span-2 text-center "  >

          <div class="text-sm text-TFL-dashText1  ">شماره حساب</div>
          <div v-if="accountNumber" class="text-sm mt-2 text-gray-300 ">{{accountNumber.toLocaleString('fa-IR').replace(/٬/g,'')}}</div>
          

        </div>
        <div class="col-span-12 lg:col-span-6 xl:col-span-2 text-center ">

          <div class="text-sm text-TFL-dashText1  ">نوع اکانت</div>
          <div v-if="accountDeposit" class="text-sm mt-2 text-gray-300 text-center ">{{ accountDeposit.toLocaleString('fa-IR').replace(/٬/g,'') }}</div>
          

        </div>
        <div class="col-span-12 lg:col-span-6 xl:col-span-2 text-center ">

          <div class="text-sm text-TFL-dashText1 ">وضعیت</div>
                <div v-if="accountState === 'Active'" class="inline-flex items-center bg-TFL-base1 text-white text-xs font-medium mr-2 px-2.5 py-0.5 rounded  ">
                                  
                                   فعال
                              </div>
                              <div v-if="accountState === 'Failed'" class="inline-flex items-center bg-red-100 text-white text-xs font-medium mr-2 px-2.5 py-0.5 rounded ">
                                  مردود
                              </div>
                              <div v-if="accountState === 'ReviewNeeded'" class="inline-flex items-center bg-orange-300 text-white text-xs font-medium mr-2 px-2.5 py-0.5 rounded ">
                                  بررسی کارشناس
                              </div>
                              <div v-if="accountState === 'Reseted'" class="inline-flex items-center bg-gray-300 text-white text-xs font-medium mr-2 px-2.5 py-0.5 rounded ">
                                  ریست شده
                              </div>
                              <div v-if="accountState === 'Expired'" class="inline-flex items-center bg-red-100 text-white text-xs font-medium mr-2 px-2.5 py-0.5 rounded ">
                                  منقضی شده
                              </div>
                              <div v-if="accountState === 'SucceedMinProfit'" class="inline-flex items-center bg-purple-500 text-white text-xs font-medium mr-2 px-2.5 py-0.5 rounded ">
                                  تارگت سود
                              </div>
                              <div v-if="accountState === 'PendingNextPhase'" class="inline-flex items-center bg-purple-500 text-white text-xs font-medium mr-2 px-2.5 py-0.5 rounded ">
                                  تارگت سود
                              </div>
                              <div v-if="accountState === 'Passed'" class="inline-flex items-center bg-blue-500 text-white text-xs font-medium mr-2 px-2.5 py-0.5 rounded ">
                                  پاس
                              </div>            
          
          

        </div>
        <div class="col-span-12 lg:col-span-6 xl:col-span-2 text-center ">

          <div class="text-sm text-TFL-dashText1 ">فاز</div>
          <div v-if="accountPhase == 'DemoPhase1' && accountplan!='Multiple'" class="inline-flex items-center text-sm text-gray-300 ">
                                   اول
                              </div>
          <div v-if="accountplan =='Multiple'" class="inline-flex items-center text-sm text-gray-300 ">
                                   ریل
                              </div>
                              <div v-if="accountPhase == 'DemoPhase2'" class="inline-flex text-sm items-center  text-gray-300 ">
                                   دوم
                              </div>
                              <div v-if="accountPhase == 'Real'" class="inline-flex items-center text-sm  text-gray-300 ">
                                  ریل 
                              </div>
          
          

        </div>
        <div class="col-span-12 lg:col-span-6 xl:col-span-2 text-center">

          <div class="text-sm text-TFL-dashText1 ">تاریخ شروع</div>
          <div v-if="accountFirstTrade" class="text-sm mt-2 text-gray-300 ">{{formatNumber(accountFirstTrade.replace("T", " at ").replace("+00:00", "").substring(0, 10).toLocaleString('fa-IR').replace(/٬/g,''))}}</div>
          <div v-if="!accountFirstTrade" class="text-sm mt-2 text-gray-300 ">در انتظار اولین ترید</div>
          

        </div>
        <div class="col-span-12 lg:col-span-6 xl:col-span-2 text-center ">

          <div class="text-sm text-TFL-dashText1 ">تاریخ پایان</div>
          <div v-if="accountplan=='Limited' && accountFirstTrade && accountPhase != 'Real'" class="text-sm mt-2 text-gray-300 ">{{ formatNumber(accountExpire.replace("T", " at ").replace("+00:00", "").substring(0, 10).toLocaleString('fa-IR').replace(/٬/g,'')) }}</div>
          <div v-if="accountplan=='Limited' && !accountFirstTrade  && accountPhase != 'Real'" class="text-sm mt-2 text-gray-300 ">در انتظار اولین ترید</div>
          <div v-if="accountplan=='NoLimit' && accountPhase != 'Real' " class="text-sm mt-2 text-gray-300 ">   بدون محدودیت زمانی</div>
          <div v-if=" accountPhase == 'Real' || accountplan =='Multiple' " class="inline-flex items-center  text-gray-300 mt-2 text-sm">
                                 بدون محدودیت 
                             </div>

        </div>
        

      </div>
    </div>
  </div>
  <div v-if="accountState != 'Failed' && failedOrderTicket" class="p-2 m-2 border-2 border-red-400 text-sm col-span-12  rounded-lg bg-TFL-red1 text-white">
    
    <div  class=" text-sm"> اخطار معامله در زمان انتشار اخبار! توجه داشته باشید در صورت تکرار انجام معامله در زمان انتشار اخبار، حساب شما مردود خواهد شد.
    <p>معامله انجام شده در زمان اخبار به شماره تیکت {{ formatNumber(failedOrderTicket) }} است.</p>
    </div>
    

  </div>
  <div v-if="accountState == 'Failed'" class="p-2 m-2 border-2 border-red-400 text-sm col-span-12  rounded-lg bg-TFL-red1 text-white">
    <div v-if="failedBy =='FailedByMaxDrawDown'" class=" text-sm">     کاربر گرامی متاسفانه به علت نقض حداکثر افت کلی وضعیت حساب شما مردود گردید.
    <p>در تاریخ {{ formatNumber(FailedDate) }} در ساعت {{ formatNumber(FailedTime) }}، اکویتی حساب معادل {{ formatNumber(FailedEquity) }} و بالانس حساب معادل {{ formatNumber(FailedBalance) }} ثبت شده است.</p></div>
    <div v-if="failedBy =='FailedByMinEquityDaily'" class=" text-sm">     کاربر گرامی متاسفانه به علت نقض حداکثر افت روزانه وضعیت حساب شما مردود گردید.
      <p>در تاریخ {{ formatNumber(FailedDate) }} در ساعت {{ formatNumber(FailedTime) }}، اکویتی حساب معادل {{ formatNumber(FailedEquity) }} و بالانس حساب معادل {{ formatNumber(FailedBalance) }} ثبت شده است.</p></div>
    <p v-if="failedBy =='FailedByInvalidAccount'" class=" text-sm">     کاربر گرامی متاسفانه به علت تغییر اینوستور پسورد، وضعیت حساب شما مردود گردید.</p>
    <p v-if="failedBy =='FailedByAdmin'" class=" text-sm">  کاربرگرامی، حساب فعلی غیرفعال گردیده‌ است. </p>
    <div v-if="failedComment " class="bg-gray-800 bg-opacity-20 p-2 rounded-lg mt-4">
      <p  class=" text-base mb-2"  > توضیحات تکمیلی:   </p>
      <p  class=" text-sm"  v-html="formatMessage(failedComment)">   </p>

    </div>
    <p v-if="failedBy =='FailedByPositionHedge'" class=" text-sm">     کاربر گرامی متاسفانه به علت نقض قوانین هج پوزیشن وضعیت حساب شما مردود گردید.</p>
    <div v-if="failedBy =='FailedByPositionDrawDown'" class=" text-sm">     کاربر گرامی متاسفانه به علت نقض حداکثر افت پوزیشن وضعیت حساب شما مردود گردید.
      <p>در تاریخ {{ formatNumber(FailedDate) }} در ساعت {{ formatNumber(FailedTime) }}، اکویتی حساب معادل {{ formatNumber(FailedEquity) }} و بالانس حساب معادل {{ formatNumber(FailedBalance) }} ثبت شده است.</p></div>
    <div v-if="failedBy =='FailedByTradeOnNews'" class=" text-sm">     کاربر گرامی متاسفانه به علت انجام معامله در زمان انتشار اخبار وضعیت حساب شما مردود گردید.
    <p>معاملات انجام شده در زمان اخبار به شماره تیکت {{ formatNumber(failedOrderTicket) }} می‌باشند.</p>
    </div>
    <p v-if="failedBy =='FailedByTradeOnWeekends'" class=" text-sm">     کاربر گرامی متاسفانه به علت انجام معامله در تعطیلات، وضعیت حساب شما مردود گردید.</p>
    <p v-if="failedBy =='FailedByNotTradingTillExpired'" class=" text-sm">  کاربر گرامی متاسفانه حساب شما به دلیل عدم معامله در زمان مشخص مردود شده است.</p>

  </div>


  
  
    
  
  
 
  
  

  <div class="col-span-12 lg:col-span-6 xl:col-span-2 m-2">
    <div class="bg-TFL-cardDashboard  p-2    rounded-lg">
      <div class="grid grid-cols-2">
        <div class="col-span-1 lg:col-span-1 xl:col-span-2">
          <div class="flex justify-content-between mx-2 my-1 ">
            <h5 class="text-TFL-dashText1">  بالانس </h5>
          </div>
        </div>
        
        <div class="col-span-1 lg:col-span-1 xl:col-span-2 xl:order-last text-right ">
            <div v-if="accountLastBalance" class="text-gray-300 font-medium text-md xl:text-right text-left mx-2 my-1   ">{{formatNumber(accountLastBalance.toFixed(2))}}</div>
        </div>
      </div>
    </div>
  </div>
  
  <div class="col-span-12 lg:col-span-6 xl:col-span-2 m-2">
    <div class="bg-TFL-cardDashboard  p-2    rounded-lg">
      <div class="grid grid-cols-2">
        <div class="col-span-1 lg:col-span-1 xl:col-span-2">
          <div class="flex justify-content-between mx-2 my-1 ">
            <h5 class="text-TFL-dashText1">  اکویتی </h5>
          </div>
        </div>
        
        <div class="col-span-1 lg:col-span-1 xl:col-span-2 xl:order-last text-right ">
            <div v-if="accountLastEquity" class="text-gray-300 font-medium text-md xl:text-right text-left mx-2 my-1   ">{{formatNumber(accountLastEquity.toFixed(2))}}</div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-span-12 lg:col-span-6 xl:col-span-2 m-2">
    <div class="bg-TFL-cardDashboard  p-2    rounded-lg">
      <div class="grid grid-cols-2">
        <div class="col-span-1 lg:col-span-1 xl:col-span-2">
          <div class="flex justify-content-between m-1 mx-2 ">
            <h5 class="ml-auto  text-TFL-dashText1 text-right">   مانده افت  کلی </h5>
          </div>
        </div>
       
        <div class="col-span-1 lg:col-span-1 xl:col-span-3 xl:order-last text-right ">
            <div v-if="accountLastEquity && accountplan !=='Rapid2'" class="text-gray-300 font-medium text-md xl:text-right text-left m-1 mx-2 ">{{formatNumber(-((accountDeposit*0.88)-accountLastEquity).toFixed(2)).replace(/,/g,'')}}</div>
            <div v-if="!accountLastEquity && accountplan !=='Rapid2'" class="text-gray-300 font-medium text-md xl:text-right text-left m-1 mx-2 "> {{ formatNumber(0) }}</div>
            <div v-if="accountLastEquity && accountplan ==='Rapid2'" class="text-gray-300 font-medium text-md xl:text-right text-left m-1 mx-2 ">{{formatNumber(-((accountDeposit*0.94)-accountLastEquity).toFixed(2)).replace(/,/g,'')}}</div>
            <div v-if="!accountLastEquity && accountplan ==='Rapid2'" class="text-gray-300 font-medium text-md xl:text-right text-left m-1 mx-2 "> {{ formatNumber(0) }}</div>
        </div>
      </div>
    </div>
  </div>

  

  <div class="col-span-12 lg:col-span-6 xl:col-span-2 m-2">
    <div class="bg-TFL-cardDashboard  p-2    rounded-lg">
      <div class="grid grid-cols-2">
        <div class="col-span-1 lg:col-span-1 xl:col-span-2">
          <div class="flex justify-content-between m-1 mx-2  ">
            <h5 class="ml-auto  text-right text-TFL-dashText1">بالانس ابتدای روز </h5>
          </div>
        </div>
       
        <div class="col-span-1 lg:col-span-1 xl:col-span-3 xl:order-last text-right ">
            <div v-if="accountLastDayBalance" class="text-gray-300 font-medium text-md xl:text-right text-left m-1 mx-2 ">{{formatNumber(accountLastDayBalance.toFixed(2))}}</div>
            <div v-if="!accountLastDayBalance && accountDeposit" class="text-gray-300 font-medium text-md xl:text-right text-left m-1 mx-2 ">{{formatNumber(accountDeposit).replace(/,/g,'')}}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-span-12 lg:col-span-6 xl:col-span-2 m-2">
    <div class="bg-TFL-cardDashboard  p-2    rounded-lg">
      <div class="grid grid-cols-2">
        <div class="col-span-1 lg:col-span-1 xl:col-span-2">
          <div class="flex justify-content-between m-1 mx-2  ">
            <h5 class="ml-auto  text-TFL-dashText1 text-right">  سودآورترین روز معاملاتی   </h5>
          </div>
        </div>
        
        
        <div class="col-span-1 lg:col-span-1 xl:col-span-3 xl:order-last text-right">
            <div  class="text-gray-300 font-medium text-md xl:text-right text-left m-1 mx-2 ">{{formatNumber((this.maxDayProfit).toFixed(2)).replace(/,/g,'')}}</div>
            
        </div>
      </div>
    </div>
  </div>


  <div class="col-span-12 lg:col-span-6 xl:col-span-2 m-2">
    <div class="bg-TFL-cardDashboard  p-2    rounded-lg">
      <div class="grid grid-cols-2">
        <div class="col-span-1 lg:col-span-1 xl:col-span-2">
          <div class="flex justify-content-between m-1 mx-2  ">
            <h5 class="ml-auto  text-TFL-dashText1 text-right">روزهای معاملاتی  </h5>
          </div>
        </div>
        
        <div class="col-span-1 lg:col-span-1 xl:col-span-3 xl:order-last text-right ">
            <div v-if="approvedTradingDays && totalTradingDays2" class="text-gray-300 font-medium text-md xl:text-right text-left m-1 mx-2 ">{{formatNumber(approvedTradingDays)}} / {{ formatNumber(totalTradingDays2) }}</div>
            <div v-if="!approvedTradingDays || !totalTradingDays2" class="text-gray-300 font-medium text-md xl:text-right text-left m-1 mx-2 ">{{formatNumber(0)}} / {{ formatNumber(0) }}</div>
        </div>
      </div>
    </div>
</div>



  
 
  


  

  
<div class="col-span-12 xl:col-span-6">
      
      <div class="bg-TFL-cardDashboard  p-4 m-2 mb-4    rounded-lg ">
        <h5 class="mb-4 text-TFL-dashText1"> گزارش حساب</h5>           
            <div class="col-span-12 mt-2 mb-1  text-m text-gray-300 px-4  ">میزان سود </div>
            <div class="col-span-12 grid grid-cols-12 items-center justify-between py-1">
              
              

              <div class=" col-span-12 md:col-span-7 flex-col gap-y-8 px-2 ">
                <div class="rounded-10  bg-TFL-card1 bg-opacity-20 p-2 px-3     text-gray-300 ">
                  <div dir="ltr" class=" flex full  items-center justify-between ">
  
                    <div v-if=" (accountLastBalance - accountDeposit)>=0" class="text-lg text-TFL-green1 ">+ {{ formatNumber((((accountLastBalance - accountDeposit) / (accountDeposit))*100).toFixed(1)) + '%' }}</div>
                    <div v-if=" (accountLastBalance - accountDeposit)<0"  class="text-lg text-TFL-green1 ">+ {{ formatNumber(0) + '%' }}</div>
                    <div v-if="accountplan ==='Multiple' && (accountLastBalance - accountDeposit)>=0"  class="pt-1 text-gray-300 text-opacity-80">{{formatNumber((accountLastBalance - accountDeposit).toFixed(1)) }} / {{formatNumber(0.1*accountDeposit)}}</div>
                    <div v-if="accountplan ==='Multiple' && (accountLastBalance - accountDeposit)<0 "  class="pt-1 text-gray-300 text-opacity-80">{{formatNumber(0)}} / {{formatNumber(0.1*accountDeposit)}}</div>
                    
                    <div v-if="accountPhase === 'DemoPhase1' && (accountLastBalance - accountDeposit)>=0 &&(accountplan == 'Limited' || accountplan == 'NoLimit' )"  class="pt-1 text-gray-300 text-opacity-80">{{formatNumber((accountLastBalance - accountDeposit).toFixed(1)) }} / {{formatNumber(0.08*accountDeposit)}}</div>
                    <div v-if="accountPhase === 'DemoPhase1' && (accountLastBalance - accountDeposit)<0 &&(accountplan == 'Limited' || accountplan == 'NoLimit' ) "  class="pt-1 text-gray-300 text-opacity-80">{{formatNumber(0)}} / {{formatNumber(0.08*accountDeposit)}}</div>
                    <div v-if="accountPhase === 'DemoPhase1' && (accountLastBalance - accountDeposit)>=0 &&(accountplan == 'Limited6' || accountplan == 'NoLimit6' )"  class="pt-1 text-gray-300 text-opacity-80">{{formatNumber((accountLastBalance - accountDeposit).toFixed(1)) }} / {{formatNumber(0.06*accountDeposit)}}</div>
                    <div v-if="accountPhase === 'DemoPhase1' && (accountLastBalance - accountDeposit)<0 &&(accountplan == 'Limited6' || accountplan == 'NoLimit6' )"  class="pt-1 text-gray-300 text-opacity-80">{{formatNumber(0)}} / {{formatNumber(0.06*accountDeposit)}}</div>

                    <div v-if="accountPhase === 'DemoPhase2' && (accountLastBalance - accountDeposit)>=0 &&(accountplan == 'Limited' || accountplan == 'NoLimit' )" class="pt-1 text-gray-300 text-opacity-80">{{formatNumber((accountLastBalance - accountDeposit).toFixed(1)) }} / {{formatNumber(0.04*accountDeposit)}}</div>
                    <div v-if="accountPhase === 'DemoPhase2' && (accountLastBalance - accountDeposit)>=0 &&(accountplan == 'Limited6' || accountplan == 'NoLimit6' )" class="pt-1 text-gray-300 text-opacity-80">{{formatNumber((accountLastBalance - accountDeposit).toFixed(1)) }} / {{formatNumber(0.06*accountDeposit)}}</div>
                    <div v-if="accountPhase === 'DemoPhase2' && (accountLastBalance - accountDeposit)<0 &&(accountplan == 'Limited' || accountplan == 'NoLimit' )" class="pt-1 text-gray-300 text-opacity-80">{{formatNumber(0)}} / {{formatNumber(0.04*accountDeposit)}}</div>
                    <div v-if="accountPhase === 'DemoPhase2' && (accountLastBalance - accountDeposit)<0 &&(accountplan == 'Limited6' || accountplan == 'NoLimit6' )" class="pt-1 text-gray-300 text-opacity-80">{{formatNumber(0)}} / {{formatNumber(0.06*accountDeposit)}}</div>

                    <div v-if="accountPhase === 'Real' && (accountLastBalance - accountDeposit)>0" class="pt-1 text-gray-300 text-opacity-80">{{formatNumber((accountLastBalance - accountDeposit).toFixed(1)) }} </div>
                    <div v-if="accountPhase === 'Real' && (accountLastBalance - accountDeposit)<=0" class="pt-1 text-gray-300 text-opacity-80"> {{ formatNumber(0) }}  </div>
                    <div v-if="accountplan === 'Competition' && (accountLastBalance - accountDeposit)>0" class="pt-1 text-gray-300 text-opacity-80">{{formatNumber((accountLastBalance - accountDeposit).toFixed(1)) }} </div>
                    <div v-if="accountplan === 'Competition' && (accountLastBalance - accountDeposit)<=0" class="pt-1 text-gray-300 text-opacity-80"> {{ formatNumber(0) }}  </div>
  
                    </div>
                  </div>
                </div>
                <div dir="ltr" class=" col-span-5 flex-col gap-y-8 px-2 hidden md:block ">
                <div class="rounded-10 bg-TFL-card1 bg-opacity-20  p-4    text-gray-300 ">
                  <div class=" bg-gray-600 rounded-full h-2.5 ">

                    <div v-if="accountPhase === 'DemoPhase1' && (accountLastBalance - accountDeposit)>=0.08*(accountDeposit) &&(accountplan == 'Limited' || accountplan == 'NoLimit' )" class="bg-TFL-green1 h-2.5 rounded-full" :style="{ width: 100 + '%' }"></div>
                  <div v-if="accountPhase === 'DemoPhase1' && ((accountLastBalance - accountDeposit)>=0) && ((accountLastBalance - accountDeposit)<0.08*(accountDeposit)) &&(accountplan == 'Limited' || accountplan == 'NoLimit' )" class="bg-TFL-green1 h-2.5 rounded-full" :style="{ width: (((accountLastBalance - accountDeposit) / (0.08*accountDeposit))*100).toFixed(1) + '%' }"></div>
                  <div v-if="accountPhase === 'DemoPhase1' && (accountLastBalance - accountDeposit)<0 &&(accountplan == 'Limited' || accountplan == 'NoLimit' )" class="bg-TFL-green1 h-2.5 rounded-full" :style="{ width: 0 + '%' }"></div>
                  <div v-if="accountPhase === 'DemoPhase1' && (accountLastBalance - accountDeposit)>=0.06*(accountDeposit) &&(accountplan == 'Limited6' || accountplan == 'NoLimit6' )" class="bg-TFL-green1 h-2.5 rounded-full" :style="{ width: 100 + '%' }"></div>
                  <div v-if="accountPhase === 'DemoPhase1' && ((accountLastBalance - accountDeposit)>=0) && ((accountLastBalance - accountDeposit)<0.06*(accountDeposit)) &&(accountplan == 'Limited6' || accountplan == 'NoLimit6' )" class="bg-TFL-green1 h-2.5 rounded-full" :style="{ width: (((accountLastBalance - accountDeposit) / (0.06*accountDeposit))*100).toFixed(1) + '%' }"></div>
                  <div v-if="accountPhase === 'DemoPhase1' && (accountLastBalance - accountDeposit)<0 &&(accountplan == 'Limited6' || accountplan == 'NoLimit6' )" class="bg-TFL-green1 h-2.5 rounded-full" :style="{ width: 0 + '%' }"></div>

                  <div v-if="accountPhase === 'DemoPhase2' && (accountLastBalance - accountDeposit)>=0.04*(accountDeposit) &&(accountplan == 'Limited' || accountplan == 'NoLimit' )" class="bg-TFL-green1 h-2.5 rounded-full" :style="{ width: 100 + '%' }"></div>
                  <div v-if="accountPhase === 'DemoPhase2' && (accountLastBalance - accountDeposit)>=0  && (accountLastBalance - accountDeposit)<0.04*(accountDeposit) &&(accountplan == 'Limited' || accountplan == 'NoLimit' )" class="bg-TFL-green1 h-2.5 rounded-full" :style="{ width: (((accountLastBalance - accountDeposit) / (0.04*accountDeposit))*100).toFixed(1) + '%' }"></div>
                  <div v-if="accountPhase === 'DemoPhase2' && (accountLastBalance - accountDeposit)<0 &&(accountplan == 'Limited' || accountplan == 'NoLimit' )" class="bg-TFL-green1 h-2.5 rounded-full" :style="{ width: 0 + '%' }"></div>
                  <div v-if="accountPhase === 'DemoPhase2' && (accountLastBalance - accountDeposit)>=0.06*(accountDeposit) &&(accountplan == 'Limited6' || accountplan == 'NoLimit6' )" class="bg-TFL-green1 h-2.5 rounded-full" :style="{ width: 100 + '%' }"></div>
                  <div v-if="accountPhase === 'DemoPhase2' && (accountLastBalance - accountDeposit)>=0  && (accountLastBalance - accountDeposit)<0.06*(accountDeposit) &&(accountplan == 'Limited6' || accountplan == 'NoLimit6' )" class="bg-TFL-green1 h-2.5 rounded-full" :style="{ width: (((accountLastBalance - accountDeposit) / (0.06*accountDeposit))*100).toFixed(1) + '%' }"></div>
                  <div v-if="accountPhase === 'DemoPhase2' && (accountLastBalance - accountDeposit)<0 &&(accountplan == 'Limited6' || accountplan == 'NoLimit6' )" class="bg-TFL-green1 h-2.5 rounded-full" :style="{ width: 0 + '%' }"></div>
                  
                  <div v-if="accountPhase === 'Real' && (accountLastBalance - accountDeposit)>0" class="bg-TFL-green1 h-2.5 rounded-full" :style="{ width:100 + '%' }"></div>
                  <div v-if="accountPhase === 'Real' && (accountLastBalance - accountDeposit)<=0" class="bg-TFL-green1 h-2.5 rounded-full" :style="{ width: 0 + '%' }"></div>

                  <div v-if="accountplan === 'Competition' && (accountLastBalance - accountDeposit)>0" class="bg-TFL-green1 h-2.5 rounded-full" :style="{ width:100 + '%' }"></div>
                  <div v-if="accountplan === 'Competition' && (accountLastBalance - accountDeposit)<=0" class="bg-TFL-green1 h-2.5 rounded-full" :style="{ width: 0 + '%' }"></div>
                  </div>
                </div>              
              </div>   
            </div>
            
            <div  class="col-span-12 text-m text-gray-300 px-4 mt-2 mb-1">افت روزانه حساب</div>
            <div  class="col-span-12 grid grid-cols-12 flex items-center justify-between py-1">
              
              
              <div class=" col-span-12 md:col-span-7 flex-col gap-y-8 px-2 ">
                <div class="rounded-10  bg-TFL-card1 bg-opacity-20 p-2 px-3     text-gray-300 ">
                  <div v-if="accountplan !== 'Competition' && accountplan !== 'Rapid2' " dir="ltr" class=" flex full  items-center justify-between ">
  
                    <div v-if=" (accountLastEquity-accountLastDayBalance)<0 && accountLastDayBalance" class="text-lg text-TFL-red1 "> -{{formatNumber((((accountLastEquity-accountLastDayBalance) / (-accountLastDayBalance))*100).toFixed(1))}}% / {{formatNumber(-5)}}%</div>
                    <div v-if=" (accountLastEquity-accountLastDayBalance)<0 && !accountLastDayBalance" class="text-lg text-TFL-red1 "> -{{formatNumber((((accountLastEquity-accountDeposit) / (-accountDeposit))*100).toFixed(1))}}% / {{formatNumber(-5)}}%</div>
                    <div v-if=" (accountLastEquity-accountLastDayBalance)>=0 && accountLastDayBalance"  class="text-lg text-TFL-red1 ">{{formatNumber(0) + ''}}  % / {{formatNumber(-5) + ''}} %</div>
                    <div v-if=" (accountLastEquity-accountLastDayBalance)>=0 && !accountLastDayBalance"  class="text-lg text-TFL-red1 ">{{formatNumber(0) + ''}}  % / {{formatNumber(-5) + ''}} %</div>
                      <div v-if="(accountLastEquity-accountLastDayBalance)<0 && accountLastDayBalance" class="pt-1 text-gray-300 text-opacity-80">{{formatNumber((accountLastEquity - accountLastDayBalance).toFixed(1))}} / {{formatNumber((-0.05*accountLastDayBalance).toFixed(1))}}</div>
                      <div v-if="(accountLastEquity-accountLastDayBalance)<0 && !accountLastDayBalance" class="pt-1 text-gray-300 text-opacity-80">{{formatNumber((accountLastEquity - accountDeposit).toFixed(1))}} / {{formatNumber((-0.05*accountDeposit).toFixed(1))}}</div>
                      <div v-if="(accountLastEquity-accountLastDayBalance)>=0 && accountLastDayBalance" class="pt-1 text-gray-300 text-opacity-80">{{formatNumber(0)}} / {{formatNumber((-0.05*accountLastDayBalance).toFixed(0))}}</div>
                      <div v-if="(accountLastEquity-accountLastDayBalance)>=0 && !accountLastDayBalance" class="pt-1 text-gray-300 text-opacity-80">{{formatNumber(0)}} / {{formatNumber((-0.05*accountDeposit).toFixed(0))}}</div>
  
                  </div>
                  <div v-if="accountplan == 'Competition' || accountplan == 'Rapid2' " dir="ltr" class=" flex full  items-center justify-between ">
  
                    <div v-if=" (accountLastEquity-accountLastDayBalance)<0 && accountLastDayBalance" class="text-lg text-TFL-red1 "> -{{formatNumber((((accountLastEquity-accountLastDayBalance) / (-accountLastDayBalance))*100).toFixed(1))}}% / {{formatNumber(-3)}}%</div>
                    <div v-if=" (accountLastEquity-accountLastDayBalance)<0 && !accountLastDayBalance" class="text-lg text-TFL-red1 "> -{{formatNumber((((accountLastEquity-accountDeposit) / (-accountDeposit))*100).toFixed(1))}}% / {{formatNumber(-3)}}%</div>
                    <div v-if=" (accountLastEquity-accountLastDayBalance)>=0 && accountLastDayBalance"  class="text-lg text-TFL-red1 ">{{formatNumber(0) + ''}}  % / {{formatNumber(-3) + ''}} %</div>
                    <div v-if=" (accountLastEquity-accountLastDayBalance)>=0 && !accountLastDayBalance"  class="text-lg text-TFL-red1 ">{{formatNumber(0) + ''}}  % / {{formatNumber(-3) + ''}} %</div>
                      <div v-if="(accountLastEquity-accountLastDayBalance)<0 && accountLastDayBalance" class="pt-1 text-gray-300 text-opacity-80">{{formatNumber((accountLastEquity - accountLastDayBalance).toFixed(1))}} / {{formatNumber((-0.03*accountLastDayBalance).toFixed(1))}}</div>
                      <div v-if="(accountLastEquity-accountLastDayBalance)<0 && !accountLastDayBalance" class="pt-1 text-gray-300 text-opacity-80">{{formatNumber((accountLastEquity - accountDeposit).toFixed(1))}} / {{formatNumber((-0.03*accountDeposit).toFixed(1))}}</div>
                      <div v-if="(accountLastEquity-accountLastDayBalance)>=0 && accountLastDayBalance" class="pt-1 text-gray-300 text-opacity-80">{{formatNumber(0)}} / {{formatNumber((-0.03*accountLastDayBalance).toFixed(0))}}</div>
                      <div v-if="(accountLastEquity-accountLastDayBalance)>=0 && !accountLastDayBalance" class="pt-1 text-gray-300 text-opacity-80">{{formatNumber(0)}} / {{formatNumber((-0.03*accountDeposit).toFixed(0))}}</div>
  
                  </div>
  
                  
                  
                              
  
                </div>
                
              </div>
              <div dir="ltr" class=" col-span-5 flex-col gap-y-8 px-2 hidden md:block  ">
                <div class="rounded-10 bg-TFL-card1 bg-opacity-20  p-4    text-gray-300 ">
                  <div v-if="accountplan !== 'Competition' && accountplan !== 'Rapid2'" class=" bg-gray-600 rounded-full h-2.5 ">

                    <div v-if=" (accountLastEquity-accountLastDayBalance)<0 && (accountLastEquity-accountLastDayBalance*0.95)>0 " class="bg-TFL-red1 h-2.5 rounded-full" :style="{ width: (((accountLastEquity-accountLastDayBalance) / (-0.05*accountLastDayBalance))*100).toFixed(1) + '%' }"></div>
                    <div v-if=" (accountLastEquity-accountLastDayBalance)<0 && (accountLastEquity-accountLastDayBalance*0.95)>0" class="absolute right-0 -top-4 text-xs text-gray-500 dark:text-gray-300">
                      -{{(((accountLastEquity-accountLastDayBalance) / (-accountLastDayBalance))*100).toFixed(1)}}%
                    </div>

                    <div v-if=" (accountLastEquity-accountLastDayBalance)>=0" class="bg-TFL-red1 h-2.5 rounded-full" :style="{ width: 0 + '%' }"></div>
                    <div v-if=" (accountLastEquity-accountLastDayBalance)>=0" class="absolute right-0 -top-4 text-xs text-gray-500 dark:text-gray-300">
                      -{{ formatNumber(0) }}%
                    </div>

                    <div v-if=" accountLastEquity !== null && (accountLastEquity-accountLastDayBalance*0.95)<=0" class="bg-TFL-red1 h-2.5 rounded-full" :style="{ width: 100 + '%' }"></div>
                    <div v-if=" accountLastEquity !== null && (accountLastEquity-accountLastDayBalance*0.95)<=0" class="absolute right-0 -top-4 text-xs text-gray-500 dark:text-gray-300">
                      -{{ formatNumber(100) }}%
                    </div>
                    
                  </div>
                  <div v-if="accountplan == 'Competition' || accountplan == 'Rapid2'" class=" bg-gray-600 rounded-full h-2.5 ">

                    <div v-if=" (accountLastEquity-accountLastDayBalance)<0 && (accountLastEquity-accountLastDayBalance*0.94)>0 " class="bg-TFL-red1 h-2.5 rounded-full" :style="{ width: (((accountLastEquity-accountLastDayBalance) / (-0.06*accountLastDayBalance))*100).toFixed(1) + '%' }"></div>
                    <div v-if=" (accountLastEquity-accountLastDayBalance)<0 && (accountLastEquity-accountLastDayBalance*0.94)>0" class="absolute right-0 -top-4 text-xs text-gray-500 dark:text-gray-300">
                      -{{(((accountLastEquity-accountLastDayBalance) / (-accountLastDayBalance))*100).toFixed(1)}}%
                    </div>

                    <div v-if=" (accountLastEquity-accountLastDayBalance)>=0" class="bg-TFL-red1 h-2.5 rounded-full" :style="{ width: 0 + '%' }"></div>
                    <div v-if=" (accountLastEquity-accountLastDayBalance)>=0" class="absolute right-0 -top-4 text-xs text-gray-500 dark:text-gray-300">
                      -{{ formatNumber(0) }}%
                    </div>

                    <div v-if=" accountLastEquity !== null && (accountLastEquity-accountLastDayBalance*0.94)<=0" class="bg-TFL-red1 h-2.5 rounded-full" :style="{ width: 100 + '%' }"></div>
                    <div v-if=" accountLastEquity !== null && (accountLastEquity-accountLastDayBalance*0.94)<=0" class="absolute right-0 -top-4 text-xs text-gray-500 dark:text-gray-300">
                      -{{ formatNumber(100) }}%
                    </div>
                    
                  </div>
                </div>
                
              </div>
  
              
    
            </div>
            
            
            <div class="col-span-12 text-m text-gray-300 px-4 mt-2 mb-1">افت کلی حساب</div>
            <div class="col-span-12 grid grid-cols-12 items-center justify-between py-1">
              
              
              <div dir="ltr"  class=" col-span-12 md:col-span-7 flex-col gap-y-8 px-2 ">
                <div class="rounded-10  bg-TFL-card1 bg-opacity-20 p-2 px-3 shadow-[rgba(0,_0,_0,_0.50)_0px_3px_8px]  text-gray-300 ">
                  <div class=" flex full  items-center justify-between ">
  
                    <div dir="ltr" v-if="accountplan !=='Multiple' && (accountLastEquity<accountDeposit) && accountplan !=='Competition' && accountplan !=='Rapid2' " class="text-lg text-TFL-red1 "> -{{formatNumber((((accountLastEquity-accountDeposit) / (-accountDeposit))*100).toFixed(1))}}% / {{ formatNumber(-12) }} %</div>
                  <div dir="ltr" v-if="accountplan !=='Multiple' && (accountLastEquity>=accountDeposit) && accountplan !=='Competition' && accountplan !=='Rapid2'" class="text-lg text-TFL-red1 "> {{ formatNumber(0) }} % / {{ formatNumber(-12) }} %</div>
                    <div dir="ltr" v-if=" accountplan !=='Multiple' && (accountLastEquity<accountDeposit) && accountplan !=='Competition' && accountplan !=='Rapid2'" class="pt-1 text-gray-300 text-opacity-80">{{formatNumber((accountLastEquity - accountDeposit).toFixed(1))}} / {{formatNumber((-0.12*accountDeposit).toFixed(0))}}</div>
                    <div dir="ltr" v-if="accountplan !=='Multiple' && (accountLastEquity>=accountDeposit) && accountplan !=='Competition' && accountplan !=='Rapid2'" class="pt-1 text-gray-300 text-opacity-80">{{ formatNumber(0) }}  / {{formatNumber((-0.12*accountDeposit).toFixed(0))}}</div>
                  
                    <div dir="ltr" v-if="accountplan =='Multiple' && (accountLastEquity<accountDeposit)" class="text-lg text-TFL-red1 "> -{{formatNumber((((accountLastEquity-accountDeposit) / (-accountDeposit))*100).toFixed(1))}}% / {{ formatNumber(-5) }} %</div>
                  <div dir="ltr" v-if="accountplan =='Multiple' && (accountLastEquity>=accountDeposit)" class="text-lg text-TFL-red1 "> {{ formatNumber(0) }} % / {{ formatNumber(-5) }} %</div>
                    <div dir="ltr" v-if=" accountplan =='Multiple' && (accountLastEquity<accountDeposit)" class="pt-1 text-gray-300 text-opacity-80">{{formatNumber((accountLastEquity - accountDeposit).toFixed(1))}} / {{formatNumber((-0.05*accountDeposit).toFixed(0))}}</div>
                    <div dir="ltr" v-if="accountplan =='Multiple' && (accountLastEquity>=accountDeposit)" class="pt-1 text-gray-300 text-opacity-80">{{ formatNumber(0) }}  / {{formatNumber((-0.05*accountDeposit).toFixed(0))}}</div>

                    <div dir="ltr" v-if="accountplan =='Competition' && (accountLastEquity<accountDeposit)" class="text-lg text-TFL-red1 "> -{{formatNumber((((accountLastEquity-accountDeposit) / (-accountDeposit))*100).toFixed(1))}}% / {{ formatNumber(-6) }} %</div>
                  <div dir="ltr" v-if="accountplan =='Competition' && (accountLastEquity>=accountDeposit)" class="text-lg text-TFL-red1 "> {{ formatNumber(0) }} % / {{ formatNumber(-6) }} %</div>
                    <div dir="ltr" v-if=" accountplan =='Competition' && (accountLastEquity<accountDeposit)" class="pt-1 text-gray-300 text-opacity-80">{{formatNumber((accountLastEquity - accountDeposit).toFixed(1))}} / {{formatNumber((-0.06*accountDeposit).toFixed(0))}}</div>
                    <div dir="ltr" v-if="accountplan =='Competition' && (accountLastEquity>=accountDeposit)" class="pt-1 text-gray-300 text-opacity-80">{{ formatNumber(0) }}  / {{formatNumber((-0.06*accountDeposit).toFixed(0))}}</div>

                    <div dir="ltr" v-if="accountplan =='Rapid2' && (accountLastEquity<accountDeposit)" class="text-lg text-TFL-red1 "> -{{formatNumber((((accountLastEquity-accountDeposit) / (-accountDeposit))*100).toFixed(1))}}% / {{ formatNumber(-6) }} %</div>
                  <div dir="ltr" v-if="accountplan =='Rapid2' && (accountLastEquity>=accountDeposit)" class="text-lg text-TFL-red1 "> {{ formatNumber(0) }} % / {{ formatNumber(-6) }} %</div>
                    <div dir="ltr" v-if=" accountplan =='Rapid2' && (accountLastEquity<accountDeposit)" class="pt-1 text-gray-300 text-opacity-80">{{formatNumber((accountLastEquity - accountDeposit).toFixed(1))}} / {{formatNumber((-0.06*accountDeposit).toFixed(0))}}</div>
                    <div dir="ltr" v-if="accountplan =='Rapid2' && (accountLastEquity>=accountDeposit)" class="pt-1 text-gray-300 text-opacity-80">{{ formatNumber(0) }}  / {{formatNumber((-0.06*accountDeposit).toFixed(0))}}</div>
                  </div>
                  
                  
                              
  
                </div>
                
              </div>
              <div dir="ltr" class=" col-span-5 flex-col gap-y-8 px-2 hidden md:block ">
                
                <div class="rounded-10 bg-TFL-card1 bg-opacity-20 shadow-[rgba(0,_0,_0,_0.50)_0px_3px_8px]  p-4 text-gray-300 ">
                  <div class=" bg-gray-600 rounded-full h-2.5">

                    <div v-if=" accountplan !=='Multiple' &&  (accountLastEquity-accountDeposit)<0 && (accountLastEquity-accountDeposit*0.88)>0 && accountplan !=='Competition'" class="bg-TFL-red1 h-2.5 rounded-full" :style="{ width: (((accountLastEquity-accountDeposit) / (-0.12*accountDeposit))*100).toFixed(1) + '%' }"></div>
                  <div v-if=" accountplan !=='Multiple' && (accountLastEquity-accountDeposit)<0 && (accountLastEquity-accountDeposit*0.88)>0 && accountplan !=='Competition'" class="absolute right-0 -top-4 text-xs text-gray-500 dark:text-gray-300">
                    -{{(((accountLastEquity-accountDeposit) / (-accountDeposit))*100).toFixed(1)}}%
                  </div>
                  <div v-if=" accountplan =='Multiple' &&  (accountLastEquity-accountDeposit)<0 && (accountLastEquity-accountDeposit*0.95)>0" class="bg-TFL-red1 h-2.5 rounded-full" :style="{ width: (((accountLastEquity-accountDeposit) / (-0.05*accountDeposit))*100).toFixed(1) + '%' }"></div>
                  <div v-if=" accountplan =='Multiple' && (accountLastEquity-accountDeposit)<0 && (accountLastEquity-accountDeposit*0.95)>0" class="absolute right-0 -top-4 text-xs text-gray-500 dark:text-gray-300">
                    -{{(((accountLastEquity-accountDeposit) / (-accountDeposit))*100).toFixed(1)}}%
                  </div>

                  <div v-if=" accountplan =='Competition' &&  (accountLastEquity-accountDeposit)<0 && (accountLastEquity-accountDeposit*0.94)>0" class="bg-TFL-red1 h-2.5 rounded-full" :style="{ width: (((accountLastEquity-accountDeposit) / (-0.06*accountDeposit))*100).toFixed(1) + '%' }"></div>
                  <div v-if=" accountplan =='Competition' && (accountLastEquity-accountDeposit)<0 && (accountLastEquity-accountDeposit*0.94)>0" class="absolute right-0 -top-4 text-xs text-gray-500 dark:text-gray-300">
                    -{{(((accountLastEquity-accountDeposit) / (-accountDeposit))*100).toFixed(1)}}%
                  </div>
                  <div v-if=" accountplan !=='Multiple' && (accountLastEquity-accountDeposit)>=0 && accountplan !=='Competition'" class="bg-TFL-red1 h-2.5 rounded-full" :style="{ width: 0 + '%' }"></div>
                  <div v-if=" accountplan !=='Multiple' && (accountLastEquity-accountDeposit)>=0 && accountplan !=='Competition'" class="absolute right-0 -top-4 text-xs text-gray-500 dark:text-gray-300">
                     {{formatNumber(0)}}%
                  </div>
                  <div v-if=" accountplan =='Multiple' && (accountLastEquity-accountDeposit)>=0" class="bg-TFL-red1 h-2.5 rounded-full" :style="{ width: 0 + '%' }"></div>
                  <div v-if=" accountplan =='Multiple' && (accountLastEquity-accountDeposit)>=0" class="absolute right-0 -top-4 text-xs text-gray-500 dark:text-gray-300">
                     {{formatNumber(0)}}%
                  </div>
                  <div v-if=" accountplan =='Competition' && (accountLastEquity-accountDeposit)>=0" class="bg-TFL-red1 h-2.5 rounded-full" :style="{ width: 0 + '%' }"></div>
                  <div v-if=" accountplan =='Competition' && (accountLastEquity-accountDeposit)>=0" class="absolute right-0 -top-4 text-xs text-gray-500 dark:text-gray-300">
                     {{formatNumber(0)}}%
                  </div>
                  <div v-if="accountplan !=='Multiple' && accountLastEquity !== null && (accountLastEquity-accountDeposit*0.88)<=0 && accountplan !=='Competition'" class="bg-indigo-900 h-2.5 rounded-full" :style="{ width: 100 + '%' }"></div>
                  <div v-if="accountplan !=='Multiple' && accountLastEquity !== null && (accountLastEquity-accountDeposit*0.88)<=0 && accountplan !=='Competition'" class="absolute right-0 -top-4 text-xs text-gray-500 dark:text-gray-300">
                    -{{ 100 }}%
                  </div>
                  <div v-if="accountplan =='Multiple' && accountLastEquity !== null && (accountLastEquity-accountDeposit*0.95)<=0" class="bg-indigo-900 h-2.5 rounded-full" :style="{ width: 100 + '%' }"></div>
                  <div v-if="accountplan =='Multiple' && accountLastEquity !== null && (accountLastEquity-accountDeposit*0.95)<=0" class="absolute right-0 -top-4 text-xs text-gray-500 dark:text-gray-300">
                    -{{ 100 }}%
                  </div>
                  <div v-if="accountplan =='Competition' && accountLastEquity !== null && (accountLastEquity-accountDeposit*0.94)<=0" class="bg-indigo-900 h-2.5 rounded-full" :style="{ width: 100 + '%' }"></div>
                  <div v-if="accountplan =='Competition' && accountLastEquity !== null && (accountLastEquity-accountDeposit*0.94)<=0" class="absolute right-0 -top-4 text-xs text-gray-500 dark:text-gray-300">
                    -{{ 100 }}%
                  </div>
                  </div>
                </div>
                
              </div>
  
              
    
            </div>
            
  
            
            
  
          

      
          

          
          

        

    </div>
    <div class="bg-TFL-cardDashboard  p-4 m-2 mb-4    rounded-lg">
        <div class="flex justify-content-between align-items-center mb-5">
          <h5 class="ml-auto text-TFL-dashText1 ">آمار معاملات</h5>     
        </div>
        <div class="grid grid-cols-2">
          <ul class="col-span-2 xl:col-span-1 pr-2">
            <li class="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4">
              <span class="text-gray-300 font-medium mr-2 mb-1 md:mb-0   ">تعداد معاملات</span>
              <span v-if="tradesCount"  class="text-gray-300 font-medium ml-4 mb-1 md:mb-0 mr-auto ">{{formatNumber(this.tradesCount)}}</span>
              
            </li>
            <hr class="h-px my-2 bg-gray-600 border-0 ">
            <li class="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4">
              <span class="text-gray-300 font-medium mr-2 mb-1 md:mb-0 ">  تعداد معاملات موفق</span>
              <span v-if="profitTradeCount" class="text-TFL-green1 font-medium ml-4 mb-1 md:mb-0  mr-auto ">{{formatNumber(this.profitTradeCount)}}</span>
            </li>
            <hr class="h-px my-2 bg-gray-600 border-0 ">
            <li class="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4">
              <span class="text-gray-300 font-medium mr-2 mb-1 md:mb-0  ">  میانگین سود معاملات</span>
              <span v-if="averageWin" class="text-TFL-green1 font-medium ml-4 mb-1 md:mb-0 mr-auto  ">{{formatNumber(this.averageWin.toFixed(1))}}</span>
            </li>
            <hr class="h-px my-2 bg-gray-600 border-0 ">
            <li class="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4">
              <span class="text-gray-300 font-medium mr-2 mb-1 md:mb-0  ">  حداکثر سود معاملات</span>
              <span v-if="bestTrade" class="text-TFL-green1 font-medium ml-4 mb-1 md:mb-0 mr-auto  ">{{formatNumber(this.bestTrade.toFixed(1))}}</span>
              
            </li>
            <hr class="h-px my-2 bg-gray-600 border-0 ">
            <li class="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4">
              <span class="text-gray-300 font-medium mr-2 mb-1 md:mb-0  ">  نسبت میانگین سود به ضرر  </span>
              <span v-if="averageWin & averageLoss"  class="text-TFL-green1 font-medium ml-4 mb-1 md:mb-0 mr-auto  ">{{formatNumber((this.averageWin/-this.averageLoss).toFixed(2))}}</span>
              
              
            </li>
          </ul>
        
        
          <ul class="col-span-2 xl:col-span-1 pl-2">
            <li class="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4">
              <span class="text-gray-300 font-medium mr-2 mb-1 md:mb-0   ">میانگین زمان معاملات</span>
              <span v-if="avrgTradeTime" class="text-gray-300 font-medium mr-2 mb-1 md:mb-0 mr-auto ">{{formatNumber(this.avrgTradeTime)}}</span>
            </li>
            <hr class="h-px my-2 bg-gray-600 border-0 ">
            <li class="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4">
              <span class="text-gray-300 font-medium mr-2 mb-1 md:mb-0  ">  تعداد معاملات ناموفق</span>
              <span v-if="lossTradeCount" class="text-red-100 font-medium mr-2 mb-1 md:mb-0 mr-auto ">{{formatNumber(this.lossTradeCount)}}</span>
            </li>
            <hr class="h-px my-2 bg-gray-600 border-0 ">
            <li class="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4">
              <span class="text-gray-300 font-medium mr-2 mb-1 md:mb-0  ">  میانگین ضرر معاملات  </span>
              <span v-if="averageLoss" class="text-red-100 font-medium mr-2 mb-1 md:mb-0 mr-auto  ">{{formatNumber(-this.averageLoss.toFixed(1))}}</span>
            </li>
            <hr class="h-px my-2 bg-gray-600 border-0 ">
            <li class="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4">
              <span class="text-gray-300 font-medium mr-2 mb-1 md:mb-0  ">  حداکثر ضرر معاملات  </span>
              <span v-if="worstTrade"  class="text-red-100 font-medium mr-2 mb-1 md:mb-0 mr-auto  ">{{formatNumber(-this.worstTrade.toFixed(1))}}</span>
              
            </li>
            <hr class="h-px my-2 bg-gray-600 border-0 ">
            <li class="flex flex-column md:flex-row md:align-items-center md:justify-content-between mb-4">
              <span class="text-gray-300 font-medium mr-2 mb-1 md:mb-0  ">  فاکتور سود    </span>
              <span v-if="profitFactor" class="text-red-100 font-medium mr-2 mb-1 md:mb-0 mr-auto  ">{{formatNumber(this.profitFactor.toFixed(1))}}</span>
              
            </li>
            
          </ul>
        </div>
        

        
      </div>

   
    <div class="bg-TFL-cardDashboard  p-4 m-2 mb-4    rounded-lg">
      <div class="flex justify-content-between align-items-center mb-5">
        <h5 class="ml-auto text-TFL-dashText1 ">آمار تیک اسکلپ</h5>     
      </div>
      <div class="grid grid-cols-2 text-white">
        <p> تعداد معاملات فعال شده  </p>
        <p>{{this.realTrade }}</p>
        <p> تعداد معاملات زیر 1 دقیقه  </p>
        <p>{{this.min1TradesCount }}</p>
        <p> تیکت معاملات زیر 1 دقیقه</p>
        <p>{{this.min1Tickets}}</p>
        <p> تعداد معاملات بین 1 و 2 دقیقه  </p>
        <p>{{this.min2TradesCount }}</p>
        <p> تیکت معاملات زیر 2 دقیقه</p>
        <p>{{this.min2Tickets}}</p>

      
    </div>
    <div class="flex justify-content-between align-items-center mb-5">
        <h5 class="ml-auto text-TFL-dashText1 mt-4">آمار 80 درصد</h5>     
      </div>
      <div class="grid grid-cols-2 text-white">
        <p> نقض داشته؟ </p>
        <p>{{this.isHighRisk }}</p>
        <p> کدام معاملات؟ </p>
        <p>{{this.highRiskTrades }}</p>
        

      
    </div>
    


  </div>
    


  </div>
  
  <div class="col-span-12 xl:col-span-6 ">
    <div class="bg-TFL-cardDashboard  p-4 m-2 mb-4    rounded-lg">
      <div class="w-full grid grid-cols-3 bg-gray-800  py-1  m-1 rounded">
      <button @click="ChartSelected('Balance')" :class="{ 'bg-gray-600 bg-opacity-50 text-TFL-dashText1': this.selectedChart !== 'Balance','bg-TFL-base1  text-white': this.selectedChart === 'Balance' }" class="col-span-1 py-1 px-2 mx-1 rounded text-sm"> بالانس</button>
        <button @click="ChartSelected('Equity')" :class="{ 'bg-gray-600 bg-opacity-50 text-TFL-dashText1': this.selectedChart !== 'Equity','bg-TFL-base1  text-white': this.selectedChart === 'Equity' }" class="col-span-1 py-1 px-2 mx-1 rounded  text-sm">اکویتی  </button>
        <button @click="ChartSelected('Position')" :class="{ 'bg-gray-600 bg-opacity-50 text-TFL-dashText1': this.selectedChart !== 'Position','bg-TFL-base1  text-white': this.selectedChart === 'Position' }" class="col-span-1 py-1 px-2 mx-1 rounded  text-sm">پوزیشن  </button>
        </div>
      
      <BalanceApex v-if="showPosition && selectedChart == 'Balance'" :tradeHistory="tradeHistory" style="height: 100%" />
      <EquityApexAdmin v-if=" selectedChart == 'Equity'" style="height: 100%" />
      <!-- <PositionApex v-if=" selectedChart == 'Position' " :tradeHistory="tradeHistory" style="height: 100%" /> -->
      <PositionChart  v-if=" selectedChart == 'Position' " :tradeHistory="tradeHistory" class="w-full h-full" />

    </div>
    
    
    
  </div>
  
  <div class="col-span-12">
    
    <div class=" grid grid-cols-3 bg-TFL-cardDashboard  p-4 m-2 mb-4    rounded-lg">
      <h2 class="text-TFL-dashText1 col-span-3 md:col-span-1 text-sm md:text-base m-4 font-medium"> تاریخچه معاملات</h2>
      <div class="col-span-3 md:col-span-1 justify-self-center bg-gray-800 py-1  m-1 self-center rounded">

        <button @click="TradeHistorySelected('Open')" :class="{ 'bg-gray-600 bg-opacity-50 text-TFL-dashText1': this.selectedHistory !== 'Open','bg-TFL-base1  text-white': this.selectedHistory === 'Open' }" class="col-span-4 py-1 px-2 mx-1 rounded text-sm">معاملات باز</button>
        <button @click="TradeHistorySelected('Close')" :class="{ 'bg-gray-600 bg-opacity-50 text-TFL-dashText1': this.selectedHistory !== 'Close','bg-TFL-base1  text-white': this.selectedHistory === 'Close' }" class="col-span-4 py-1 px-2 mx-1 rounded  text-sm">معاملات بسته </button>
      </div>

      <TradeHistory v-if="!showOpen"  :tradeHistory="tradeHistory"/>
      <TradeHistoryOpen v-if="showOpen"  :tradeHistoryOpen="tradeHistoryOpen"/>
    </div>


  </div>
 

</div>
</template>

  <script>
  
  import BalanceChart from './BalanceChart.vue';
  
  
  
  import PositionChart from './PositionChart.vue';
  import NewChallenge from './NewChallenge.vue';
  import TradeHistory from './TradeHistory';
  import TradeHistoryOpen from './TradeHistoryOpen';
  import EquityChartApex from '../components/EquityChartApex.vue';
  import EquityApexAdmin from '../components/EquityApexAdmin.vue';
  import BalanceApex from '../components/BalanceApex.vue';
  import PositionApex from '../components/PositionApex.vue';
  
  
  import { digitsEnToFa } from 'persian-tools';
  import EquityChart from './EquityChart.vue';
  import DailyChart from './DailyChart.vue';
 
  import axios from 'axios';
  import URL from "../URL";

  
  
  
  
  export default {
    name: 'DAnalysis',
    components: { BalanceChart, DailyChart, PositionChart, EquityChart, NewChallenge, TradeHistory, EquityChartApex,TradeHistoryOpen,EquityApexAdmin,BalanceApex,PositionApex },
    props: {
      accountUIDfromAccountList: {
      type: String,
      required: true,
    },
  },
    

    
    data(){

      
      return{
        renderChart1: false,
        renderChart2: false,
        renderChart3: false,
        selectedChart: 'Balance',


        selectedHistory: 'Close',
        EquityData: [],
        EquityDataMax: [],
        EquityDataM: [],
      
        trades: [],
        tradeHistory:[],
        tradeHistoryOpen:[],
        PositionTrades: [],

        accountsDetails:[],
        accountuId: this.$route.params.id,
        showEquity1:false,
        showEquity2:false,
        showPosition:false,

        showOpen: false,
        allLoaded1: false,
        allLoaded2: false,
        allLoaded3: false,
      
        tradesCount: 0,
        profitTradeCount: 0,
        lossTradeCount: 0,
        profitability: 0,
        averageWin: 0,
        averageLoss: 0,
        
        bestTrade: 0,
        worstTrade: 0,
        avrgTradeTime: 0,
        profitFactor: 0,
        standardDeviation: 0,
        expectancy: 0,

        FailedDate: '',
        FailedTime: '',
        FailedBalance: 0,
        FailedEquity: 0,
        failedComment: '',

        failedOrderTicket: '',
        
        min1TradesCount : 0, // Count of trades under 1 minute
        min2TradesCount : 0, // Count of trades between 1 and 2 minutes
        min1Tickets : [], // Tickets of trades under 1 minute
        min2Tickets : [], // Tickets of trades between 1 and 2 minutes

        realTrade :0,
        isHighRisk: null,
        highRiskTrades:[],
    }
    
  
    },
    methods: {
      formatMessage(message) {
    return message.replace(/\n/g, '<br>');
  },
    //   fetchEquityData() {
    //   const accountId = this.accountuId;
    //   const timeFrame = 'D1';
    //   const indicator = 'Min';
    //   const parameter = 'Equity';

    //   const url = URL.baseUrl + `Admin/account/history?AccountId=${accountId}&TimeFrame=${timeFrame}&Indicator=${indicator}&Parameter=${parameter}`;
      
      
    //   axios.get(url,{
    //     headers: {
    //     'Authorization': 'Bearer ' + localStorage.getItem('token')
    //     }
    // })
    //     .then(response => {
    //       // loop through response data and push each object into trades array
    //       this.EquityData = response.data;
    //       this.$emit('equityloaded', this.EquityData);
    //       this.showEquity1 = true;
    //       console.log( this.EquityData);
    //       console.log( "balaayi oone");
    //     })
      
      
    //     .catch(error => {
    //       console.error(error);
    //     });
    // },
    //   fetchEquityDataMax() {
    //   const accountId = this.accountuId;
    //   const timeFrame = 'D1';
    //   const indicator = 'Max';
    //   const parameter = 'Equity';

    //   const url = URL.baseUrl + `Admin/account/history?AccountId=${accountId}&TimeFrame=${timeFrame}&Indicator=${indicator}&Parameter=${parameter}`;
      
      
    //   axios.get(url,{
    //     headers: {
    //     'Authorization': 'Bearer ' + localStorage.getItem('token')
    //     }
    // })
    //     .then(response => {
    //       // loop through response data and push each object into trades array
    //       this.EquityDataMax = response.data;
    //       this.$emit('equityMaxloaded', this.EquityDataMax);
    //       this.showEquity2 = true;
    //       console.log( this.EquityDataMax);
    //       console.log( "Max oone");
    //     })
      
      
    //     .catch(error => {
    //       console.error(error);
    //     });
    // },
    //   fetchEquityDataM1() {
    //   const accountId = this.accountuId;
    //   const timeFrame = 'M1';
    //   const indicator = 'Min';
    //   const parameter = 'Equity';

    //   const url = URL.baseUrl + `Admin/account/history?AccountId=${accountId}&TimeFrame=${timeFrame}&Indicator=${indicator}&Parameter=${parameter}`;
      
      
    //   axios.get(url,{
    //     headers: {
    //     'Authorization': 'Bearer ' + localStorage.getItem('token')
    //     }
    // })
    //     .then(response => {
    //       // loop through response data and push each object into trades array
    //       this.EquityDataM = response.data;
    //       this.$emit('equityloaded', this.EquityDataM);
          
    //     })
      
      
    //     .catch(error => {
    //       console.error(error);
    //     });
    // },
    updateTrades(trades) {
      this.trades = trades;

    },
    updatePositionTrades(PositionTrades) {
      this.PositionTrades = PositionTrades;

    },
    formatNumber(number) {
      const formattedNumber = number.toLocaleString('en-US');
      return digitsEnToFa(formattedNumber);
    },
    fetchStat() {
      
      const url = URL.baseUrl + `Admin/account/advancestatistics?AccountId=${this.accountuId}`;
      axios.get(url,{
        headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    })
        .then(response => {
          if(response.data != ''){
            
            
            this.tradesCount = response.data.tradesCount;
            this.profitTradeCount = response.data.profitTradeCount;
            this.lossTradeCount = response.data.lossTradeCount;
            this.profitability = response.data.profitability;
            this.averageWin = response.data.averageWin;
            this.averageLoss = response.data.averageLoss;
            
            this.bestTrade = response.data.bestTrade;
            this.worstTrade = response.data.worstTrade;
            this.avrgTradeTime = response.data.avrgTradeTime;
            this.profitFactor = response.data.profitFactor;
            this.standardDeviation = response.data.standardDeviation;
            this.expectancy = response.data.expectancy;
            this.isHighRisk = response.data.isHighRisk;
            this.highRiskTrades = response.data.highRiskTrades
          }
          
        })
        .catch(error => {
          this.allLoaded1 = true;
        });
    },
    fetchFailedTickData() {
      
      const url = URL.baseUrl + `Admin/account/tickData/${this.failedTickdataId}`;
      axios.get(url,{
        headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    })
        .then(response => {
          this.failedTickData = response.data;
          const dateTime = new Date(this.failedTickData.serverTime);
          this.FailedDate = dateTime.toISOString().split('T')[0];
          this.FailedTime = dateTime.toTimeString().split('.')[0];
          this.FailedBalance = this.failedTickData.balance;
          this.FailedEquity = this.failedTickData.equity;
          
        })
        .catch(error => {
          
        });
    },

    fetchDataForChooseAccount() {
      
      const url = URL.baseUrl + `Admin/accounts?Uid=${this.accountuId}&Page=1&PageSize=20`;
      axios.get(url,{
        headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    })
        .then(response => {
          this.accountsDetails = response.data;
          const account = this.accountsDetails.find(account => account.uId === this.accountuId);
          this.failedTickdataId = account ? account.failedTickDataId : null;
          if(this.failedTickdataId != null){
            

            this.fetchFailedTickData();
          }
          if ( this.accountUIDfromAccountList ){
            this.accountuId = this.accountUIDfromAccountList;
            this.accountUIDfromAccountList = "";
            
          };
           if ( !this.accountuId){

            this.accountuId = this.accountsDetails[0].uId;
            
          }
          // age az accountList omade bud
          this.allLoaded1 = true;
        })
        .catch(error => {
          this.allLoaded1 = true;
        });
    },
    getAccountData() {
    // Make a request to the server to get account details
    const url = URL.baseUrl + `Admin/accounts`;
    axios.get(url,{
        headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    })
      .then(response => {
        // Add the show variable to each account object
        this.accounts = response.data.map(account => {
          return {
            ...account,
            show: false
          };
        });
      })
      .catch(error => {
        
      });
      
  },
  updateAccountId() {
      // Update account name based on selected account ID
      const account = this.accountsDetails.find(account => account.uId === this.accountuId);
      this.accountNumber = account ? account.accountId : null;
      this.accountDeposit = account ? account.actualDeposit : null;
      this.accountState = account ? account.state : null;
      this.accountFirstTrade = account ? account.firstTradeDateTime : null;
      this.accountExpire = account ? account.expireTime : null;
      this.accountPhase = account ? account.type : null;
      this.accountLastBalance = account ? account.balance : null;
      this.accountLastEquity = account ? account.equity : null;
      this.failedBy = account ? account.failedBy : null;
      this.accountplan = account ? account.plan : null;
      this.realBalance = account ? account.realBalance : null;
      this.failedOrderTicket = account ? account.failedOrderTicket : null;  
      this.accountUID = account ? account.uId : null;
      this.accountLastDayBalance = account ? account.lastDayBalanceOrEquity : null;
      this.maxDayProfit = account ? account.maxDayProfit : null;
      this.totalTradingDates = account ? account.totalTradingDates : null;
      this.failedTickDataId = account ? account.failedTickDataId : null;
      this.failedOrderTicket = account ? account.failedOrderTicket : null;
      this.failedComment = account ? account.failedComment : null;
      if (this.failedTickDataId != null){
            this.fecthFailedTickData(response.data.failedTickDataId);
          }
    },
    fetchHistoryData() {
      
      const url = URL.baseUrl + `Admin/account/orders?AccountId=${this.accountuId}`;
      axios.get(url,{
        headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    })
        .then(response => {
          // loop through response data and push each object into trades array
          this.tradeHistory = response.data;
          this.$emit('tradesloaded', this.tradeHistory);
          this.showPosition = true;
          this.allLoaded2 = true; 
          this.Tickscalping();
        })
        .catch(error => {
          this.allLoaded2 = true;
        });
    },
    TradeHistorySelected(value){
      if(value == 'Close'){
        this.fetchHistoryData();
      }
      if(value == 'Open'){

      }
      this.selectedHistory = value;
    },
    Tickscalping(){
      

      this.tradeHistory.forEach(trade => {
        
  console.log('salam');
  // Convert openTime and closeTime to Date objects
  const openTime = new Date(trade.openTime);
  const closeTime = new Date(trade.closeTime);
  const profit = trade.profit;
  const type =trade.type
  if (profit !=0 && type != 'Balance')
{
  this.realTrade++;
}
  // Calculate the duration in minutes
  const durationMinutes = (closeTime - openTime) / (1000 * 60); // Convert milliseconds to minutes
  console.log(durationMinutes);
  if (durationMinutes < 1 && profit !=0 && type != 'Balance') {
    // Trade duration is under 1 minute
    this.min1TradesCount++;
    this.min1Tickets.push(trade.ticket);
  } else if (durationMinutes >= 1 && durationMinutes <= 2) {
    // Trade duration is between 1 and 2 minutes
    this.min2TradesCount++;
    this.min2Tickets.push(trade.ticket);
  }
});
    },
    ChartSelected(value){
      
      this.selectedChart = value;
    },
     
    fetchOpenOrders() {
      
      const url = URL.baseUrl + `Admin/account/openedOrders?AccountId=${this.accountuId}`;
      axios.get(url,{
        headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    })
        .then(response => {
          // loop through response data and push each object into trades array
          this.tradeHistoryOpen = response.data;
          this.$emit('tradesloaded', this.tradeHistoryOpen);
          this.allLoaded3 = true; 
        })
        .catch(error => {
          this.allLoaded3 = true;
        });
    },
    TradeHistorySelected(value){
      if(value == 'Close'){
        this.showOpen =false;
      }
      if(value == 'Open'){
        this.showOpen =true;
      }
      this.selectedHistory = value;
    },
    renderCharts() {
      this.$nextTick(() => {
        this.renderChart1 = true;

        setTimeout(() => {
          this.$nextTick(() => {
            this.renderChart2 = true;

            setTimeout(() => {
              this.$nextTick(() => {
                this.renderChart3 = true;
              });
            }, 500); // Delay for the third chart rendering
          });
        }, 500); // Delay for the second chart rendering
      });
    },
     
  


  },
  
  mounted() {
    if(this.accountuId){

      this.fetchDataForChooseAccount();
      this.fetchHistoryData();
      // this.fetchEquityData();
      // this.fetchEquityDataMax();
      this.fetchOpenOrders();
      this.fetchStat(); 
      // this.renderCharts();
      // this.fetchEquityDataM1();
    }


  },
  computed: {
    // az un bala vaghti account ro entekhab mikoni meghdar haye div aval taghir kone
    accountNumber() {
      const account = this.accountsDetails.find(account => account.uId === this.accountuId);
      return account ? account.accountId : null;
    },
    failedOrderTicket() {
      const account = this.accountsDetails.find(account => account.uId === this.accountuId);
      return account ? account.failedOrderTicket : null;
    },
    failedComment() {
      const account = this.accountsDetails.find(account => account.uId === this.accountuId);
      return account ? account.failedComment : null;
    },
    accountplan() {
      const account = this.accountsDetails.find(account => account.uId === this.accountuId);
      return account ? account.plan : null;
    },
    accountDeposit() {
      const account = this.accountsDetails.find(account => account.uId === this.accountuId);
      return account ? account.actualDeposit : null;
    },
    accountState() {
      const account = this.accountsDetails.find(account => account.uId === this.accountuId);
      return account ? account.state : null;
    },
    accountFirstTrade() {
      const account = this.accountsDetails.find(account => account.uId === this.accountuId);
      return account ? account.firstTradeDateTime : null;
    },
    maxDayProfit() {
      const account = this.accountsDetails.find(account => account.uId === this.accountuId);
      return account ? account.maxDayProfit : null;
    },
    failedBy() {
      const account = this.accountsDetails.find(account => account.uId === this.accountuId);
      return account ? account.failedBy : null;
    },
    accountExpire() {
      const account = this.accountsDetails.find(account => account.uId === this.accountuId);
      return account ? account.expireTime : null;
    },
    accountPhase() {
      const account = this.accountsDetails.find(account => account.uId === this.accountuId);
      return account ? account.type : null;
    },
    accountLastBalance() {
      const account = this.accountsDetails.find(account => account.uId === this.accountuId);
      return account ? account.balance : null;
    },
    accountLastEquity() {
      const account = this.accountsDetails.find(account => account.uId === this.accountuId);
      return account ? account.equity : null;
    },
    approvedTradingDays	() {
      const account = this.accountsDetails.find(account => account.uId === this.accountuId);
      return account ? account.approvedTradingDays	 : null;
    },
    totalTradingDays2	() {
      const account = this.accountsDetails.find(account => account.uId === this.accountuId);
      return account ? account.totalTradingDates	 : null;
    },
    accountUID() {
      const account = this.accountsDetails.find(account => account.uId === this.accountuId);
      return account ? account.uId : null;
    },
    //for position chart
    profitInPercentData() {
      return this.tradeHistory.map(trade => trade.profitInPercent.toFixed(2));
    },
    maxDrawDownInPercentData() {
      return this.tradeHistory.map(trade => trade.maxDrawDownInPercent.toFixed(2));
    },
    accountLastDayBalance() {
      const account = this.accountsDetails.find(account => account.uId === this.accountuId);
      return account ? account.lastDayBalanceOrEquity : null;
    },
    //statistic
    maxProfit() {
      // create a new array without the first element
      const profitArray = this.tradeHistory.slice(1).map(trade => trade.totalProfit)
      return Math.max(...profitArray)
    },
    minProfit() {
      // create a new array without the first element
      const profitArray = this.tradeHistory.slice(1).map(trade => trade.totalProfit)
      return Math.min(...profitArray)
    },
    avgProfit() {
      // create a new array with only positive values
      const positiveProfitArray = this.tradeHistory.slice(1).map(trade => trade.totalProfit).filter(profit => profit > 0)
      // calculate the sum of the positive values and divide by the number of elements
      if (positiveProfitArray.length > 0) {
        const sum = positiveProfitArray.reduce((accumulator, currentValue) => accumulator + currentValue)
        return sum / positiveProfitArray.length
      } else {
        return 0
      }
    },
    avgLoss() {
      // create a new array with only positive values
      const negativeProfitArray = this.tradeHistory.slice(1).map(trade => trade.totalProfit).filter(profit => profit < 0)
      // calculate the sum of the positive values and divide by the number of elements
      if (negativeProfitArray.length > 0) {
        const sum = negativeProfitArray.reduce((accumulator, currentValue) => accumulator + currentValue)
        return sum / negativeProfitArray.length
      } else {
        return 0
      }
    },
    avgDrawdown() {
      // create a new array with only positive values
      const drawDownArray = this.tradeHistory.slice(1).map(trade => trade.maxdrawDownInPercent)
      // calculate the sum of the positive values and divide by the number of elements
      if ( drawDownArray.length > 0) {
        const sum =  drawDownArray.reduce((accumulator, currentValue) => accumulator + currentValue)
        return sum /  drawDownArray.length
      } else {
        return 0
      }
    },
    totalTrades() {
      return this.tradeHistory.length - 1
    },
    positiveTrades() {
      // create a new array with only positive values
      const positiveProfitArray = this.tradeHistory.slice(1).map(trade => trade.totalProfit).filter(profit => profit > 0)
      // return the number of positive values minus one
      return positiveProfitArray.length
    },
    negativeTrades() {
      // create a new array with only positive values
      const negativeProfitArray = this.tradeHistory.slice(1).map(trade => trade.totalProfit).filter(profit => profit < 0)
      // return the number of positive values minus one
      return negativeProfitArray.length 
    },
    avgDuration() {
      // create a new array with the duration of each trade
      const durationArray = this.tradeHistory.slice(1)
        .map(trade => (new Date(trade.closeTime) - new Date(trade.openTime)) / (1000 * 60))
      // calculate the sum of the durations and divide by the number of elements
      if (durationArray.length > 0) {
        const sum = durationArray.reduce((accumulator, currentValue) => accumulator + currentValue)
        return sum / durationArray.length
      } else {
        return 0
      }
    },
    sharpeRatio() {
      
      // calculate the daily returns
      const dailyReturns = this.tradeHistory.slice(1)
      .map(trade => trade.totalProfit / ((new Date(trade.closeTime) - new Date(trade.openTime)) / (1000 * 60 * 60 * 24)))
      // calculate the average daily return
      if (dailyReturns.length >0){
        const avgDailyReturn = dailyReturns.reduce((accumulator, currentValue) => accumulator + currentValue) / dailyReturns.length
        // calculate the standard deviation of daily returns
        const variance = dailyReturns.reduce((accumulator, currentValue) => accumulator + Math.pow(currentValue - avgDailyReturn, 2), 0) / dailyReturns.length
        const stdDev = Math.sqrt(variance)
        // calculate the Sharpe ratio
        return avgDailyReturn / stdDev
      }
    }
  },
  // watch: {
  //   accountUID: {
  //     handler(newValue) {
  //       // fetch equity data and history data whenever accountUID changes
  //       this.fetchEquityData();
  //       this.fetchHistoryData();
  //     },
  //     immediate: true, // call handler immediately when component is mounted
  //   },
  // },
  
  
   
  
 
  
    
  };
  </script>

<style>
@media screen and (max-width: 767px) {
  .hidden-sm {
    display: none;
  }

  #accounts {
    max-width: 100%;
  }

  .plus-icon::before {
    content: "+";
  }

  .hidden-mobile {
    display: none;
  }

  main {
    height: 100vh;
    overflow-x: hidden;
  }
}
</style>